@import '../../_scss/settings';

// Avoiding pollution of root
.p-video-react-annotations-markers {
  // Colors used for Vitale Teach
  --color-yellow: 255, 210, 0; // ffd200 // Geel
  --color-blue: 30, 100, 200; // 1e64c8 // Blauw
  --color-orange: 251, 126, 58; // fb7e3a // Oranje
  --color-white: 255, 255, 255; // fff // Oranje
  --color-silver: 122, 129, 152; // 7a8198 // Oranje

  --own-background: rgb(var(--color-blue));
  --own-background-color-light: rgba(var(--color-blue), 0.05);
  --own-background-color-x-light: rgba(var(--color-blue), 0.015);
  --own-border: rgb(var(--color-blue));

  --own-supervisor-background: rgb(var(--color-yellow));
  --own-supervisor-border: rgb(var(--color-blue));
  --own-supervisor-background-color-light: rgba(var(--color-yellow), 0.05);
  --own-supervisor-background-color-x-light: rgba(var(--color-yellow), 0.015);

  --supervisor-background: rgb(var(--color-orange));
  --supervisor-border: rgb(var(--color-orange));
  --supervisor-background-color-light: rgba(var(--color-orange), 0.02);
  --supervisor-border-color-light: rgba(var(--color-orange), 0.35);

  --other-background: rgb(var(--color-white));
  --other-border: rgb(var(--color-silver));
  --other-border-color-light: rgba(var(--color-silver), 0.25);

  --thick-border-width: 2px;
  --thin-border-width: 1px;
}

.c-media-wrapper {
  margin-bottom: 0;

  .video-react {
    transition: padding-top 0.2s ease-in-out;
  }

  .video-react .video-react-control-bar {
    height: 4em;
  }

  .video-react .video-react-annotations-control {
    flex: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.video-react .video-react-annotations-markers {
  flex: auto;
  display: flex;
  justify-content: center;
}

.video-react
  .video-react-annotations-control.video-react-control
  > div.video-react-progress-control.video-react-control {
  width: 100%;
}

.video-react-annotations-markers {
  .annotation-marker,
  .annotation-end-marker {
    position: absolute;
    bottom: -16px;
    left: auto;
    width: 10px;
    height: 10px;
    border-radius: 10px;

    padding: 0;
    text-align: right;
    z-index: 1;
    transform: translateX(-50%);

    background-color: $transparent;
    border: 1px solid $transparent;
    transition:
      width 0.35s ease-in-out,
      height 0.35s ease-in-out,
      margin-bottom 0.35s ease-in-out;

    &:hover:not(.annotation-marker-active) {
      transform-origin: center;
      width: 14px;
      height: 14px;
      margin-bottom: -2px;
    }
  }

  .annotation-marker--grouped {
    border-color: var(--own-background);
    background-color: $color-neutral-x-light;
    border-width: 3px;
    outline: 2px solid $color-neutral-x-light;
    outline-offset: -1px;
    box-shadow: 0 0 10px -2px $color-neutral-dark;
  }

  .annotation-marker .c-icon {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 50%;
    top: -38px;
    transform: translateX(-50%) scale(1.5);
    transition: opacity 0.15s ease-in-out;
  }

  .annotation-marker.annotation-marker-active {
    background-color: $transparent;
    border-color: $transparent;
  }

  .annotation-marker-active .c-icon {
    opacity: 1;
    visibility: visible;

    transform: translateX(-50%) scale(1.5);
  }

  .annotation-marker--own {
    background-color: var(--own-background);
    border-color: var(--own-border);

    .c-icon use {
      fill: var(--own-background);
      stroke: var(--own-border);
    }
  }

  .annotation-marker--own-supervised {
    background-color: var(--own-supervisor-background);
    border-color: var(--own-supervisor-border);

    .c-icon use {
      fill: var(--own-supervisor-background);
      stroke: var(--own-supervisor-border);
      stroke-width: 2px;
    }
  }

  .annotation-marker--supervisor {
    background-color: var(--supervisor-background);
    border-color: var(--supervisor-border);

    .c-icon use {
      fill: var(--supervisor-background);
      stroke: var(--supervisor-border);
    }
  }

  .annotation-marker--other {
    background-color: var(--other-background);
    border-color: var(--other-border);

    .c-icon use {
      fill: var(--other-background);
      stroke: var(--other-border);
      stroke-width: 2px;
    }
  }
}

// Grouped:

.video-react-annotations-grouped {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 6px;
  position: absolute;
  height: 100%;
  transform: translateX(-50%);

  border-radius: 12px;
  bottom: -$spacing-large;
  background: white;
  box-shadow: 0 0 8px -3px black;

  .annotation-marker {
    position: static;
    transform: none;
    transition:
      width 0.2s ease-in-out,
      height 0.2s ease-in-out;

    .c-icon {
      top: -42px;
    }
  }

  .annotation-marker:hover:not(.annotation-marker-active) {
    transform-origin: center;
    width: 10px;
    height: 10px;
    margin-bottom: 0;
  }
}

.annotation-end-marker {
  z-index: 0;
  background-color: $transparent;
  outline: 1px solid $color-error;
  visibility: hidden;
  width: auto;
  transform: translateX(50%);

  .annotation-marker-active + & {
    visibility: visible;
  }
}

.c-annotation__time {
  display: flex;
  align-items: center;
  color: $color-primary-dark;
}

.c-annotation__time .c-icon {
  margin: 0 $spacing-tiny;
}

.c-annotation__value {
  margin-bottom: 0;
}

.c-annotation__video-wrapper {
  position: sticky;
  top: 0;
  padding: 12px 16px 0;
  margin-left: -16px;
  margin-right: -16px;
  background-color: #fff;
  z-index: 10;
}

// Video Markers:
// Annotations
.c-annotation {
  border: var(--thin-border-width) solid;
  margin-top: $spacing-medium;
  transition:
    box-shadow 0.2s ease-in-out,
    border-color 0.2s ease-in-out;

  &:first-child {
    margin-top: 0;
  }

  .c-badge {
    border: var(--thin-border-width) solid;
    color: var(--other-border);
    background-color: $transparent;

    text-transform: none;
  }

  .c-badge[style] {
    font-family: $font-family-regular;
    color: $color-primary-dark;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: $transparent;
    margin-right: 0;

    @include inuit-font-size(13px, 1);
  }

  > .c-annotation--supervisor {
    padding: 12px $spacing-medium $spacing-medium;
    border-color: var(--supervisor-border-color-light);

    .c-badge {
      border-color: var(--supervisor-border-color-light);
    }
  }

  &.c-annotation--active > .c-annotation--supervisor {
    border-color: var(--supervisor-border);

    .c-badge {
      border-color: var(--supervisor-border);
    }
  }

  &.c-annotation--own > .c-annotation--supervisor {
    background-color: $color-neutral-x-light;
  }

  &.c-annotation--other > .c-annotation--supervisor {
    background-color: $transparent;
  }

  &.c-annotation--active {
    box-shadow: 0 0 10px -6px $color-primary-dark;
  }

  //   Visual Hack: extra space on last element
  &:last-child::after {
    content: '';
    width: 100%;
    position: relative;
    background: transparent;
    z-index: 0;
    bottom: -$spacing-large;
    display: block;
    height: 16px;
  }
}

// Annotation Ownership
.c-annotation--own {
  background-color: var(--own-background-color-x-light);
  border-color: var(--own-border);

  &.c-annotation--active {
    background-color: var(--own-background-color-light);
  }
}

.c-annotation--own-supervised {
  border-color: var(--own-supervisor-border);
  background-color: var(--own-supervisor-background-color-x-light);

  &.c-annotation--active {
    border-color: var(--own-supervisor-border);
    background-color: var(--own-supervisor-background-color-light);
  }
}

.c-annotation--supervisor {
  border-color: var(--supervisor-border-color-light);

  .c-badge {
    background-color: $transparent;
    border-color: var(--supervisor-border-color-light);
  }

  &.c-annotation--active {
    background-color: var(--supervisor-background-color-light);
    border-color: var(--supervisor-border);

    .c-badge:not([style]) {
      border-color: var(--supervisor-border);
    }
  }
}

.c-annotation--other {
  border-color: var(--other-border-color-light);

  .c-badge {
    background-color: var(--other-background);
    border-color: var(--other-border-color-light);

    border-width: var(--thin-border-width);
  }

  &.c-annotation--active {
    border-color: var(--other-border);

    > :not(.c-annotation--supervisor) .c-badge:not([style]) {
      border-color: var(--other-border);
    }
  }
}

.c-annotation__ownership {
  width: $spacing-medium;
  height: $spacing-medium;
  border-style: solid;
  border-width: var(--thick-border-width);
  border-radius: $spacing-medium;
  margin-right: $spacing-small;

  .c-annotation--own & {
    background-color: var(--own-background);
    border-color: var(--own-border);
  }

  .c-annotation--own-supervised & {
    background-color: var(--own-supervisor-background);
    border-color: var(--own-supervisor-border);
  }

  .c-annotation--supervisor & {
    background-color: var(--supervisor-background);
    border-color: var(--supervisor-border);
  }

  .c-annotation--other & {
    background-color: var(--other-background);
    border-color: var(--other-border);

    border-width: var(--thin-border-width);
  }
}

// List Legend
.c-list-legend {
  margin-bottom: 0;
}

.c-list-legend__item {
  position: relative;
  padding: $spacing-tiny 0 $spacing-tiny ($spacing-medium * 1.5);
  font-family: $font-family-light;

  &::before {
    content: '';
    width: $spacing-medium;
    height: $spacing-medium;
    position: absolute;
    left: 0;
    top: calc(50% - 1px);
    transform: translateY(-50%);
    border-radius: $spacing-medium;
    border-style: solid;
    border-width: var(--thick-border-width);
  }
}

.c-list-legend__item-own::before {
  background-color: var(--own-background);
  border-color: var(--own-border);
}

.c-list-legend__item-own-supervised::before {
  background-color: var(--own-supervisor-background);
  border-color: var(--own-supervisor-border);
}

.c-list-legend__item-supervisor::before {
  background-color: var(--supervisor-background);
  border-color: var(--supervisor-border);
}

.c-list-legend__item-other::before {
  background-color: var(--other-background);
  border-color: var(--other-border);

  border-width: var(--thin-border-width);
}

.c-list-legend__item-grouped {
  margin-top: $spacing-medium;

  &::before {
    background-color: var(--other-background);
    border-color: var(--own-border);

    outline: 3px solid $color-neutral-x-light;
    box-shadow: 0 0 4px 1px $color-neutral-dark;
    width: 11px;
    height: 11px;
    left: 3px;
    top: 50%;
  }
}
