@import '../../_scss/settings';

.c-sidebar {
  transition: $global-transition-fast;
  display: flex;

  height: 100%;
  min-height: 100%;
  width: 72px;

  flex: 1 0 72px;
  overflow: hidden;

  position: fixed;
  z-index: 250;
  left: -72px;

  @include mq($from: small) {
    left: 0;

    @media (hover: hover) {
      &:hover {
        width: 250px;
      }
    }

    @media (hover: none) {
      &:not(.c-sidebar--uncollapsed) {
        .c-nav__item {
          padding: 0;
          height: auto;
          min-height: 56px;
        }

        .c-nav__item > .c-nav__icon {
          height: 36px;
        }

        .c-nav__item > .c-nav__icon .c-icon--medium {
          width: 20px;
          height: 20px;
        }

        .c-nav__item-description {
          position: relative;
          width: 100%;

          text-align: center;
          left: 0;
          right: 0;
          top: calc(100% - 12px);

          margin-left: 0;
          margin-top: 30px;
          margin-bottom: 6px;

          transform: none;
          white-space: wrap;

          @include inuit-font-size(12px, 1);
        }

        .c-nav__item--bottom > .c-nav__item-description {
          top: auto;
        }
      }
    }
  }
}

.c-sidebar--uncollapsed {
  left: 0;
  width: 250px;
}

.c-sidebar__wrapper {
  background: $color-neutral-x-dark;
  display: flex;
  flex-direction: column;

  width: 100%;

  @include mq($until: small) {
    overflow-y: auto;

    .c-nav {
      min-height: calc(100% - #{$spacing-huge * 1.5});
    }
  }
}

.c-sidebar__header {
  display: flex;
  align-items: center;
  padding: 0;
  height: 72px;
  flex: 0 1 72px;
  text-decoration: none;

  margin: ($spacing-medium - $spacing-tiny) 0;
  cursor: pointer;
}

.c-sidebar__logo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 72px;
  min-width: 72px;

  text-align: left;

  .c-sidebar__logo-img {
    max-width: 46px;
  }
}

.c-sidebar__user-info {
  font-family: $font-family-regular;
  color: $color-neutral-x-light;
  margin-left: $spacing-small;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include inuit-font-size(16px, 2);
}
