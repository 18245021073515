@import '../../_scss/settings';

.c-panel {
  padding: 0 $spacing-medium;
  position: relative;
  overscroll-behavior-y: contain;

  line-height: 1.2;
  flex: 1 0 auto;
  height: 100%;

  margin: 0;
  width: 100%;

  background-color: transparent;
  z-index: 1;
  container: panel-container / inline-size;
  //   background-color: $color-neutral-light;

  &:last-child:not(.c-panel--secondary):not(.c-panel--home):not(:first-child),
  &.c-panel--bg-light {
    background-color: $color-neutral-x-light;

    .c-list__item:not(.c-list__item-with-sub-list):not(
        .c-list__item-small-editable
      ) {
      background-color: $color-neutral-light;
    }
  }

  &:last-child:not(.c-panel--secondary):not(.c-panel--home):not(:first-child)
    .c-card--secondary
    .c-list__item:not(.c-list__item-with-sub-list):not(
      .c-list__item-small-editable
    ) {
    background-color: $color-neutral-x-light;
  }

  &:nth-last-child(2) + & {
    border-right: 1px solid transparent;
  }

  & + &.is-active {
    margin-left: 0;
    border-right: 1px solid $color-neutral;
    z-index: 0;
    border-left: 0;
    box-shadow: -1px 0 2px -2px;
  }

  > .c-list:first-child,
  > p:first-child {
    padding-top: $spacing-large;
  }

  .c-card__body > .c-list:not(.c-list--virtualized) {
    padding-left: 0;
    padding-right: 0;
    overflow-y: initial;
    background-color: inherit;

    flex: 0 0 auto;
    display: flex;
    flex-direction: column;

    .c-list__header {
      background-color: inherit;
      z-index: 9;
      background-color: inherit;
      height: auto;
    }

    &.c-list--draggable {
      flex: 0;
    }
  }
  .c-card__body > .c-list.c-list--cue {
    overflow: visible;
  }

  .c-list + .c-list {
    padding-top: $spacing-small;
  }

  .c-card__body > .c-list > .c-list__body:last-child {
    margin-bottom: 0;
  }

  .c-list__item:last-child:not(.c-list__item-active) {
    margin-bottom: 0;
  }

  @include mq($from: small) {
    overflow-y: auto;
  }

  @include mq($from: medium) {
    width: 50%;
  }
}

.c-panel--empty-state {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $color-neutral-x-light;
  border-left: 1px solid $color-neutral;

  @include mq($until: medium) {
    /* This is in order to hide the empty panels in small screens */
    display: none !important;
  }
}

.c-panel--home {
  background-color: $color-neutral-light;

  height: calc(100% - #{$spacing-huge + $spacing-small});

  flex: 1 0 100%;
  width: 100%;
  margin-left: 0;

  display: flex;
  flex-direction: column;
  padding: 0;
  min-height: calc(100dvh - 72px);
  margin-top: 72px;

  .c-card {
    box-shadow: none;
    max-width: none;
  }

  .c-card__header--primary {
    padding-top: 0;
    padding-bottom: $spacing-small;
  }

  .c-list__item {
    background-color: $color-neutral-light;
  }

  @include mq($from: small) {
    width: calc(100% - #{$spacing-huge + $spacing-small});
    margin-left: $spacing-huge + $spacing-small;

    .c-card__body {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @include mq($from: medium) {
    .c-card__body {
      padding: $spacing-medium;
    }
  }
}

.c-panel--benchmark {
  background-color: $color-neutral-x-light;
  padding: ($spacing-medium * 1.5) ($spacing-medium * 1.5) 0;

  // TODO check: new code for scrollable tables
  overflow-x: hidden;
  max-width: 100vw;
  margin-top: 0;

  .c-panel__body--home {
    border-top: 0;
    padding: 0;
    overflow: auto;
  }

  .c-panel__body--horizontal {
    overflow-x: hidden;
    padding: 0 0 0 24px;
    overflow-y: scroll;
  }

  .c-panel__header {
    padding-top: 0;
    padding-bottom: 0;
  }

  .c-panel__title-wrapper {
    padding: 0 0 $spacing-small;
  }

  .c-panel__header-settings {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 0;

    margin-left: -$spacing-small;
    margin-right: -$spacing-small;
  }

  @include mq($from: small) {
    margin-top: 72px;

    overflow-y: auto;
    max-height: calc(100dvh - 72px);
    max-width: calc(100vw - 72px);
  }
}

.r-panel--results {
  margin-top: 72px;
  margin-left: 72px;
  width: calc(100% - 72px);
  max-height: calc(100dvh - 72px);
  background: white;
  display: flex;
  flex-direction: column;
  padding: 0;

  .c-panel__header {
    padding-left: 16px;
    padding-right: 16px;
  }

  .c-panel__body {
    overflow: auto;
    flex: 1;
    padding-left: 16px;
    padding-right: 16px;
  }

  @include mq($until: small) {
    margin-top: 0;
    max-height: calc(100dvh - 40px);
    margin-left: 0;
    width: 100%;
  }
}

.c-panel__body--full-width {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;

  .c-panel {
    &:last-child:not(.c-panel--secondary):not(.c-panel--home):not(
        :first-child
      ) {
      .c-list__item {
        background-color: $color-neutral-x-light;
      }
    }
  }
}

.c-panel--fullscreen {
  margin-left: 0;
  width: 100%;
  margin-top: 0;
  height: 100%;
}

.c-panel-graph {
  height: 100%;
  max-height: 100dvh;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  right: 0;
  width: 100%;
  z-index: 15;
  top: 0;
  border: 0;
  padding: 0;
}

.c-panel-graph__container {
  width: 50%;
  background-color: white;
  right: 0;
  top: 0;
  position: absolute;
  height: 100%;
  padding: 16px;
}

.c-panel--secondary {
  background-color: $color-neutral-x-light;
}

.c-panel__header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;

  z-index: 29;
  padding-top: $spacing-medium;
  margin-bottom: 0;
  background-color: inherit;

  .is-active & {
    background-color: inherit;
  }

  @include mq($from: medium) {
    position: sticky;
    top: 0;
  }
}

.c-panel__header--home {
  justify-content: center;
  padding: ($spacing-medium + $spacing-small) $spacing-medium;
  margin-bottom: 0;
  position: relative;

  .c-input__group--radiobutton {
    margin-bottom: 0;
  }
}

.c-panel__header--border-bottom {
  border-bottom: 1px solid $color-neutral;
  margin-bottom: $spacing-medium;
  padding-bottom: $spacing-small;

  &:has(+ .c-list) {
    box-shadow: 0 6px 6px -10px #34373e;
    z-index: 1;
    margin-bottom: 0;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: -16px;
    margin-right: -16px;
    background: inherit;
  }
}

.c-panel:nth-last-child(2) .c-panel__header-filled-bg {
  background-color: $color-neutral-light;
}

.c-panel__body {
  background-color: inherit;

  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.c-panel__body--refactor {
  margin-left: -($spacing-medium);
  margin-right: -($spacing-medium);
  padding: $spacing-medium $spacing-medium 0;

  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  align-content: flex-start;

  > .c-list {
    overflow: visible;
  }

  > * {
    flex: 0 0 100%;
  }
}

.c-panel__body--horizontal {
  scroll-behavior: smooth;
  margin-bottom: 0;
  width: calc(100% + 48px);
  margin-left: -24px;

  .c-button__group {
    position: fixed;
    bottom: $spacing-medium;
    right: 32px;
  }
}

.c-panel__body--home {
  display: flex;
  flex-grow: 1;
  background: $color-neutral-x-light;
  padding: ($spacing-medium * 1.5) $spacing-medium;
  border-top: 1px solid $color-neutral;
}

.c-panel__title-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: $spacing-medium;
  padding-bottom: 0;
}

.c-panel__title {
  font-family: $font-family-bold;
  color: $color-primary-dark;
  min-height: 32px;

  width: 100%;
  margin: 0 0 $spacing-tiny;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include inuit-font-size(24px, 1.3);

  &:last-child {
    margin-bottom: $spacing-medium;
  }

  .c-badge--completed {
    margin-bottom: -2px;
  }
}

.c-panel-title--ellipsed {
  white-space: nowrap;
  max-width: calc(100% - #{$spacing-large});
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-panel__subtitle {
  display: block;
  font-family: $font-family-light;
  color: $color-primary-dark;
  margin: 0;
  width: 100%;

  @include inuit-font-size(13px, 1.2);

  &.o-flex {
    display: flex;
  }
}

.c-panel__subtitle-capitalized {
  text-transform: capitalize;
}

.c-panel__title-buttons-group {
  display: flex;
  min-height: 32px;

  //   > .c-panel__title-button:not(:last-child) {
  //     margin-right: $spacing-tiny;
  //   }
  &:has(> .c-button-expandable:not(:first-child:last-child)) {
    min-width: 68px;
    flex: 0 0 auto;
    margin-left: 8px;
  }

  &:has(> .c-button-expandable:first-child:last-child) {
    min-width: 36px;
  }

  > * + *:not(.c-menu__wrapper-overlay) {
    margin-left: $spacing-tiny;
  }

  position: relative;
  justify-content: flex-end;
}

.c-panel--nav {
  position: fixed;
  height: 100%;

  width: 72px;
  flex: 1 0 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $spacing-medium $spacing-small;
  z-index: 5;
  overflow: hidden;

  margin-top: 72px;
  left: 72px;
  height: calc(100% - 72px);

  .c-icon {
    width: 35px;
    height: 35px;
  }

  @include mq($until: small) {
    display: none;
  }
}
.c-panel--nav-button {
  cursor: pointer;
}

.c-panel__tools {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: $spacing-small;
  flex-wrap: wrap;

  flex: 1 1 auto;
  width: 100%;

  .c-input__group {
    margin-bottom: $spacing-small;
    flex: 1 1 calc(100% - 200px);
  }

  .c-input__group.c-input__group-select.u-margin-left-none.c-input--inline {
    flex: 1 0 250px;
  }

  .c-button {
    min-height: 34px;
  }

  .c-button__group .c-menu {
    margin-left: 0;
  }

  @include mq($from: huge) {
    .c-input__group {
      flex-basis: calc(100% - 360px);
    }
  }
}

// prop: tabTools
.c-panel__tools--separated {
  margin-bottom: 0;
  padding-bottom: $spacing-medium;
  margin-top: 0;
  border-bottom: 1px solid $color-neutral;
}

// prop: separated
.c-panel__tools--on-tabs {
  padding: $spacing-medium 0 $spacing-small;

  *:not(:first-child) {
    margin-left: $spacing-small;
    margin-bottom: 0;
  }
}

// Error Panel:
.c-panel--error {
  height: 100%;
  width: 100%;
  flex: 1 0 100%;

  margin-left: 0;
  padding: 0;

  display: flex;
  flex-direction: column;

  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  background-color: $color-neutral-x-light;

  @include mq($from: small) {
    width: 100%;
  }
}

.c-panel__body--buttons-fixed-bottom {
  .p-tabs
    .react-tabs
    .react-tabs__tab-panel--selected
    > :first-child
    > :last-child {
    margin-bottom: $spacing-huge * 1.125;
  }
}

// REFACTORING
.r-panel__header {
  display: flex;
  align-items: flex-end;
  z-index: 29;
  padding-top: $spacing-medium;
  //   padding-bottom: $spacing-medium;
}

.r-panel__title-wrapper {
  flex: 1 1 50%;
  margin-bottom: 16px;

  .c-panel__body > * + & {
    margin-top: 24px;
  }
}
