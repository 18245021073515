date-input-polyfill[data-open='true'] {
  z-index: 50;
}

.p-video-player {
  .video-react.video-react-fluid {
    padding-top: 0 !important;
    height: 160px;
    width: 300px;
    position: relative;
  }
  .video-react-control-bar.video-react-control-bar-auto-hide {
    display: none;
  }
  .video-react-big-play-button.video-react-big-play-button-center.big-play-button-hide {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .video-react-loading-spinner {
    position: absolute;
  }
  .video-react-big-play-button.video-react-big-play-button-center.big-play-button-hide {
    display: none;
  }

  .video-react .video-react-video {
    object-fit: cover;
  }

  .video-react .video-react-poster {
    background-size: cover;
  }
}

.c-tooltip-popper__message-video .video-react .video-react-poster {
  background-size: cover;
}

.c-media-wrapper {
  .video-react-control-bar {
    display: flex !important;
  }

  .video-react-current-time-display {
    font-variant-numeric: tabular-nums;
  }

  .video-react .video-react-play-control {
    align-self: center;
  }

  .video-react .video-react-control::before {
    font-size: 1.9em !important;
    line-height: 2 !important;
  }

  .video-react .video-react-time-control {
    display: flex;
    align-items: center;
  }

  .video-react .video-react-progress-control:hover .video-react-time-tooltip,
  .video-react
    .video-react-progress-control:hover
    .video-react-mouse-display::after,
  .video-react
    .video-react-progress-control:hover
    .video-react-play-progress::after {
    font-family: 'lato', sans-serif;
    padding-bottom: 6px;
    font-variant-numeric: tabular-nums;
  }

  .video-react-menu-content {
    display: flex !important;
    align-items: center;
  }

  .video-react .video-react-menu-button-inline:hover .video-react-menu,
  .video-react .video-react-menu-button-inline:focus .video-react-menu,
  .video-react
    .video-react-menu-button-inline.video-react-slider-active
    .video-react-menu {
    display: flex !important;
    align-items: center;
  }

  .video-react
    .video-react-progress-control:hover
    .video-react-progress-holder {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }

  .video-react .video-react-annotations-markers {
    margin: 0 0.45em 0 0.45em;
    position: absolute;
    left: 0;
    height: 20px;
    right: 0;
    z-index: 2;
    bottom: 50%;
  }

  .video-react-progress-control.video-react-control {
    z-index: 1;
  }

  .video-react .video-react-slider:focus {
    box-shadow: none !important;
  }

  .video-react .video-react-time-tooltip,
  .video-react .video-react-mouse-display::after,
  .video-react .video-react-play-progress::after {
    top: -6em !important;
  }
}
