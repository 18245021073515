@import '../../_scss/settings';

.c-page {
  display: flex;
  flex-direction: column;

  background-color: $color-neutral-light;

  width: 100%;
  height: auto;
  min-height: 100dvh;
}

.c-page--login {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.c-page--login.c-page--login-hylyght {
  background-image: url('~/src/assets/images/hylyght-bg.svg');
}

.c-page--login.c-page--login-sportamundi {
  background-image: url('~/src/assets/images/sportamundi-bg.svg');
}

.c-page--login.c-page--login-fundis {
  background-image: url('~/src/assets/images/fundis-bg.jpg');
}

.c-page__header {
  width: 100%;
  // overflow: hidden; // TODO @fabian in comment quickfix

  // grid-area: header;
  background-color: #fff;
  height: auto;

  // @include mq($until: medium) {
  //   display: flex;
  //   /* position: absolute; */
  //   width: 100%;
  //   border-bottom: 1px solid $color-neutral-xx-dark;
  //   z-index: 20;
  // }
}

.c-page__header--fixed {
  right: 0;
  z-index: 30;
  height: 40px;

  position: relative;
  display: flex;
  // flex-direction: column;
  // justify-content: center;
  align-items: center;
  border: 1px solid $color-neutral;

  @include mq($from: small) {
    height: 72px;
    width: calc(100% - 72px);
    position: fixed;
    margin-left: -72px;
  }
}

.c-page__title {
  text-align: center;
  font-family: $font-family-bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding-left: 0;
  padding-right: 0;
  flex: 1;

  .c-page__title-logo + .c-menu__wrapper {
    flex: 1 0 auto;
  }

  @include inuit-font-size(16px);

  //   @include mq($from: tiny) {
  //     flex: 1 0 100%;
  //     padding-left: $spacing-huge;
  //     padding-right: $spacing-huge;
  //   }

  @include mq($from: small) {
    justify-content: center;
    padding-left: 0;
    padding-right: 0;

    .c-page__title-logo + .c-menu__wrapper {
      flex: 0 1 auto;
    }
  }
}

.c-page__title-logo {
  // max-width: 10%;
  height: 50px;
  // max-height: 100%;
  width: 50px;
  padding: 0 $spacing-tiny;
  display: inline-block;
  margin-right: $spacing-small;
  margin-left: -$spacing-small;
  border-radius: $tiny-radius;
  border: 1px solid $color-primary-dark;
  display: inline-flex;
  align-items: center;

  img {
    // object-fit: cover;
    flex: 1 0 100%;
  }

  @include mq($until: small) {
    display: none;
  }
}

.c-page__title {
  .c-menu__wrapper--as-modal > .c-button--menu-header {
    text-transform: uppercase;

    @include mq($from: small) {
      @include inuit-font-size(16px);
    }
  }
}
.c-page__title-title {
  margin-left: $spacing-medium;
  margin-right: $spacing-medium * 2.5;
}

.c-page__title-location {
  text-transform: none;
  font-family: $font-family-light;
  margin-left: $spacing-small;

  @include mq($until: small) {
    display: none;
  }
}

.c-page__right-item {
  position: absolute;
  right: 0;
  padding: $spacing-small $spacing-medium;
  font-family: $font-family-bold;
  // align-self: flex-end;

  top: 50%;
  transform: translateY(-50%);

  @include mq($until: small) {
    display: none;
  }
}

.c-page__right-item-language {
  display: none;

  .c-button--menu-header {
    padding-left: 12px;
    padding-right: 24px;
  }

  .c-button--menu-header .c-icon {
    right: 4px;
  }

  @include mq($until: small) {
    position: static;
    padding: 0 8px;
    transform: none;
    display: block;
  }
}

.c-page__content {
  width: 100%;
  // background-color: #999;

  // grid-area: content;
  flex: 1;
  display: flex;
  flex-direction: column;

  @include mq($until: medium) {
    max-width: 100%;
  }

  @include mq($from: small) {
    flex-direction: row;
  }
}

.c-page__content-centered {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 100dvh;
}

.c-page__content-horizontal-flow {
  scroll-behavior: smooth;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  border-left: 1px solid $color-neutral;

  margin-left: 0;
  width: 100%;
  max-height: calc(100dvh - 40px);

  > .c-panel {
    margin-top: 0;

    height: auto;
    min-height: calc(100dvh - 40px); // In context > otherwise isn't necessary

    display: flex;
    flex-direction: column;

    overflow-y: auto;
    overflow-x: hidden;
  }

  > .c-panel:has(+ .c-panel.is-active) .c-card.c-card--secondary,
  > .c-panel:first-child:not(.c-panel--secondary) .c-card.c-card--secondary,
  > .c-panel:first-child:not(.c-panel--secondary)
    .react-tabs__tab-panel--selected
    > .c-list
    .c-list__item-small {
    background-color: $color-neutral-x-light;
  }

  & > .c-panel:has(+ .c-panel.is-active) .c-card.c-card--secondary,
  & > .c-panel:first-child:not(.c-panel--secondary) .c-card.c-card--secondary {
    .c-list__item.o-list-bare__item.c-list__item-small {
      background-color: $color-neutral-light;
    }
  }

  @include mq($from: small) {
    margin-left: 144px;
    overflow-x: hidden;
    height: 100dvh;
    max-height: none;

    > .c-panel {
      //   min-height: calc(100% - 72px); // In context > otherwise isn't necessary
      min-height: calc(100dvh - 72px); // In context > otherwise isn't necessary
      margin-top: 72px;
    }
  }
}

.c-page__content-home {
  width: 100%;
  height: 100dvh;
}

.c-page__content-wrapper {
  @include mq($until: small) {
    width: 100%;
  }
}

.c-page__content-benchmark {
  width: 100%;

  .c-panel--error {
    margin-left: 72px;
    width: calc(100vw - 72px);
    background-color: $color-neutral-light;
  }
}

.c-page__footer {
  width: 100%;

  // grid-area: footer;
  background-color: #fff;
  height: auto;
  // align-self: flex-end;
}
