@import '../../_scss/settings';

.c-nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: calc(100% - 96px);
  flex: 1 1 100%;
}

.c-nav__wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 0 8px;
  position: relative;

  // Specially for the settings:
  &:not(:first-child) {
    flex: 0 1 72px;
    padding: 0 8px;

    .c-nav__item--secondary {
      border-radius: $small-radius;
      padding: 0;
      margin-bottom: $spacing-small;
      height: 56px;

      .c-nav__icon {
        left: 0;
      }

      .c-nav__item-description {
        padding-left: $spacing-tiny;
      }

      @include mq($from: small) {
        margin-bottom: 0;
        border-radius: 0;
        height: 72px;

        .c-nav__icon {
          left: $spacing-small;
        }

        .c-nav__item-description {
          padding-left: $spacing-small;
        }
      }
    }

    @include mq($from: small) {
      padding: 0;
    }
  }
}

.c-nav__header {
  text-align: left;
  font-family: $font-family-light;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  padding: $spacing-small ($spacing-medium + $spacing-tiny);
  margin-bottom: $spacing-small;
  margin-top: ($spacing-medium + $spacing-tiny);
}

.c-nav__items-group {
  margin-bottom: $spacing-large;

  &:not(:last-of-type) {
    border-bottom: 2px solid rgba($color-neutral-light, 0.2);
    margin-bottom: $spacing-medium;
    padding-bottom: $spacing-small;
  }
}

.c-nav__item {
  text-align: left;
  text-decoration: none;

  min-width: 100%;
  display: flex;
  align-items: center;
  padding: 0 $spacing-medium;
  height: 56px;
  border-radius: $small-radius;
  margin: $spacing-small 0;

  color: $color-neutral-dark;
  position: relative;

  user-select: none;
  cursor: pointer;

  border: 0;
  background-color: inherit;

  &:first-of-type {
    margin-top: 0;
  }

  @include inuit-font-size(13px);

  &.is-active {
    background: $color-primary;
    color: $color-neutral-x-light;

    .c-nav__icon {
      use {
        fill: $color-neutral-x-light;
      }
    }

    &:hover,
    &:focus,
    &:active {
      background: $color-primary;
    }
  }

  &:hover,
  &:focus,
  &:active {
    background: rgba($color-neutral-x-light, 0.08);
    color: $color-neutral-x-light;

    .c-nav__icon {
      use {
        fill: $color-neutral-x-light;
      }
    }
  }
}

.c-nav__item-description {
  position: absolute;
  font-family: $font-family-regular;
  font-weight: normal;
  margin-left: 56px;

  // this is when nav__ite is an a
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  // this is when nav__item is a button
  left: 16px;
  top: 50%;
  transform: translateY(-50%);

  width: calc(100% - 56px - 16px - 16px);
}

.c-nav__icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-nav__item--secondary {
  text-align: center !important;

  position: relative;
  bottom: 0;
  height: 72px;
  width: 100%;
  border-radius: 0;
  padding: 8px;
  margin: 0;

  .c-icon {
    use {
      fill: $color-neutral-dark;
    }
  }

  &:hover,
  &:focus,
  &:active {
    background: rgba($color-neutral-x-light, 0.08);
    color: $color-neutral-x-light;

    .c-icon {
      use {
        fill: $color-neutral-x-light;
      }
    }
  }

  .c-nav__icon {
    top: 50%;
    transform: translateY(-50%);
    left: 8px;
  }

  .c-nav__item-description {
    margin-left: 56px;
    text-align: left;
    padding-left: $spacing-tiny;
  }
}

.c-nav__item--signout {
  display: none;

  @include mq($until: small) {
    display: flex;
    flex: 0 0 auto;
  }
}

.c-nav__item--bottom {
  position: relative;
  margin: $spacing-small 0 !important;
  width: 100%;
  min-width: calc(100% - 16px);

  &:first-of-type {
    margin-top: auto !important;
  }

  &:first-of-type:last-of-type {
    margin-top: auto !important;
  }
}

.c-nav__logout {
  bottom: 0;
}
