@import '../../_scss/settings';

.c-tooltip {
  position: relative;
  display: inline-block;
  color: $color-neutral-dark;

  /** USED IN **/
  // Progress Bar
  .c-progress-bar--block-value + & {
    margin-left: $spacing-tiny;
  }

  // Accordion
  .c-accordion--cogwheel.c-accordion--small .c-input__description > & {
    display: none; // TODO: re-activate the tooltips later
  }

  // Lists
  .c-list--with-dropdown .c-list__item-one-line .c-list__item-editing-area &,
  .r-list__form & {
    position: absolute;
    top: 6px;
    right: 0;
    display: flex;
    max-height: 40px;

    &:focus-within {
      z-index: 10;
    }
  }

  .c-list--sublist .c-list__form-with-slider.c-list__item-small-editing-area & {
    top: $spacing-medium;
    height: auto;
    transform: none;
  }

  //   .r-list--sublist .r-list__form > & {
  //     top: 18px;
  //     right: 16px;
  //   }
}

.c-tooltip--popper {
  position: static;
}

.c-tooltip--alert {
  color: $color-error;
}

.c-tooltip__trigger:not(
    .c-list__item-small-editing-area .c-tooltip__trigger
  ):not(.c-list__item-double-icon-info .c-tooltip__trigger) {
  color: inherit;
}

.c-tooltip__trigger {
  &:focus + .c-tooltip__message[role='tooltip'] {
    display: flex;
    z-index: 21;
  }
}

.c-tooltip__message {
  display: none;
  background: $color-neutral-x-light;
  font-family: $font-family-regular;
  margin: 0;
  position: absolute;

  padding: $spacing-medium ($spacing-small * 1.5);
  // text-transform: uppercase;
  color: inherit;
  border-radius: $small-radius;
  // white-space: nowrap;
  // white-space: normal;

  align-items: center;
  box-shadow: $menu-box-shadow;
  min-width: 200px;
  z-index: 1;

  @include inuit-font-size(12px, 1.3);

  .c-tooltip--right & {
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + #{$spacing-small});
  }

  .c-tooltip--left & {
    top: 50%;
    transform: translateY(-50%);
    right: calc(100% + #{$spacing-small});
  }

  .c-tooltip--bottom & {
    top: calc(100% + #{$spacing-small});
    bottom: auto;
  }

  .c-tooltip--top & {
    top: auto;
    bottom: calc(100% + #{$spacing-small});
  }

  .c-tooltip--bottom.c-tooltip--right & {
    top: calc(100% + 8px);
    bottom: auto;
    transform: none;
    left: auto;
    right: 0;
  }

  .c-tooltip--top.c-tooltip--right & {
    bottom: calc(100% + 8px);
    top: auto;
    transform: none;
    left: auto;
    right: 0;
  }

  b {
    color: $color-primary-dark;
  }
}

.c-tooltip--info {
  .c-tooltip__message {
    span {
      max-width: 220px;
      width: 90vw;
    }

    .c-icon {
      stroke: $color-error;
      flex: 1 0 $large-icon;
      width: $large-icon;
      height: $large-icon;
      opacity: 0.5;
      margin-right: $spacing-small * 1.5;
    }
  }
}

.c-tooltip--video {
  .c-tooltip__message {
    padding: 0;
    overflow: hidden;
  }

  .c-tooltip__trigger:hover + .c-tooltip__message[role='tooltip'],
  .c-tooltip__trigger:focus + .c-tooltip__message[role='tooltip'] {
    display: block;
  }

  .video-react .video-react-video {
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .video-react-big-play-button {
    opacity: 0 !important;
  }

  .p-video-player {
    z-index: 101;
    position: relative;
  }
}

.c-tooltip--inline-text {
  margin: 0 $spacing-tiny 1px;

  .c-tooltip__trigger {
    display: inline-block;
    border-bottom: 1px dashed currentColor;

    span {
      text-transform: none;
      position: relative;
      display: inline-block;
    }
  }
}

.c-tooltip--inherited-styles-active .c-button {
  box-shadow: 0px 0px 1px 1px currentColor;
  border-radius: 20px;
  background: currentColor;
  text-shadow: 1px 1px 1px #000a;
  padding-left: 8px !important;
  transform: scale(1.05);

  span,
  svg {
    color: white;
  }

  .c-icon--inherit-color-growth-red {
    margin-left: 4px;
    margin-right: 2px;
  }

  &:focus {
    outline-offset: 0;
  }
}

.c-tooltip__info {
  color: white;
  z-index: 101;
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(25, 25, 25, 0.5);
  text-shadow: 1px 1px 1px black;
  margin: 0;
  padding: 8px;
}

.c-tooltip-popper__message {
  display: block;
  z-index: 10;

  span:not([class]) {
    display: inline-block;
    max-width: 220px;
    width: 90vw;
  }
}

.c-tooltip-popper__message-video {
  margin: auto !important;
  position: static;
  display: block;

  padding: 0;
  overflow: hidden;
  z-index: 10;

  .ReactModalPortal ~ & {
    z-index: 101;
  }
}

.c-tooltip-popper__message-graphic {
  padding: $spacing-medium;
  display: flex;
  flex-direction: column;
  max-width: 300px;
}

.c-tooltip__message.c-tooltip-popper__message--auto-width {
  min-width: auto;
}

.c-toggletip-message {
  background: $color-neutral-x-light;
  font-family: $font-family-regular;
  margin: 0;

  padding: $spacing-medium ($spacing-small * 1.5);
  color: $color-neutral-dark;
  border-radius: $small-radius;

  align-items: center;
  box-shadow: $menu-box-shadow;
  min-width: 200px;
  overflow: hidden;
  z-index: 10;

  @include inuit-font-size(12px, 1.3);

  span {
    display: flex;
    max-width: 220px;
    width: 90vw;
  }

  .c-icon {
    stroke: $color-error;
    flex: 1 0 $large-icon;
    width: $large-icon;
    height: $large-icon;
    opacity: 0.5;
    margin-right: $spacing-small * 1.5;
  }

  b {
    color: $color-primary-dark;
  }
}

.c-tooltip-popper__message--hidden,
.c-toggletip-message--hidden {
  visibility: hidden;
  pointer-events: none;
}
