/* ==========================================================================
   #ROW
   ========================================================================== */

/**
 * Creates even spaced responsive rows using the default InuitCSS spacing variables.
 *
 *   <div class="o-row">
 *     <div class="o-wrapper">
 *     </div>
 *   </div>
 *
 */

$inuit-row-breakpoint: medium !default;

/* Default classes.
   ========================================================================== */

.o-row {
  padding-top: $inuit-global-spacing-unit-medium;
  padding-bottom: $inuit-global-spacing-unit-medium;

  @include mq($inuit-row-breakpoint) {
    padding-top: $inuit-global-spacing-unit-large;
    padding-bottom: $inuit-global-spacing-unit-large;
  }
}

.o-row-tiny {
  padding-top: $inuit-global-spacing-unit-tiny;
  padding-bottom: $inuit-global-spacing-unit-tiny;

  @include mq($inuit-row-breakpoint) {
    padding-top: $inuit-global-spacing-unit-small;
    padding-bottom: $inuit-global-spacing-unit-small;
  }
}

.o-row-small {
  padding-top: $inuit-global-spacing-unit-small;
  padding-bottom: $inuit-global-spacing-unit-small;

  @include mq($inuit-row-breakpoint) {
    padding-top: $inuit-global-spacing-unit-medium;
    padding-bottom: $inuit-global-spacing-unit-medium;
  }
}

.o-row-large {
  padding-top: $inuit-global-spacing-unit-large;
  padding-bottom: $inuit-global-spacing-unit-large;

  @include mq($inuit-row-breakpoint) {
    padding-top: $inuit-global-spacing-unit-huge;
    padding-bottom: $inuit-global-spacing-unit-huge;
  }
}

.o-row-huge {
  padding-top: $inuit-global-spacing-unit-huge;
  padding-bottom: $inuit-global-spacing-unit-huge;
}
