@import '../../_scss/settings';

.c-input__wrapper {
  position: relative;

  &:focus-within .c-input {
    outline: 2px solid $color-primary;
  }

  &:not(.c-menu__wrapper) {
    display: block;
  }

  &.o-flex {
    display: flex;
  }
}

.c-input__group {
  margin-bottom: $spacing-medium;
  flex: 1 1 auto;
}

.c-input__group--readonly {
  .c-button--tiny {
    position: absolute;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    right: 0;
    top: 0;
    height: 100%;
    z-index: 1;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .c-button--tiny:hover,
  .c-button--tiny:focus {
    background-color: $color-neutral-x-light;
    outline-offset: -1px;
  }

  .c-input__wrapper {
    background-color: $color-neutral-light;
    border: 1px solid $color-neutral;
    border-radius: 4px;

    &:focus-within .c-input {
      outline: none;
    }
  }

  .c-input {
    border: 0;
  }

  .c-input:has(+ .c-button--tiny) {
    width: calc(100% - 36px);
    margin-bottom: 0;
  }
}

.c-input--expandable {
  height: 32px;
  width: 42px;
  margin-left: auto;
  transition: all 0.2s ease-in-out;

  .c-input__wrapper {
    height: 100%;
  }

  .c-input {
    height: 100%;
  }

  .c-icon--input-left {
    left: 12px;
  }

  .c-input:focus {
    color: inherit;
    padding-left: 32px;
  }

  &:focus-within,
  &.c-input--expanded {
    width: 100% !important;
  }

  &.c-input--disabled {
    input {
      cursor: not-allowed;
    }

    .c-icon--input-left {
      opacity: 0.4;
    }
  }
}

.c-input--search {
  .p-tabs__tab-header:has(&) {
    position: relative;
  }

  .p-tabs__tab-header & + .c-button--mini {
    display: none;
    position: absolute;
    right: 21px;
    top: 5px;
  }

  .c-card__body--modal .p-tabs__tab-header & + .c-button--mini {
    right: 30px;
  }

  .p-tabs__tab-header & + .c-button--mini {
    .c-icon {
      width: 12px;
      height: 12px;
    }
  }

  &.c-input--search-filled,
  &.c-input--expanded {
    .c-input {
      padding-right: $spacing-large;
    }

    & + .c-button--mini {
      display: flex;
      border-radius: 50%;
      padding: 4px;
    }
  }
}

.c-input {
  outline: none;
  border: 1px solid $color-neutral;
  border-radius: $tiny-radius;

  // @include inuit-font-size($inuit-global-font-size);
  font-family: $font-family-regular;
  color: $color-primary-dark;

  width: 100%;

  padding: $spacing-small;
  -webkit-appearance: initial;
  // padding-right: $spacing-large + $spacing-small;

  @include inuit-font-size(13px, 1.15);

  &.has-error {
    color: $color-error;
    border-color: $color-error;
  }

  &:not(.c-input--checkbox)[disabled] {
    -webkit-text-fill-color: $color-primary-dark;
    opacity: 1; /* required on iOS */
  }

  .c-input__group-select-year &[disabled] {
    -webkit-text-fill-color: $color-primary-dark-25;
  }
}

.c-input--rounded {
  border-radius: $medium-radius;
  padding-left: $spacing-large;
}

.c-icon--input-left {
  right: auto;
  left: ($spacing-small + 3px);
  pointer-events: none;
}

.c-input--icon-left.c-input--disabled.c-input--read-only {
  &.c-input--read-only .c-input {
    padding-left: $spacing-medium * 1.5;
    word-break: break-all;
  }
  .c-icon--input-left {
    top: 0;
    left: 0;
  }
}

.c-input--url {
  padding-right: $spacing-large + $spacing-small;
}

.c-input--textarea {
  resize: none;
}

.c-input--textarea-resize-vertical {
  resize: vertical;
}

.c-input--textarea-readonly {
  font-family: $font-family-regular;
  color: $color-primary-dark;
  @include inuit-font-size(13px, 1.15);
}

.c-input__label {
  position: relative;
  display: block;
  font-family: $font-family-bold;
  color: $color-neutral-dark;
  text-transform: uppercase;

  // margin-left: 2px;
  margin-bottom: $spacing-small;

  @include inuit-font-size(10px, 1.2);

  // transition: all 0.25s ease-in-out;

  &.c-input__label-flex {
    display: flex;
    flex-wrap: wrap;
  }
}

.c-input__label-extra-info {
  font-family: $font-family-light;
  color: $color-primary-dark;
  text-transform: none;

  margin-left: auto;
  margin-right: $spacing-tiny;

  @include inuit-font-size(12px);

  > .u-color-error {
    font-family: $font-family-regular;
  }
}

.c-input--large {
  border: none !important;
  // padding: 20px 75px 25px 10px;
  text-align: center;
  // font-size: $sizeH1Small;
  color: $color-primary;

  // @include mq($from: tiny) {
  //   padding: 20px 75px;
  // }
}

.c-input--disabled {
  .c-input {
    background: $color-neutral-light;
    max-height: $spacing-large;
  }
}

.c-input--read-only {
  .c-input__wrapper {
    line-height: 0;
  }

  .c-input__label {
    margin-bottom: $spacing-tiny;
  }

  .c-input {
    padding: 0;
    background: none;
    border: 0;
    margin-bottom: 0;

    &[type='date'] {
      margin-left: -$spacing-mini;
    }

    &.c-input--border {
      padding: $spacing-small;
      border: 1px solid $color-neutral;
    }
  }

  .c-input__group {
    margin-bottom: $spacing-small;
  }

  // .c-input__label {
  //   // margin-left: 0;
  //   // margin-bottom: 0;
  // }
  .c-list__item &.c-input--disabled {
    opacity: 0.7;

    .c-input__label {
      font-family: $font-family-regular;
    }

    .c-input {
      cursor: not-allowed;
      background-color: $color-neutral-light; //#f6f6f6
    }
  }
}

.c-input--outfaded {
  .c-input {
    background: $color-neutral-light;
  }

  .c-input__label {
    opacity: 0.6;
  }

  .c-input-select__single-value {
    color: $color-neutral-dark;
  }
}

// RADIOBUTTON
.c-input__group--radiobutton {
  // cursor: pointer;
  // display: flex;
  //   display: inline-block;
  display: block;

  // margin-right: $spacing-small;
  //   vertical-align: middle;
  flex: 0 auto;
  margin-top: $spacing-small;

  .c-input__label {
    display: inline-flex;
    margin: 0;
    cursor: pointer;
  }

  .c-input--radiobutton-card[type='radio'] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  [type='radio']:not(.c-input--radiobutton-card) {
    margin-right: $spacing-small;
  }

  &:not(:last-child) {
    margin-right: $spacing-medium * 1.5;
  }

  .c-input__group.c-input--inline {
    &:not(:last-child) {
      margin-right: $spacing-medium * 1.5;
    }
  }

  &.c-input__group-radiobutton-readonly {
    margin-top: -$spacing-tiny;

    .c-input__label {
      cursor: default;
    }
  }
}

.c-input--radiobutton {
  display: inline-block;
  width: auto;
  -webkit-appearance: radio;
  cursor: pointer;

  &.c-input--radiobutton-card:checked + .c-input__label {
    box-shadow: 0 2px 5px 0 rgba($color-primary-dark, 0.1);
    border: 1px solid $color-neutral-dark;
    position: relative;

    &:after {
      content: '';
      width: 32px;
      height: 16px;
      position: absolute;
      border-left: 16px solid transparent;
      bottom: -30px;
      left: 50%;
      transform: translateX(-50%);
      border-right: 16px solid transparent;
      border-bottom: 16px solid white;
      background: transparent;
    }

    &:before {
      content: '';
      width: 34px;
      height: 18px;
      position: absolute;
      border-left: 16px solid transparent;
      bottom: -30px;
      left: 50%;
      transform: translateX(-50%);
      border-right: 16px solid transparent;
      border-bottom: 16px solid $color-neutral;
      background: transparent;
    }
  }

  .c-input__group-radiobutton-readonly & {
    cursor: default;
  }
}

.c-input__group-radiobutton-org {
  min-height: 32px;
  position: relative;
  .c-input__label-wrapper {
    display: flex;
    flex-wrap: wrap;
    // flex-direction: column;
    .c-input__main-label {
      margin-right: $spacing-small;
    }

    .c-input__extra-label {
      display: block;
    }
  }
  .c-input--radiobutton {
    -webkit-appearance: none;
    opacity: 0;
    position: absolute;
    width: auto;

    & + .c-input__label--radiobutton {
      margin: 0;
      align-items: flex-start;
      user-select: none;
      cursor: pointer;
      // position: relative;
      padding: 0;
      padding-right: $spacing-small;

      font-family: $font-family-bold;
      color: $color-primary-dark;
      @include inuit-font-size(16px);

      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      // .c-input__extra-label{
      //   margin-left: 8px;
      // }
      @include mq($until: small) {
        align-items: flex-start;
        flex-wrap: wrap;
        margin-left: 40px;
        max-width: calc(100% - 40px);

        .c-input__extra-label {
          margin: auto;
          // text-align: right;
          width: 100%;
          flex: 1 1 auto;
          margin-bottom: 0;
          margin-top: 0;
          margin-left: 0;
        }
      }
    }

    & + .c-input__label--radiobutton:before {
      content: '';
      // margin-right: $spacing-large;
      display: inline-block;
      vertical-align: middle;
      left: 0;
      top: 0;
      width: 24px;
      height: 24px;
      flex: 0 0 24px;

      background-image: url('~/src/assets/images/svgstore/checkbox-inactive.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      // margin-left: -($spacing-small + $spacing-tiny);

      margin-right: $spacing-medium;
      margin-top: calc(-1 * $spacing-tiny / 2);

      .c-accordion & {
        margin-top: 0;
      }
    }

    &:checked + .c-input__label--radiobutton:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 24px;
      height: 24px;
      background-image: url('~/src/assets/images/svgstore/checkbox-active.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      // margin-left: -($spacing-small + $spacing-tiny);
      margin-right: $spacing-medium;
      margin-top: calc(-1 * $spacing-tiny / 2);

      .c-accordion & {
        margin-top: 0;
      }
    }

    @include mq($until: small) {
      & + .c-input__label--radiobutton:before,
      &:checked + .c-input__label--radiobutton:after {
        left: -32px;
        position: absolute;
      }
    }
  }
}

.c-input__cols-group,
.c-input__three-cols-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include mq($from: medium) {
    .c-input__group {
      width: 32%;
      margin-right: 1%;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.c-input__cols-group {
  align-items: flex-end;

  .c-input__group:not(:last-child) {
    margin-right: 1%;
  }
}

.c-input__three-cols-group-label {
  > .c-input__label {
    align-self: center;
    margin-bottom: 0;
    width: 100%;

    @include mq($from: tiny) {
      width: 24%;
    }
  }

  .c-input__group {
    width: 24%;
    margin-right: 1%;

    &:last-child {
      margin-right: 0;
    }
    .c-input__label {
      padding-left: $spacing-small;
    }
  }
}

// CHECKBOXES

.c-input--fieldset-checkbox {
  .c-input__group--checkbox {
    margin-bottom: 0;

    .c-input__text {
      font-family: $font-family-regular;
    }
  }
}

.c-input__group--checkbox {
  margin-top: 0;
  margin-bottom: $spacing-medium;
  width: auto;
  display: flex;
  position: relative;
}

.c-input-checkbox--with-input {
  width: 100%;
  display: flex;
  align-items: center;

  .c-input__group:first-child {
    display: block;
    flex: 1 1 32%;
  }

  .c-input__group:nth-child(2) {
    flex: 1 0 65%;
    margin-right: 1%;
  }

  .c-input--read-only {
    margin-bottom: 0;
  }
}

.c-input__description {
  color: $color-neutral-dark;
  line-height: 1.5;

  .c-tooltip--popper {
    position: absolute;
    margin-left: $spacing-tiny;
  }

  .c-accordion--switch > & {
    text-align: right;
  }
}

.c-input-checkbox--with-paragraph {
  :not(.c-list__item-editing-area) .c-input__label--checkbox .c-input__text {
    font-family: $font-family-regular;
    font-weight: normal;
    color: $color-primary-dark;
  }

  .c-input--checkbox--secondary
    .c-input--checkbox:disabled
    + .c-input__label
    .c-input__text {
    color: $color-primary-dark;
  }

  .c-input__group + .c-input__description {
    padding: 0 ($spacing-small * 1.5) $spacing-small * 1.5;
    margin-top: -$spacing-tiny;
    margin-bottom: 0;
  }

  &:focus-within {
    outline-offset: -4px;
    outline: 2px solid $color-primary;
  }

  > .c-card__header-title {
    margin-left: 12px;
    margin-bottom: 0;
    margin-top: 16px;

    @include inuit-font-size(13px, 1.5);
  }

  .c-card__body--modal & .c-input--checkbox + .c-input__label--checkbox {
    padding: 0 $spacing-small;
  }

  .c-card__body--modal
    &
    .c-input--checkbox:checked
    + .c-input__label--checkbox::after {
    left: $spacing-small;
  }
}

.c-card__body--modal {
  .c-input__group--checkbox:not(.c-input--checkbox--in-list) {
    .c-input--checkbox {
      & + .c-input__label--checkbox:before {
        width: calc($huge-icon / 2);
        flex: 0 0 calc($huge-icon / 2);
      }

      &:checked + .c-input__label--checkbox:after {
        width: calc($huge-icon / 2);
      }
    }

    .c-input__text-group {
      margin-left: $spacing-small;
    }
  }
}

*:not(.c-list__item-editing-area > .c-input__group-benchmarks)
  .c-input__label--checkbox {
  // min-height: $spacing-small + $spacing-medium;
  display: flex;
  @include inuit-font-size(13px, 1.2);

  .c-input__text {
    text-transform: none;
    color: $color-primary-dark;
    font-family: $font-family-bold;
    padding-right: $spacing-small + $spacing-tiny;
  }
}

.c-input__checkbox--full {
  width: 100%;

  .c-input__label--checkbox {
    width: 100%;
  }
}

.c-input__text-group {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.c-input__extra-label {
  color: $color-neutral-dark;
  font-family: $font-family-light;
  text-transform: none;
  margin-right: $spacing-medium;

  .c-input__label--radiobutton & {
    // margin-left: $spacing-small;
  }
}

.c-input--checkbox {
  -webkit-appearance: none;
  opacity: 0;
  position: absolute;
  width: auto;

  & + .c-input__label--checkbox {
    margin: 0;

    user-select: none;
    cursor: pointer;
    position: relative;
    padding: 0;

    // padding: $spacing-tiny 0 $spacing-tiny $spacing-large;
    // background-image: url('~/src/assets/images/svgstore/checkbox-no.svg');
    // background-position: left center;
    // background-repeat: no-repeat;
  }

  & + .c-input__label--checkbox:before {
    content: '';
    // margin-right: $spacing-large;
    display: inline-block;
    vertical-align: middle;
    left: 0;
    // top: 0;
    width: $huge-icon;
    height: $huge-icon;
    flex: 0 0 $huge-icon;

    background-image: url('~/src/assets/images/svgstore/checkbox-no.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // margin-left: -($spacing-small + $spacing-tiny);

    // position: absolute;
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    .c-list__item-checkbox & {
      background-position: center;
    }
  }

  &:checked + .c-input__label--checkbox:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: $huge-icon;
    height: $huge-icon;
    background-image: url('~/src/assets/images/svgstore/checkbox-yes.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transform: translateY(-50%);

    // margin-left: -($spacing-small + $spacing-tiny);

    .c-list__item-checkbox & {
      background-position: center;
    }
  }

  .c-input--checkbox--indeterminate
    &:not(:checked)
    + .c-input__label--checkbox:after {
    background-image: url('~/src/assets/images/svgstore/checkbox-some.svg');
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
    width: $small-icon;
    height: $small-icon;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.c-input--fieldset-checkbox-two-inline {
  display: flex;
  flex-wrap: wrap;

  > .c-input__label,
  > .c-input__error {
    flex: 1 0 100%;
  }

  > .c-input__group--checkbox {
    flex: 0 0 50%;
  }

  .c-card__body--modal & .c-input__group--checkbox {
    .c-input__text-group {
      margin-left: $spacing-tiny;
    }
  }

  .c-input--checkbox {
    & + .c-input__label--checkbox {
      height: $spacing-large;
      padding-left: $spacing-medium * 1.5;
    }

    & + .c-input__label--checkbox:after,
    & + .c-input__label--checkbox:before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .c-input--checkbox-disabled {
    & + .c-input__label--checkbox {
      height: $spacing-medium;
      margin-top: -$spacing-tiny;
      margin-left: -$spacing-mini;
      opacity: 0.35;
    }

    &:checked + .c-input__label--checkbox {
      opacity: 1;
    }
  }
}

.c-input--checkbox--simple {
  .c-input__label--checkbox {
    display: block;
    font-family: $font-family-bold;
    color: $color-neutral-dark;
    text-transform: uppercase;
    @include inuit-font-size(10px, 1.2);
  }

  .c-input--checkbox {
    & + .c-input__label--checkbox:before,
    &:checked + .c-input__label--checkbox:after {
      width: $spacing-medium;
      flex: 0 0 $spacing-medium;
      margin-right: $spacing-small;
    }
  }
}

.c-input--checkbox-disabled {
  & + .c-input__label.c-input__label--checkbox {
    cursor: default;

    .c-input__text {
      font-family: $font-family-regular;
    }
  }

  & + .c-input__label--checkbox:before {
    // border:1px solid red;
    background-image: url('~/src/assets/images/svgstore/checkbox-no-disabled.svg');
    // border: 1px solid blue;
  }

  &:checked + .c-input__label--checkbox:after {
    // border:1px solid red;
    background-image: url('~/src/assets/images/svgstore/checkbox-yes-disabled.svg');
    // border: 1px solid red;
  }
}

.c-input--checkbox--secondary {
  margin-bottom: 0;

  .c-input__text {
    font-family: $font-family-regular;
    font-weight: bold;
  }

  .c-input--checkbox {
    &:checked + .c-input__label--checkbox:after {
      background-image: url('~/src/assets/images/svgstore/checkbox-yes-blue.svg');
    }

    &:disabled {
      + .c-input__label--checkbox {
        cursor: default;
      }

      + .c-input__label .c-input__text {
        color: $color-neutral-dark;
        font-family: $font-family-regular;
        font-weight: normal;
      }

      + .c-input__label--checkbox:before {
        // border:1px solid red;
        background-image: url('~/src/assets/images/svgstore/checkbox-no-disabled.svg');
        // border: 1px solid blue;
      }

      &:checked + .c-input__label--checkbox:after {
        // border:1px solid red;
        background-image: url('~/src/assets/images/svgstore/checkbox-yes-disabled.svg');
        // border: 1px solid red;
      }
    }
  }

  &.c-input--checkbox--indeterminate
    .c-input--checkbox:not(:checked)
    + .c-input__label--checkbox:after {
    background-image: url('~/src/assets/images/svgstore/checkbox-some-blue.svg');
  }

  &.c-input--checkbox--indeterminate
    .c-input--checkbox:disabled
    + .c-input__label--checkbox:after {
    background-image: url('~/src/assets/images/svgstore/checkbox-some-disabled.svg');
  }
}

.c-input--checkbox--rounded {
  background-color: $color-neutral-light;
  border: 1px solid $color-neutral;
  border-radius: $medium-radius;

  .c-button__group & .c-input__label.c-input__label--checkbox {
    margin-left: 0 !important;
  }

  .c-input__text-group {
    line-height: 1.1;
    padding: 8px 0;
  }

  .c-input__text {
    font-family: $font-family-regular !important;
    color: $color-neutral-dark !important;
  }

  .c-input--checkbox + .c-input__label--checkbox::before {
    height: auto;
    flex: 0 0 auto;
  }
}

// ***
.c-input--checkbox--in-list {
  display: none;
  height: 100%;
  margin-bottom: 0;

  .c-input__label {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &:before {
      width: 48px;
      height: 48px;
      flex: 1;
    }
  }

  .c-input--checkbox:checked + .c-input__label--checkbox:after {
    width: 48px;
    height: 48px;
  }

  &.c-input--checkbox--checked {
    display: block !important;
  }

  // Indeterminated status in list-item
  &.c-input--checkbox--indeterminate
    .c-input__label.c-input__label--checkbox::after {
    width: 19px;
    height: 19px;
    transform: translate(-50%, -50%);
    left: 50%;
  }
}

.c-list__item-free-height .c-input--checkbox--in-list .c-input__label {
  top: 50%;
  transform: translateY(-50%);
}

.c-input--checkbox--checked {
  & + .c-icon,
  & + .c-list__initials {
    display: none !important;
  }
}

.c-input--checkbox--visible {
  display: block !important;
}

.c-input--checkbox--visible {
  & + .c-icon,
  & + .c-list__initials {
    display: none !important;
  }
}

// Select:

.c-input__group-select {
  .c-menu__wrapper {
    max-width: none;
    position: relative;
  }

  .c-menu--open {
    max-width: none;

    width: 100%;
    min-width: auto;
    max-height: 190px;

    .c-menu__item {
      padding-left: $spacing-medium;
    }
  }

  [type='button'] {
    -webkit-appearance: none;
  }

  [type='button'][disabled] {
    user-select: text;
    white-space: normal;
    cursor: not-allowed;
  }

  &.c-input__group-select-standalone {
    margin-top: $spacing-small;
    margin-bottom: $spacing-tiny;
    flex: 0 0 auto;
  }

  .c-panel--benchmark .c-panel__header & {
    flex: 1;
  }
}

.c-input__group-select-tags {
  margin-top: 8px;
  margin-bottom: 0;
  flex: 0;
}

.c-input__group-select-year {
  z-index: 22;
  margin-bottom: 0;
  flex: 1 1 auto;
}

.o-layout__item .c-input__group-benchmarks {
  align-items: stretch;
  margin: 0 0 $spacing-tiny 0;
  min-height: 100%;

  .c-input__group-select {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacing-small * 1.5;

    .c-input__wrapper--select {
      width: 100%;
      height: auto;
      display: flex;
      flex: 1 0 auto;
    }
  }

  .c-input__label {
    margin-bottom: $spacing-tiny;
    flex: 0;
  }
}

.c-input-select {
  width: 100%;
  line-height: 1.2;
  font-family: $font-family-regular;
  font-weight: 400;
  height: 100%;

  .c-input-select__control {
    background: inherit;

    &,
    &:hover {
      min-height: 32px;
      border-color: $color-neutral;
      height: 100%;
    }

    &.c-input-select__control--is-focused {
      box-shadow: none;
      outline-offset: 0;
      outline: 2px solid $color-primary !important;
    }
  }

  .c-input-select__menu {
    z-index: 21;
    box-shadow: $menu-box-shadow;
  }

  .c-input-select__menu-list {
    padding-top: $spacing-small;
    padding-bottom: $spacing-small;
  }

  .c-input-select__indicators {
    padding: 0;

    .c-icon--input {
      position: static;
    }
  }

  .c-input-select__clear-indicator {
    padding: 0;
    color: $color-neutral-dark;
    margin-right: -$spacing-tiny;
  }

  .c-input-select__dropdown-indicator {
    padding: $spacing-tiny $spacing-small;
  }

  .c-input-select__option {
    color: $color-neutral-dark;
    padding: $spacing-small ($spacing-small * 1.5);
    line-height: 1.2;

    &:hover,
    &.c-input-select__option--is-focused {
      color: $color-neutral-dark;
      background-color: $color-primary-10;
    }
  }

  .c-input-select__option--is-selected {
    color: $color-neutral-x-dark !important;
    background-color: $color-neutral-x-light !important;

    &::before {
      content: '\27A4';
      font-weight: 900;
      font-family: $font-family-bold;
      display: inline-block;
      margin-right: 8px;
      color: $color-primary-dark;
    }
  }

  .c-input-select__value-container {
    padding: $spacing-tiny $spacing-small;
  }

  .c-input-select__single-value {
    white-space: normal;
    overflow: visible;
  }

  .c-input--outfaded & {
    .c-input-select__menu {
      background: $color-neutral-light;
    }

    .c-input-select__option--is-selected::before {
      color: $color-neutral;
    }

    .c-input-select__option--is-selected {
      color: $color-neutral-dark !important;
      background-color: $color-neutral-light !important;
    }
  }
}

// `customMultiValue`:
.c-input-select--menu-250-width {
  .c-input__label {
    margin-bottom: $spacing-tiny;
  }

  .c-input-select__placeholder {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .c-input-select__menu {
    min-width: 250px;
  }
}

.c-input--select {
  text-align: left;
  background-color: rgba($color-neutral-x-light, 0);
  z-index: 5;

  padding-right: $spacing-large;

  & + .c-icon--input {
    z-index: 0;
  }
}

.c-input--select-placeholder {
  color: $color-primary-dark-50;
}

.c-input--select-open-from-left {
  .c-menu--open {
    width: 100%;
    left: 0;
    right: auto;
  }
}

.c-input__wrapper--select {
  border-radius: $tiny-radius;
  background: $color-neutral-x-light;

  .c-input--outfaded & {
    background: rgba($color-neutral-light, 0.6);
  }
}

.c-input__sup-value {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 9px;
  text-transform: uppercase;
  width: 100%;
  color: $color-neutral-dark;
  font-family: $font-family-bold;
  text-align: left;

  @include inuit-font-size(10px, 1.2);

  & + .c-input--select {
    min-height: 40px;
    padding-top: 18px;
    padding-bottom: 4px;
    @include inuit-font-size(13px, 1.2);
  }
}

.c-input--inline .c-input__wrapper {
  flex: 1 0 auto;
  margin-left: 16px;
}

.c-input--inline + .c-list {
  margin-top: 0 !important;
}

// Error Message:
.c-input-error-group {
  display: flex;
  flex-direction: column;

  .c-input__error {
    margin-bottom: 0;
  }

  .c-input__error:last-child {
    margin-top: $spacing-tiny;
    margin-bottom: 0;
  }
}

.c-input__error {
  margin-top: $spacing-tiny;
  color: $color-error;

  @include inuit-font-size(10px);

  .c-input__group-select + &,
  .c-input__group-select + fieldset.c-input-group + & {
    margin-top: $spacing-small * -1.5;
  }

  .c-card--panel & {
    margin-bottom: 0;
  }

  .c-card__header-title + & {
    margin-top: $spacing-medium;
  }

  .c-list & {
    position: absolute;
    top: 100%;
    // right: $spacing-medium;
    // left: 50%;
    // width: auto;
    background: $color-neutral-x-light;
    z-index: 11;
    box-shadow: $menu-box-shadow;
    padding: $spacing-small $spacing-medium;
    line-height: 1.2;
    border-radius: $tiny-radius;
    // min-width: 130px;

    right: 0;
    left: auto;
    width: 100%;
    min-width: 200px;

    font-family: $font-family-regular;
  }

  &:last-child {
    margin-top: 0;
    margin-bottom: -12px;
  }
}

// Date > chrome input button:
input[type='date'] {
  max-height: 32px;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-outer-spin-button {
  height: 100% !important;
}

input[type='date']::-webkit-calendar-picker-indicator {
  opacity: 0;
  position: absolute;
  right: $spacing-small;
}

input[type='date'][disabled] {
  color: $color-primary-dark;
  -webkit-text-fill-color: $color-primary-dark !important; /* 1. sets text fill to current `color` for safari */
}

.c-input__group-benchmarks {
  display: flex;
  align-items: flex-end;
  margin: $spacing-small 0;

  .c-input__group-label {
    margin-bottom: $spacing-small;
    margin-right: $spacing-small;
    color: $color-primary-dark;
    font-family: $font-family-light;
    text-transform: capitalize;

    @include inuit-font-size(13px);
  }

  .c-input__group {
    margin-bottom: 0;
    margin-right: 16px;

    &.c-input__group--checkbox:focus-within {
      outline-offset: 0;
      outline: 2px solid $color-primary;
    }
  }

  .c-input__group-select {
    min-width: 0;
    margin: 0;
  }
}

.c-input__group-range-date {
  margin: $spacing-large 0;
  flex-wrap: nowrap;
  align-items: flex-start;

  .c-input__group {
    flex: 1 1 100%;
    margin-bottom: $spacing-medium;
    margin-right: 0;

    &:not(:last-child) {
      margin-right: $spacing-small;
    }
  }

  & + p {
    position: absolute;
    top: calc(100% - 60px);
    max-width: calc(100% - #{$spacing-huge});
  }

  .c-card__body & {
    margin: 0;
  }

  @include mq($until: small) {
    flex-wrap: wrap;

    .c-input__group:not(:last-child) {
      margin-right: 0;
    }
  }
}

.c-input--in-table {
  padding: 0;
  border: 0;
  background: none;
  text-align: center;

  @include inuit-font-size(13px, 1);
}

.c-input--inline {
  display: flex;
  align-items: center;

  .c-input__label {
    margin-bottom: 0;
    margin-right: $spacing-tiny;
  }
}

.p-select-container {
  flex: auto;

  &.o-flex {
    flex-wrap: wrap;
  }

  .p-select__control {
    min-height: 34px;
    border-color: $color-neutral;

    &:hover {
      border-color: $color-neutral;
    }
  }

  .p-select__menu {
    z-index: 10;
    min-width: 280px;
    overflow: hidden;
  }

  .p-select__menu-list.p-select__menu-list--is-multi {
    max-height: 160px;
  }

  .p-select__value-container {
    padding: 2px 4px;
  }

  .p-select__input,
  .p-select__placeholder {
    padding-left: 4px;
  }

  .p-select__control--is-focused {
    box-shadow: none;
    border-color: $color-neutral;
    outline: 2px solid $color-primary !important;
  }

  .p-select__multi-value {
    background-color: $color-primary;
    color: $color-primary;

    border-radius: $spacing-large;
    display: inline-flex;
    margin: 2px;
    min-width: 0;
    max-width: 100%;
    box-sizing: border-box;

    &:hover {
      //   background-color: $color-primary-25;
      background-color: rgb(198, 217, 245);

      .p-select__multi-value__label,
      .c-icon use {
        color: $color-neutral-x-dark;
        stroke: $color-neutral-x-dark;
      }
    }
  }

  .p-select__multi-value__remove {
    cursor: pointer;
    padding-right: 12px;

    .c-icon {
      width: 12px;
      height: 12px;

      use {
        fill: $transparent;
        stroke: $color-neutral-x-light;
      }
    }

    &:hover {
      background: $transparent;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;

      .c-icon use {
        stroke: $color-neutral-x-dark;
      }
    }

    &.c-button {
      padding-right: 2px;
      width: 24px;
      border-radius: 24px;
      display: flex;
      justify-content: center;
      margin-right: 4px;
      margin-left: -2px;

      &:focus {
        outline-color: white;
        outline-offset: -3px;
        outline-width: 1px;
      }
    }
  }

  .p-select__multi-value__label {
    padding: 2px 4px 2px 12px;
    color: $color-neutral-x-light;

    @include inuit-font-size(13px);

    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .p-select__option {
    color: $color-neutral-dark;

    &:active,
    &.p-select__option--is-focused,
    &.p-select__option--is-focused:active,
    &.p-select__option--is-selected {
      background-color: $color-primary-10;
    }
  }

  &.p-select--is-disabled {
    > .p-select__control.p-select__control--is-disabled {
      border: 0;
      background: transparent;
      padding: 0;
      min-height: 0;
    }

    .p-select__value-container {
      padding: 0;
      margin-left: -4px;
      gap: 2px;
    }

    .p-select__indicators {
      display: none;
    }

    .p-select__multi-value__remove {
      display: none;
    }

    .p-select__multi-value__label {
      padding-right: 12px;
    }

    .p-select__placeholder {
      padding-left: 2px;
      @include inuit-font-size(12px);
    }

    .p-select__input-container {
      margin: 0;
      padding: 0;
    }
  }
}

.c-button--filter-close:focus {
  outline-color: white;
  outline-offset: -3px;
  outline-width: 1px;
}

.c-panel__title-wrapper,
.r-panel__header + .c-input__group-select-tags {
  .p-select__multi-value {
    background-color: $color-primary-10;
    margin: 0;
  }
  .p-select__multi-value__label {
    color: $color-primary;
    font-family: $font-family-bold;
  }
}

.p-select__value-empty {
  min-height: $spacing-medium;
}

.p-select-container__wrapper {
  padding: 0;
  border-radius: 4px;

  .p-select__multi-value {
    background-color: transparent;
    //background-color: rgba(255,255,255,0.4);
    color: #071649;
    font-size: 85%;
    overflow: hidden;
    padding: 3px 6px;
    //padding-left: 3px;
    //padding-left: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid $color-neutral;
  }
}

// Editor input:
.c-textarea--editor {
  .field-item {
    background: white;
    padding: $spacing-medium;
    margin-bottom: $spacing-medium;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: $font-family-bold;
      margin-bottom: 8px;
      color: $color-primary-dark;

      * + & {
        margin-top: $spacing-medium;
      }
    }

    h1 {
      @include inuit-font-size(24px);
    }

    h2 {
      @include inuit-font-size(20px);
    }

    h3 {
      @include inuit-font-size(18px);
    }

    h4,
    h5,
    h6 {
      @include inuit-font-size(16px);
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }

  .field-level {
    display: none;
  }
}

// Input Range:
.c-input-slider {
  container: range-wrapper / inline-size;
}

.c-input-slider__wrapper {
  display: flex;

  // TODO REMOVE: REFACTORED `.r-list__form &`
  .r-list__form & {
    flex-wrap: wrap;
  }
}

input[type='range'].c-input--range {
  width: 100%;
  min-width: 100%;
  margin: 6px 0;
  background-color: transparent;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  flex: 1;

  position: relative;
  z-index: 1000;
}

input[type='range'].c-input--range:focus {
  outline: none;
}

input[type='range'].c-input--range::-webkit-slider-runnable-track {
  background: $color-primary-light;
  box-shadow: inset 0 0 6px -4px $color-neutral-x-dark;
  border: 0;
  border-radius: 12.2px;
  width: 100%;
  height: 8px;
  cursor: pointer;
}

input[type='range'].c-input--range::-webkit-slider-thumb {
  margin-top: -6px;
  width: 16px;
  height: 16px;
  background: #3c84f0;
  border: 2.2px solid rgba(0, 0, 0, 0);
  border-radius: 50px;
  cursor: pointer;
  -webkit-appearance: none;
}

input[type='range'].c-input--range:focus::-webkit-slider-runnable-track {
  background: #e1e9f5;
}

input[type='range'].c-input--range::-moz-range-track {
  background: $color-primary-light;
  box-shadow: inset 0 0 6px -4px $color-neutral-x-dark;
  border: 0;
  border-radius: 12.2px;
  width: 100%;
  height: 8px;
  cursor: pointer;
}

input[type='range'].c-input--range::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background: #3c84f0;
  border: 2.2px solid rgba(0, 0, 0, 0);
  border-radius: 50px;
  cursor: pointer;
}
input[type='range'].c-input--range::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 6px 0;
  color: transparent;
  width: 100%;
  height: 8px;
  cursor: pointer;
}
input[type='range'].c-input--range::-ms-fill-lower {
  background: #e1e9f5;
  border: 0;
  border-radius: 24.4px;
}
input[type='range'].c-input--range::-ms-fill-upper {
  background: $color-primary-light;
  box-shadow: inset 0 0 6px -4px $color-neutral-x-dark;
  border: 0;
  border-radius: 24.4px;
}

input[type='range'].c-input--range::-ms-thumb {
  width: 16px;
  height: 16px;
  background: #3c84f0;
  border: 2.2px solid rgba(0, 0, 0, 0);
  border-radius: 50px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
input[type='range'].c-input--range:focus::-ms-fill-lower {
  background: $color-primary-light;
}
input[type='range'].c-input--range:focus::-ms-fill-upper {
  background: #e1e9f5;
}

/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align: auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type='range'].c-input--range {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}

// TODO REMOVE: OLD - not REFACTORED:
.c-list__item.o-list-bare__item.c-list__item-small.c-list__item-small-editable.is-open:focus,
.c-list__item.o-list-bare__item.c-list__item-small.c-list__item-small-editable.is-open:focus-within,
.c-list__item.o-list-bare__item.c-list__item-small.c-list__item-small-editable.is-open:hover {
  input[type='range'].c-input--range::-webkit-slider-runnable-track {
    background: $color-neutral-x-light;
  }
  input[type='range'].c-input--range::-moz-range-track {
    background: $color-neutral-x-light;
  }
  input[type='range'].c-input--range::-ms-fill-upper {
    background: $color-neutral-x-light;
  }

  .c-input--disabled {
    input[type='range'].c-input--range::-webkit-slider-runnable-track {
      background: $color-neutral-x-light;
    }
    input[type='range'].c-input--range::-moz-range-track {
      background: $color-neutral-x-light;
    }
    input[type='range'].c-input--range::-ms-fill-upper {
      background: $color-neutral-x-light;
    }
  }
}

// REFACTORED:
.r-list__item:focus,
.r-list__item:focus-within,
.r-list__item:hover {
  > form input[type='range'].c-input--range::-webkit-slider-runnable-track {
    background: $color-neutral-x-light;
  }
  > form input[type='range'].c-input--range::-moz-range-track {
    background: $color-neutral-x-light;
  }
  > form input[type='range'].c-input--range::-ms-fill-upper {
    background: $color-neutral-x-light;
  }

  .c-input--disabled {
    input[type='range'].c-input--range::-webkit-slider-runnable-track {
      background: $color-neutral-x-light;
    }
    input[type='range'].c-input--range::-moz-range-track {
      background: $color-neutral-x-light;
    }
    input[type='range'].c-input--range::-ms-fill-upper {
      background: $color-neutral-x-light;
    }
  }
}

.c-input--disabled {
  cursor: auto !important;

  input[type='range'].c-input--range::-webkit-slider-runnable-track {
    cursor: inherit;
  }
  input[type='range'].c-input--range::-webkit-slider-thumb {
    cursor: inherit;
  }
  input[type='range'].c-input--range::-moz-range-track {
    cursor: inherit;
  }
  input[type='range'].c-input--range::-moz-range-thumb {
    cursor: inherit;
  }
  input[type='range'].c-input--range::-ms-track {
    cursor: inherit;
  }
  input[type='range'].c-input--range::-ms-thumb {
    cursor: inherit;
  }

  input[type='range'].c-input--range::-moz-range-track {
    box-shadow: none;
    background-color: $color-neutral;
  }

  input[type='range'].c-input--range::-webkit-slider-runnable-track {
    box-shadow: none;
    background-color: $color-neutral;
  }

  input[type='range'].c-input--range::-ms-fill-upper {
    box-shadow: none;
    background-color: $color-neutral;
  }

  input[type='range'].c-input--range::-webkit-slider-thumb {
    background: $color-neutral-dark;
  }
  input[type='range'].c-input--range::-moz-range-thumb {
    background: $color-neutral-dark;
  }
  input[type='range'].c-input--range::-ms-thumb {
    background: $color-neutral-dark;
  }
}

// TODO REMOVE: OLD - not REFACTORED:
.c-list form:not(.r-list__form) .c-input--range-extreme-values {
  flex: 1 0 60px;

  .c-list--sublist .c-list__item-small-editable & {
    flex: auto;
  }
}

.c-input--range-extreme-values {
  width: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 12px;
  margin-bottom: 0;
  flex: 1;

  // TODO REMOVE: OLD - not REFACTORED:
  .c-list--sublist .c-list__item-small-editable &:first-child {
    justify-content: flex-end;
    text-align: right;
  }

  // REFACTORED: REMOVE .r-list__form
  .r-list__form &:first-child {
    justify-content: flex-start;
    text-align: left;
    padding-left: 0;
    margin-top: 2px;
    margin-bottom: 6px;
    z-index: 0;
    position: relative;
  }

  // REFACTORED: REMOVE .r-list__form
  .r-list__form &:first-child::after {
    content: '';
    background: repeating-linear-gradient(
      to bottom,
      #0004,
      #0004 50%,
      #0000 50%,
      #0000
    );
    width: 1px;
    height: 32px;
    position: absolute;
    bottom: -37.5px;
    left: 9.5px;
    background-size: auto 4px;
    z-index: 0;
  }

  // REFACTORED: REMOVE .r-list__form
  .r-list__form &:last-child {
    justify-content: flex-end;
    text-align: right;
    padding-right: 0;
    margin-top: 6px;
  }

  // REFACTORED: REMOVE .r-list__form
  .r-list__form &:last-child::after {
    content: '';
    background: repeating-linear-gradient(
      to bottom,
      #0004,
      #0004 50%,
      #0000 50%,
      #0000
    );
    width: 1px;
    height: 32px;
    position: absolute;
    top: -35.5px;
    right: 9.5px;
    background-size: auto 4px;
    z-index: 0;
  }
}

.c-list__item-with-slider,
.c-list__form-with-slider {
  @container range-wrapper (inline-size < 486px) {
    margin: 12px 0;

    .c-input__wrapper.o-flex {
      flex-wrap: wrap;
      > * {
        flex: 1 0 100%;
      }
    }
  }
}

// TODO REMOVE: OLD - not REFACTORED:
.c-list .c-list__form-with-slider:not(.r-list__form) {
  .c-input.c-input--range {
    min-width: 100px;
    flex: 1 0 40%;
  }
}

// Fieldsets Checkboxes (Filter)
fieldset.c-input-group {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  &:not(.o-flex--justify-start) {
    justify-content: space-between;
  }

  .c-input__group.c-input__group--checkbox {
    flex: 1 0 calc(calc(100% / 3) - 4px);
    align-items: flex-start;

    margin-bottom: $spacing-small !important;
    max-width: calc(calc(100% - #{$spacing-medium * 1.5}) / 3);
    padding: $spacing-tiny;

    &:focus-within {
      outline: 2px solid $color-primary;
    }

    &:first-child:last-child {
      flex: 1;
      max-width: none;
      margin: 0 !important;
    }
  }

  &.c-input-group--two-cols .c-input__group.c-input__group--checkbox {
    flex: 1 0 50%;
  }

  .c-input__text {
    font-family: $font-family-regular !important;
    font-weight: 400 !important;
  }

  .c-input--checkbox {
    & + .c-input__label--checkbox::before {
      height: calc($huge-icon / 2);
      background-size: 200%;
      width: calc($huge-icon / 2);
      flex: 0 0 calc($huge-icon / 2);
      top: 0;
      transform: none;
    }

    &:checked + .c-input__label--checkbox:after {
      height: calc($huge-icon / 2);
      width: calc($huge-icon / 2);
      background-size: 200%;
      top: 0;
      transform: none;
    }
  }

  .c-input__text-group {
    margin-left: $spacing-small;
  }
}

// Only REMOVE tags Select:
.c-input--dropdown-disabled {
  //   border: 1px solid red;
  //   margin: 0 !important;

  .c-input__label,
  .p-select__placeholder,
  .p-select__indicators {
    display: none;
  }

  .p-select-container .p-select__value-container {
    padding: 0;
    max-height: 0;
    // transition: max-height 0.2s ease-in-out 0.5s;
  }

  .p-select__value-container.p-select__value-container--is-multi.p-select__value-container--has-value {
    padding: 0;
    background: transparent;
  }

  .p-select-container
    .p-select__value-container.p-select__value-container--has-value {
    max-height: 100px;
  }

  .p-select__control {
    background: transparent;
    border: none;
    min-height: 0;
  }

  .p-select-container
    .p-select__multi-value:hover
    .p-select__multi-value__label,
  .p-select-container .p-select__multi-value:hover .c-icon use {
    color: $color-neutral-x-light;
    stroke: $color-neutral-x-light;
  }

  .p-select__multi-value:hover {
    background-color: $color-primary;
    box-shadow: 0 0 4px -1px $color-neutral-x-dark;
  }
}

/* ITEM WRAPPER: refactoring on Data Tab / Session > Sporter */
.r-input-test-item-wrapper {
  display: flex;
  justify-content: flex-end;
  font-family: $font-family-regular;
  margin-left: auto;
  margin-right: 24px;
  width: calc(100% - 24px);

  > .c-input__label {
    align-self: flex-end;
    margin-bottom: 10px;
    margin-right: 4px;
  }

  .c-input__group-select {
    width: 220px;
    margin: 8px 0 0 4px;
    display: flex;
    flex-direction: column;

    &:first-child:last-child {
      max-width: 100%;
      width: 348px;
      margin: 0;
    }

    .c-input__wrapper {
      height: 100%;
    }
  }

  .c-input__group {
    margin: 0 0 0 4px;
    max-width: 60px;
  }

  .c-input-slider {
    margin: 8px 0 0 4px;
    width: 100%;
    max-width: 348px;
  }

  .r-list__item-read-only & {
    margin-left: 0;
    margin-right: auto;
    width: 100%;
  }

  & + & {
    margin-top: 8px;
  }
}

// *** *** ***
//

.react-aria-Slider {
  display: grid;
  grid-template-areas:
    'label output'
    'track track';
  grid-template-columns: 1fr auto;
  //   max-width: 300px;
  color: var(--text-color);
  z-index: 1;
  margin-top: -1px;
  margin-bottom: -1px;

  .react-aria-Label {
    grid-area: label;
  }

  .react-aria-SliderOutput {
    grid-area: output;
  }

  .react-aria-SliderTrack {
    grid-area: track;
    position: relative;

    /* track line */
    &:before {
      content: '';
      display: block;
      position: absolute;
      //   background: var(--border-color);
      background-color: #e1e9f7;
      box-shadow: inset 0 0 6px -4px #34373e;
      border-radius: 24px;
    }
  }

  .react-aria-SliderThumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    // background: var(--highlight-background);
    // border: 2px solid var(--background-color);
    background-color: $color-primary;
    box-shadow: 0 2px 10px $color-primary-dark-25;
    //   border-radius: 10px;

    forced-color-adjust: none;

    &:hover {
      // outline: 22px solid $color-primary-10;
    }

    &[data-focused],
    &[data-hovered]:not([data-disabled]) {
      background: $color-primary;
      outline: 22px solid $color-primary-10;
    }

    &[data-focus-visible] {
      background: $color-primary;
      outline: 22px solid $color-primary-10;
    }

    &[data-dragging] {
      background: $color-primary-dark;
    }
  }
  .react-aria-SliderThumb[style*='left: 0%'] {
    margin-left: 10px;
  }
  .react-aria-SliderThumb[style*='left: 100%'] {
    margin-left: -10px;
  }

  &[data-orientation='horizontal'] {
    flex-direction: column;
    width: 100%;

    .react-aria-SliderTrack {
      height: 30px;
      width: 100%;

      &:before {
        height: 8px;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .react-aria-SliderThumb {
      top: 50%;
    }
  }

  .c-button--tiny {
    width: auto;
    position: absolute;
    top: -3px;
    right: 0;
    padding: 2px 8px;

    &:hover,
    &:focus {
      background-color: $color-neutral-light;
    }
  }

  &.c-input--slider-unset {
    .react-aria-SliderTrack {
      &:before {
        background-color: $color-neutral-x-light;
      }
    }

    .react-aria-SliderThumb {
      background: $color-neutral-x-light;
      box-shadow: 0 0px 10px -4px rgba(7, 22, 73, 0.25);

      &[data-hovered]:not([data-disabled]),
      &[data-focused] {
        background: $color-primary;
        outline: 22px solid $color-primary-10;
      }
    }
  }
}

.c-input-tags-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  container: input-wrapper-container / inline-size;

  legend.c-input__label {
    padding: 0 4px;
    background: $color-neutral-light;
    z-index: 1;
    margin-left: -4px;
  }

  fieldset.c-input-group {
    flex: 1 0 50%;
    padding: 0 8px 4px;
    gap: 4px;
  }

  fieldset.c-input-group::after {
    content: '';
    outline: 1px solid $color-neutral;
    position: absolute;
    outline-offset: -1px;
    top: -14px;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 4px;
  }

  fieldset.c-input-group .c-input__group.c-input__group--checkbox {
    z-index: 1;

    .c-input__text {
      padding-right: 0;
    }
  }

  > *:last-child {
    margin-bottom: 32px;
  }

  .c-input__group {
    flex: 0 0 calc(50% - 4px);
  }

  .p-select-container .p-select__menu {
    min-width: 120px;
    max-width: 100%;
  }

  @container input-wrapper-container (inline-size > 510px) {
    .c-input__group {
      flex: 0 0 calc(50% - 4px);
    }

    fieldset.c-input-group .c-input__group.c-input__group--checkbox {
      max-width: calc(calc(100% / 3) - 4px);
    }
  }

  @container input-wrapper-container (inline-size < 511px) {
    .c-input__group {
      flex: 1 1 100%;
    }

    fieldset.c-input-group .c-input__group.c-input__group--checkbox {
      max-width: none;
      width: calc(50% - 4px);
      margin-bottom: 4px !important;
      padding: 4px;
      flex: auto;
    }
  }
}

.c-tags__wrapper
  > .c-input__group.c-input__group-select.c-input--read-only.c-input--inline.c-input--dropdown-disabled:not(
    :first-child
  ) {
  margin-left: 8px;
  padding-left: 8px;
  border-left: 1px solid $color-neutral;
}
