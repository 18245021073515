/* ==========================================================================
   #BLOCK
   ========================================================================== */

/**
 * Stacked image-with-text object. A simple abstraction to cover a very commonly
 * occurring design pattern.
 */

.o-block {
  display: block;
  text-align: center;
}

.o-block__img {
  margin-bottom: $inuit-global-spacing-unit-medium;

  /* Size variants
       ====================================================================== */

  .o-block--flush > & {
    margin-bottom: 0;
  }

  .o-block--tiny > & {
    margin-bottom: $inuit-global-spacing-unit-tiny;
  }

  .o-block--small > & {
    margin-bottom: $inuit-global-spacing-unit-small;
  }

  .o-block--large > & {
    margin-bottom: $inuit-global-spacing-unit-large;
  }

  .o-block--huge > & {
    margin-bottom: $inuit-global-spacing-unit-huge;
  }
}

.o-block__body {
  display: block;
}

/* Alignment variants
   ========================================================================== */

.o-block--right {
  text-align: right;
}

.o-block--left {
  text-align: left;
}
