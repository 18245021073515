.c-form--annotations {
  .c-input-group {
    legend.c-input__label {
      margin-bottom: 4px;
      color: $color-neutral-dark;
    }

    .c-input__group--radiobutton {
      margin-bottom: 6px;
      display: flex;
      align-items: flex-start;
      margin-bottom: 8px;

      .c-input__label {
        font-family: $font-family-regular;
        text-transform: none;
        color: $color-neutral-dark;

        @include inuit-font-size(13px, 1.1);
      }

      .c-input:checked + .c-input__label {
        color: $color-primary-dark;
      }

      &:hover {
        .c-input__label {
          color: $color-primary-dark;
        }
      }

      &:hover + .c-tooltip--popper .c-icon,
      & + .c-tooltip--popper:hover .c-icon {
        background-color: $color-neutral-x-light;
        border-radius: 50%;

        use {
          fill: $color-primary-dark;
        }
      }
    }
  }

  .c-card--multiple-body .c-card__footer {
    padding: 0;
    margin-top: 0;
  }
}
