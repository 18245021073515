@import '../../_scss/settings';

// .c-loader__wrapper {
// display: flex;
// width: 100%;
// height: 100%;
// left: 0;
// top: 0;
// position: fixed;

// z-index: 100;

// align-items: center;
// text-align: center;
// justify-content: center;

// background: rgba(255,255,255,.7);
// }
.c-loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
}

.c-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  position: absolute;

  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  z-index: 100;
  background: rgba(255, 255, 255, 0.7);

  @keyframes rotate360 {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  svg {
    width: 40px;
    height: 40px;
    animation: rotate360 1500ms linear infinite;
  }

  .c-card__body--primary & {
    background-color: $transparent;
  }
}

.c-loader__balls {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.c-loader--bouncing {
  @keyframes bouncing-loader {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0.1;
      transform: translateY(-1rem);
    }
  }

  display: flex;
  justify-content: center;

  & > .c-loader--bouncing-ball {
    width: 1rem;
    height: 1rem;
    margin: 3rem 0.2rem;
    background: #8385aa;
    border-radius: 50%;
    animation: bouncing-loader 0.6s infinite alternate;
  }
  & > .c-loader--bouncing-ball:nth-child(2) {
    animation-delay: 0.2s;
  }
  & > .c-loader--bouncing-ball:nth-child(3) {
    animation-delay: 0.4s;
  }
}
