@import '../../_scss/settings';

.c-progress-bar {
  color: $color-primary;
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  width: 100%;
}

.c-progress-bar__label {
  margin-right: $spacing-small * 1.5;
  transition: opacity 0.2s ease-in-out;
}

.c-progress-bar__bar {
  background-color: $color-primary;
  border-radius: $tiny-radius;
  height: $spacing-medium;
  width: 2px;
}

.c-progress-bar--no-scale .c-progress-bar__bar {
  &:hover,
  &:active,
  &:focus {
    transform: none;
  }

  & + .c-progress-bar__label {
    color: $color-neutral-dark;
    opacity: 1;
  }
}

.c-progress-bar--secondary {
  margin-top: $spacing-medium;
  margin-bottom: $spacing-medium;
  position: relative;
  justify-content: flex-start;

  .c-progress-bar__label {
    display: none;
  }

  .c-progress-bar__bar {
    width: 32%;
    height: $spacing-tiny;
    transition: all 0.2s ease-in-out;
    z-index: 1;
    border-radius: $tiny-radius;
  }

  // white background
  &:after {
    content: '';
    width: 100%;
    top: 0;
    height: $spacing-tiny;
    border-radius: $tiny-radius;
    background-color: white;
    z-index: 0;
    position: absolute;
  }
}

.c-progress-bar--benchmark {
  justify-content: flex-start;
  align-items: center;
  color: $color-primary-dark;
  font-family: $font-family-bold;
  padding-right: $spacing-medium * 2.5;

  @include inuit-font-size(11px, 1.2);

  .c-progress-bar__bar {
    min-width: 1px;
    border-radius: 0;
    height: $spacing-tiny + $spacing-small;
    border-top-right-radius: $spacing-mini + $spacing-tiny;
    border-bottom-right-radius: $spacing-mini + $spacing-tiny;
  }

  .c-progress-bar__label {
    order: 1;
    position: absolute;
    right: -$spacing-tiny;
    text-align: right;
  }
}

.c-progress-bar--framed {
  border: 1px solid $color-neutral;
  border-top-right-radius: $spacing-small;
  border-bottom-right-radius: $spacing-small;
  background-color: $color-neutral-x-light;
  padding-right: 0;

  & + & {
    margin-top: $spacing-tiny;
  }
}

.c-progress-bar__group {
  & + & {
    margin-top: $spacing-medium;
  }
}

.c-progress-bar__group-full-width {
  max-width: 100%;
  width: 100%;
}

.c-progress-bar__group-full-width.c-progress-bar__group-no-side-no-benchmark {
  .c-progress-bar__label-block-light,
  .c-progress-bar__label-block:last-child {
    text-transform: none;

    white-space: normal;
    @include inuit-font-size(11px, 1.2);
  }

  .c-progress-bar__label-block-light {
    @include inuit-font-size(13px, 1.2);
  }
}

.c-progress-bar__group-value-at-end {
  padding: 0 24px 0 0;

  .c-progress-bar--benchmark {
    position: relative;

    .c-progress-bar__label {
      position: absolute;
      left: auto;
    }
  }
}
.c-progress-bar__label,
.c-progress-bar__label-block {
  transition: opacity 0.1s ease-in-out;
}
.c-progress-bar.c-progress-bar--benchmark.c-progress-bar--framed {
  transition:
    margin 0.1s ease-in-out,
    max-width 0.2s ease-in-out;
}

.c-progress-bar--value-fixed {
  .c-progress-bar__label {
    opacity: 1;
  }

  .c-progress-bar__label.c-progress-bar__label-hidden {
    opacity: 0 !important;
  }
}

.c-progress-bar--red {
  background-color: $color-progress-red;
}
.c-progress-bar--orange {
  background-color: $color-progress-orange;
}
.c-progress-bar--yellow {
  background-color: $color-progress-yellow;
}
.c-progress-bar--lime {
  background-color: $color-progress-lime;
}
.c-progress-bar--green {
  background-color: $color-progress-green;
}
.c-progress-bar--average {
  background-color: $color-progress-average;
}

.c-progress-bar__group-nodata {
  .c-progress-bar--red,
  .c-progress-bar--orange,
  .c-progress-bar--yellow,
  .c-progress-bar--lime,
  .c-progress-bar--green {
    opacity: 0.1;
    background-color: $color-primary-dark;
  }

  .c-progress-bar--average {
    opacity: 0.3;
  }

  &:hover,
  &:active,
  &:focus {
    .c-progress-bar--benchmark {
      .c-progress-bar__label {
        visibility: hidden;
      }
    }
  }
}

.c-progress-bar__group-nodata {
  .c-progress-bar--benchmark.c-progress-bar--unavailable {
    padding: $spacing-small;
    opacity: 0.3;

    .c-progress-bar__bar {
      border-radius: 6px;
      background-color: $color-primary-dark;
    }
  }

  .c-progress-bar__bar {
    width: 0;
  }
}

.c-progress-bar__group-clickable {
  cursor: pointer;
}

// Label
.c-progress-bar__label-side {
  color: $color-neutral-dark;
  font-family: $font-family-bold;
  text-transform: uppercase;
  white-space: nowrap;

  @include inuit-font-size(10px, 1.2);
}

.c-progress-bar__label-block {
  margin-bottom: $spacing-mini;
  font-family: $font-family-bold;
  //   text-transform: uppercase;
  @include inuit-font-size(11px);
}

.c-progress-bar__label-block-light {
  font-family: $font-family-regular;
  @include inuit-font-size(12px);
}

.c-progress-bar__group-inline {
  display: flex;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-top: 0;
  margin-bottom: 0;

  & + & {
    margin-top: 0;
    margin-bottom: 0;
  }

  &:nth-child(odd) {
    background-color: #f9f9f9;
  }
}
.c-progress-bar__label-inline {
  width: 25%;
  text-align: right;
  padding-right: 8px;
  margin-bottom: 0;
  font-family: $font-family-bold;
  text-transform: uppercase;
  @include inuit-font-size(11px);

  min-width: 65px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:nth-child(even) {
    color: tint($color-primary-dark, 10%);
  }

  @include mq($from: medium) {
    min-width: 70px;
  }
}

.c-progress-bar__average-wrapper {
  position: absolute;
  width: calc(75% - 18px);
  height: calc(100% - 24px);
  left: calc(25% - 14px);
  top: 4px;
}

.c-progress-bar__average-line {
  height: 100%;
  position: absolute;
  left: 0;
  background: rgba(255, 255, 255, 0.2);
  border-right: 1px dashed $color-neutral-dark;
}

.c-progress-bar__x-axis-ref-bar {
  position: absolute;
  width: 100%;
  border-top: 1px solid;

  width: calc(100% - 9px);
  left: 3px;
  top: -5px;
}

.c-progress-bar__x-axis-ref {
  position: absolute;
  width: 100%;

  width: calc(100% - 16px);
  left: 3px;

  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 5px;

  span {
    width: auto;
    text-align: center;
    position: relative;

    &::before {
      content: '';
      width: 1px;
      background: $color-neutral-xx-dark;
      height: 10px;
      position: absolute;
      top: -15px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.c-progress-bar__group-x-axis {
  margin-top: 16px;
  width: 75%;
  position: absolute;
  right: 0;
}

.c-progress-bar__wrapper {
  width: 75%;
  margin-right: 8px;
}

// Adding this to print:
.c-progress-bar__wrapper {
  width: 100%;
  flex: 1;

  .c-progress-bar__group-nodata & {
    opacity: 0;
  }
}

.c-progress-bar__group-nodata {
  min-height: 36px;
}

// Hylyght
button.c-progress-bar--block-value {
  border: 0;
  background: 0;
  display: inline-flex;
  padding: 0;
  margin: 0;
  outline: 0;
}

.c-progress-bar--block-value {
  height: 100%;
  max-height: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .c-progress-bar__block {
    border-radius: $spacing-mini;
    width: $spacing-medium;
    height: $spacing-medium;
  }

  .c-progress-bar__value {
    padding: $spacing-tiny $spacing-tiny $spacing-tiny $spacing-small;
    font-family: $font-family-bold;
    color: $color-neutral-dark;

    @include inuit-font-size(11px);
  }

  &.c-progress-bar--hidden-value {
    transition: transform 0.2s ease-in-out;

    .c-progress-bar__block {
      margin: auto;
    }

    .c-progress-bar__value {
      opacity: 0;
      position: absolute;
      color: $color-neutral-x-light;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      transition: opacity 0.2s ease-in-out;

      text-shadow: 0 0 1px $color-neutral-dark;
    }

    &:hover,
    &:focus {
      transform: scale(1.2);

      .c-progress-bar__value {
        opacity: 1;
      }
    }
  }
}

.c-progress-bar--growth {
  align-items: center;
  justify-content: space-between;

  .c-progress-bar__label {
    margin-right: 0;
    margin-left: $spacing-small;
    text-align: left;
    min-width: 50px;
  }

  .c-progress-bar__bar {
    height: 12px;
  }
}

.c-progress-bar__block-0 {
  &,
  .c-progress-bar--growth .c-progress-bar__bar {
    background-color: $color-progress-red;
  }
}

.c-progress-bar__block-1 {
  &,
  .c-progress-bar--growth .c-progress-bar__bar {
    background-color: $color-progress-orange;
  }
}

.c-progress-bar__block-2 {
  &,
  .c-progress-bar--growth .c-progress-bar__bar {
    background-color: $color-progress-yellow;
  }
}

.c-progress-bar__block-3 {
  &,
  .c-progress-bar--growth .c-progress-bar__bar {
    background-color: $color-progress-lime;
  }
}

.c-progress-bar__block-4 {
  &,
  .c-progress-bar--growth .c-progress-bar__bar {
    background-color: $color-progress-green;
  }
}

/* Legend */

.c-legend,
.c-progress-bar--scale-wrapper {
  height: 48px;
  display: inline-flex;
  align-items: center;
}

.c-legend__wrapper,
.c-progress-bar--scale {
  height: 100%;
  max-height: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.c-legend__bar {
  border-radius: 0;
  width: 50px;
  transition:
    transform 0.2s ease-in-out,
    z-index 0.2s ease-in-out;
  height: 100%;
  text-align: center;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &:hover,
  &:active,
  &:focus {
    transform: scale(1.5);
    z-index: 2;

    .c-legend__label {
      opacity: 1;
    }
  }
}

.c-legend__bar.u-color--red,
.c-legend__bar:first-child {
  background-color: $color-progress-red;
}

.c-legend__bar.u-color--orange,
.c-legend__bar:nth-child(2) {
  background-color: $color-progress-orange;
}

.c-legend__bar.u-color--yellow-orange,
.c-legend__bar:nth-child(3) {
  background-color: $color-progress-orange-light;
}

.c-legend__bar.u-color--yellow,
.c-legend__bar:nth-child(4) {
  background-color: $color-progress-yellow;
}

.c-legend__bar.u-color--lime,
.c-legend__bar:nth-child(5) {
  background-color: $color-progress-lime;
}

.c-legend__bar.u-color--green,
.c-legend__bar:nth-child(6) {
  background-color: $color-progress-green;
}

.c-legend__label {
  display: inline-block;
  color: $color-neutral-x-light;
  font-family: $font-family-bold;
  opacity: 0;
  margin-right: 0;
  padding: $spacing-tiny $spacing-small;
  transition: opacity 0.1s ease-in-out;
  white-space: nowrap;

  text-shadow: 0 0 1px $color-neutral-dark;
  @include inuit-font-size(8px, 1);
}
