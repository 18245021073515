@use 'sass:math';

$inuit-global-baseline: 4px;
$inuit-global-font-size: 13px;

@import '../../_scss/00-settings/settings.core';
@import '../../_scss/00-settings/settings.colors';

///* ========================================================================
//   #GLOBAL
//   ======================================================================== */

// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

// $fontface: 'Lato', sans-serif;
$font-family-regular: 'lato', sans-serif;
$font-family-light: 'latolight', sans-serif;
$font-family-bold: 'latoblack', sans-serif;

$font-default-details-color: $color-primary-dark;
$font-status-label-color: $color-neutral-dark;
$font-hint-color: rgba($color-neutral-dark, 0.5);
$font-error-color: $color-error;

$font-default-size: 13px;
$font-small-size: 10px;

// Alias
$spacing-mini: math.div($inuit-global-spacing-unit-tiny, 2); // 2
$spacing-tiny: $inuit-global-spacing-unit-tiny; // 4
$spacing-small: $inuit-global-spacing-unit-small; // 8
$spacing-medium: $inuit-global-spacing-unit-medium; // 16
$spacing-large: $inuit-global-spacing-unit-large; // 32
$spacing-huge: $inuit-global-spacing-unit-huge; // 64

// Standardise some UI treatments.
$huge-radius: $spacing-huge; //64
$large-radius: $spacing-large; // 32
$medium-radius: $spacing-medium; // 16
$small-medium-radius: $spacing-tiny + $spacing-small; // 12
$small-radius: $spacing-small; // 8
$tiny-radius: $spacing-tiny; // 4
$circular-radius: 50%;

// Icon Sizes:
$x-huge-icon: 96px;
$huge-icon: 40px;
$large-icon: 32px;
$medium-icon: 24px;
$small-medium-icon: 20px;
$small-icon: 16px;

// $text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
$list-box-shadow: 0 0 2px 0 rgba($color-primary-dark, 0.1);
$menu-box-shadow: 0 2px 8px 0 rgba(7, 22, 73, 0.5);
$dragging-shadow: 0 0 10px -7px $color-neutral-x-dark;
$body-modal-shadow-top: inset 0 5px 6px -10px $color-neutral-x-dark;
$shadow-top: 0 -6px 6px -10px $color-neutral-x-dark;
$shadow-bottom: 0 6px 6px -10px $color-neutral-x-dark;

$global-border-width-tiny: 1px;
// $global-border-width-small: 4px;
// $global-border-width-medium: 10px;
// $global-border-width-large: 15px;

$global-transition: all 300ms ease-in-out !default;
$global-transition-fast: all 150ms ease-in-out !default;

// Breakpoints (https://medium.freecodecamp.com/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862#.necmuodfd)
$mq-breakpoints: (
  tiny: 470px,
  small: 600px,
  medium: 900px,
  large: 1200px,
  xlarge: 1400px,
  huge: 1800px
);

// $mq-show-breakpoints: (tiny, small, medium, large, xlarge, huge);

///* ========================================================================
//   _objects.ratio:
//   ========================================================================
//   $inuit-ratios: (
//     "2\\:1"         : (2:1),
//     "4-by-3"        : (4:3),
//     "full-hd"       : (16:9),
//     "card-image"    : (2:3),
//     "golden-ratio"  : (1.618:1) -> non-integers are okay
//   ) !default;
// class="u-2-by-1"
// http://alistapart.com/article/creating-intrinsic-ratios-for-video
// 1. Default is a 1:1 ratio (i.e. a perfect square).

$inuit-ratios: (
  '2-by-1': (
    2: 1
  ),
  '4-by-3': (
    4: 3
  ),
  '16-by-9': (
    16: 9
  ),
  'rating': (
    114: 139
  ),
  '145': (
    110: 160
  ),
  '164': (
    110: 180
  )
) !default;

///* ========================================================================
//   _objects.responsive-wrapper:
//   ========================================================================

$inuit-wrapper-width-tiny: 400px;
$inuit-wrapper-width-small: 600px;
$inuit-wrapper-width-medium: 900px;
$inuit-wrapper-width-large: 1200px;
$inuit-wrapper-width-huge: 1800px;
$inuit-wrapper-breakpoint: large;

///* ========================================================================
//   _utilities.responsive-spacings:
//   ========================================================================

// /**
// * This would bring us the following classes:
//  *
//  *   .u-margin-small-at-small {}
//  *   .u-margin-small-at-medium {}
//  *   .u-margin-small-at-large {}
//  *   .u-margin-small-at-huge {}
//  *   .u-margin-bottom-small-at-small {}
//  *   .u-margin-bottom-small-at-medium {}
//  *   .u-margin-bottom-small-at-large {}
//  *   .u-margin-bottom-small-at-huge {}
//  *
//  *   Not active by default!//
//  */

///* ========================================================================
//   _utilities.spacings:
//   ========================================================================

// .u-margin-top-medium, .u-padding-left-large, .u-padding-medium, .u-padding-right-none, .u-padding-horizontal-medium, enz...
$inuit-spacing-sizes: (
  '-tiny': $inuit-global-spacing-unit-tiny,
  '-small': $inuit-global-spacing-unit-small,
  '-medium': $inuit-global-spacing-unit-medium,
  '-large': $inuit-global-spacing-unit-large,
  '-huge': $inuit-global-spacing-unit-huge,
  '-none': 0,
  '-auto': auto
) !default;

///* ========================================================================
//   _utilities.widths:
//   ========================================================================

$inuit-widths-delimiter: '-of-'; // use: u-1-of-2, u-push-2-of-5
$inuit-widths-breakpoint-separator: '-at-'; // use: u-1-of-1-at-small, u-1-of-1-at-large

// $inuit-fractions; .u-1-of-2, .u-2-of-5, enz... > 1 2 3 4 5 !default;

///* ========================================================================
//   offset utilities:
//   ========================================================================

// Be aware that enabling offsets produces a large chunk of classes which might
// bloat your CSS, depending of the amount of breakpoints you defined.
// Only set this to `true` if you are absolutely sure about what you are doing.
// Use: .u-push-1-of-3, .u-pull-2-of-4, enz... > if is true

$inuit-offsets: false;
