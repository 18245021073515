@import '../../_scss/settings';

// .slick-list {
//   max-width: 100%;
// }

// .slick-slide {
//   margin: 4px;
// }
.c-list--sublist-with-carousel {
  .slick-prev,
  .slick-next {
    height: $spacing-large * 1.5;
    width: $spacing-large * 1.5;
  }

  .slick-prev::before,
  .slick-next::before {
    color: $color-primary;

    @include inuit-font-size(24px);
  }

  .slick-prev {
    left: -42px;
  }

  .slick-next {
    right: -42px;
  }
}

.c-carousel {
  width: 100%;
}

.c-mini-table {
  //   outline: 1px solid gray;
  text-align: center;
  box-shadow: $dragging-shadow;
  margin: 1px 6px 1px 2px;
  background-color: $color-neutral-x-light;
}

.c-mini-table__body {
  padding: 6px 0 4px;
}

.c-mini-table__head {
  padding: 4px 0;
  text-transform: uppercase;
  font-family: $font-family-light;
  background-color: $color-neutral-light;
  color: $color-primary-dark;

  @include inuit-font-size(10px, 1.2);

  .c-mini-table--highlight & {
    background-color: $color-primary-light;
  }
}

.c-mini-table--highlight {
  outline: 1px solid $color-neutral;
}
