.c-h1 {
  display: block;
  font-weight: 600;
  font-family: $font-family-bold;
  letter-spacing: -2px;
  font-size: 70px;
  line-height: 1em !important;
  margin: 0;
  // color: $white;
  // width: 100%;
}

.c-h2 {
  display: block;
  font-family: $font-family-bold;
  font-size: 40px;
  line-height: 1.4;
  margin: 0;
  color: $color-primary-dark;
  // text-decoration: underline;
}

.c-h3 {
  display: block;
  font-family: $font-family-regular;
  color: $color-primary-dark;
  font-size: 24px;
  font-weight: normal;
}
