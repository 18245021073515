@import '../../_scss/settings';

.c-list--permissions {
  //   height: 100%;

  .c-panel & {
    overflow: visible;
    // height: calc(100% - 76px);
  }
}

.c-list__title {
  // + Paragraph styles

  /* padding: 10px; */
  display: inline-block;
  background: $color-primary-dark;
  color: $color-neutral-light;

  font-family: $font-family-regular;
  text-align: center;
  text-transform: uppercase;

  margin: 0 10px 0 0;
  padding: $spacing-small $spacing-small + $spacing-tiny;
  line-height: 1;
}

.c-list__header-button {
  padding: 0;
  line-height: 0;
  width: 30px;
  height: 30px;
}

// BODY
.c-list__body {
  display: block;
  padding-left: 0;
  // position: relative;
  // width: 100%;

  .u-padding-vertical-small & {
    margin-bottom: 0;
  }

  > .r-list-item--wrapping {
    padding-bottom: 8px;
    width: 100%;

    &:last-child {
      padding-bottom: 0;
    }
  }
}

// Using:

.c-list {
  background-color: $transparent;
  // max-width: 530px;
  min-width: 50%;
  // padding: $spacing-medium;
  padding: 0;
  z-index: 0;

  overflow-y: auto;
  overflow-x: hidden;

  position: relative;

  .c-panel > &:not(.c-list--virtualized) {
    // padding: 0;
    // overflow: visible;
    padding: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 0 -16px;
    padding: 16px 16px 0;
  }

  .c-button__group + & {
    margin-top: $spacing-medium;
  }
}

.c-list__item-small-editing-area {
  display: flex;
  align-items: center;
}

.c-list--draggable {
  // overflow-y: visible;
  padding: 16px 0;
  min-height: 100%;
  overflow: visible;

  .c-list__body {
    padding-left: 0;
    margin: 0;
    // flex: 1 0 auto;
    flex: 0;
  }

  .c-list__item {
    max-height: 100%;
    height: auto;
  }

  .c-list__item-is-dragging {
    background-color: $color-neutral-x-light;
  }

  .c-list__item:hover,
  .c-list__item:active,
  .c-list__item:focus,
  .c-list__item:focus-within,
  .c-list__item.c-list__item-active,
  .c-list__item-is-dragging .c-list__item-label,
  .c-list__item-is-dragging .c-list__item-icon {
    background-color: $color-primary-10;
  }

  .c-list__item-icon {
    // :not(.c-list--exercises-checkboxes)
    align-items: flex-start;
    justify-content: flex-end;
    background-color: $transparent;
    width: $spacing-large;
    padding: $spacing-medium 0;

    .c-icon {
      width: 20px;
      height: 20px;
    }

    use {
      fill: $color-neutral;
    }
  }

  .c-list__item-label {
    width: 100%;
    padding: $spacing-medium;
  }

  .c-list__item-icon + .c-list__item-label {
    padding: $spacing-medium $spacing-medium $spacing-medium $spacing-small;
  }

  .c-list__item-text {
    min-width: 0;
    align-items: flex-start;

    &:first-child {
      white-space: normal;
      min-width: 40%;
      width: auto;
      flex: 1 1 auto;
      max-width: 45%;
    }
  }

  .c-list__item-icon + .c-list__item-label .c-list__item-text {
    padding-top: 2px;
  }

  .c-list__item-small-editing-area {
    margin-bottom: $spacing-small;
    padding-left: $spacing-small;

    width: auto;
    flex: 1 1 auto;
    max-width: 55%;
  }

  .c-list__item-text + .c-list__item-text:last-child {
    max-width: 60%;
  }

  .c-list__item-text.c-list__item-secondary-text {
    white-space: normal;
    overflow: visible;
    align-items: flex-start;
  }

  .c-button__group:last-child {
    margin-left: auto;

    .c-tooltip + .c-button {
      order: -1;
      margin-left: 0;
      margin-right: $spacing-small;
    }
  }

  .c-input__group {
    margin-bottom: 0;
  }

  @include mq($until: small) {
    .c-list__item {
      position: relative;
    }

    .c-list__item-label {
      flex-direction: column;
    }

    .c-list__item-text:first-child {
      min-width: 0;
      width: calc(100% - 120px);
      flex: 1 1 calc(100% - 120px);

      + * {
        margin-top: $spacing-medium;
      }
    }

    .c-input__group.c-input--read-only {
      max-width: 100%;
      margin-left: 0;
    }

    &,
    &.c-list--draggable-disabled {
      .c-input {
        text-align: left;
      }
    }

    .c-list__item-small-editing-area {
      width: 100%;
      flex: 0;
    }

    .c-button__group:last-child {
      position: absolute;
      right: $spacing-medium;
      top: $spacing-small * 1.5;
    }

    &.c-list--draggable-disabled .c-button__group:last-child {
      top: $spacing-medium;
    }
  }
}

.c-list--draggable-disabled {
  .c-list__item-small-editing-area {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    p {
      align-self: flex-start;
      font-family: $font-family-regular;
      color: $color-primary-dark;
      text-align: right;
      width: 100%;
      margin: 0;

      width: calc(100% - 40px);
      transition: all 0.2s ease-in-out;
      padding-right: 0;
      opacity: 1;
    }

    p + .c-button__group:last-child,
    .c-button__group:last-child {
      max-height: none;
      position: absolute;
      right: 0;
      top: auto;
      z-index: 1;
    }
  }

  .o-flex--align-start.c-list__item-small-editing-area {
    align-items: flex-start;
  }

  .c-list__item {
    &:hover,
    &:focus-within {
      .c-list__item-small-editing-area {
        p:not(:last-child) {
          opacity: 0;
        }
      }
    }
  }

  .c-input__group.c-input--read-only {
    max-width: 360px;
    margin-left: auto;
  }

  .c-input {
    text-align: right;
  }

  .c-button__group,
  .c-button__group:last-child {
    margin-left: 0;
    opacity: 0;
    max-width: 0;
    max-height: $spacing-medium;
    flex-direction: row-reverse;
    transition: all 0.2s ease-in-out;

    .c-button--template-icon {
      padding: 0;
      margin-right: $spacing-small;
      order: 10;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .c-button:nth-child(2) {
      order: 1;
    }

    .c-button:last-child {
      order: 0;
    }
  }

  .c-list__item:hover,
  .c-list__item:active,
  .c-list__item:focus,
  .c-list__item:focus-within,
  .c-list__item.c-list__item-active {
    .c-button__group {
      max-width: 100%;
      opacity: 1;
      padding-left: 16px;
    }
  }

  @include mq($until: medium) {
    .c-list__item-label {
      flex-direction: row;
    }

    .c-list__item-label .c-list__item-text.c-list__item-main-text {
      flex: 1 0 45%;
    }

    .c-list__item-label .c-list__item-small-editing-area {
      padding: 0;
      margin: 0;
      flex: 1 0 55%;
    }
  }
}

.c-list--dnd {
  overflow-y: visible;

  .c-list__item {
    transition: padding-left 0.2s ease-in-out;
    position: relative;
    cursor: grab;

    &:active {
      user-select: none;
      cursor: grabbing;
      box-shadow: $dragging-shadow;
    }

    &:not(.c-list__item-not-draggable)::before {
      content: '';
      background-image: url('~/src/assets/images/svgstore/three-dots-vertical.svg');
      background-repeat: no-repeat;
      background-size: 16px 16px;
      background-position: center;
      //   background-color: $color-neutral-x-light;
      //border-left: 1px solid $color-neutral-light;
      //border-top: 1px solid $color-neutral-light;
      //border-bottom: 1px solid $color-neutral-light;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      width: 34px;
      position: absolute;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: opacity 0.5s ease-in-out;
    }

    &:not(.c-list__item-not-draggable) {
      // &:hover,
      // &:focus,
      // &:active,
      // &.c-list__item-is-dragging {
      // }
      position: relative;
      padding-left: 32px;
    }
  }

  .c-list__item:hover,
  .c-list__item:active,
  .c-list__item:focus,
  .c-list__item:focus-within,
  .c-list__item.c-list__item-active,
  .c-list__item-is-dragging .c-list__item-label {
    background-color: $color-primary-10;
  }

  .c-list__item.c-list__item-active {
    position: relative;
    z-index: 1;

    // Making the dragging object not-transparent
    ::after {
      content: '';
      position: absolute;
      background-color: none;
      background: linear-gradient(to left, $color-primary-10, $color-primary-10),
        linear-gradient(to left, white, white);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 4px;
      z-index: -1;
    }
  }

  .c-list__item-small {
    &::before {
      width: 24px;
      //z-index: -1;
    }

    &:hover,
    &:focus,
    &:active,
    &.c-list__item-is-dragging {
      position: relative;
      padding-left: 24px;
    }
  }

  &.c-list--cue {
    // .c-list__item::before {
    // }

    .c-list__item-small {
      &::before {
        position: static;
        background-color: transparent;
        height: 24px;
      }
      &:hover,
      &:focus,
      &:active,
      &.c-list__item-is-dragging {
        padding-left: 24px;
        padding-left: 4px;
      }
    }
  }
}

.c-list--dnd-preview {
  overflow: visible;
  padding: 0 $spacing-medium;
  margin-bottom: $spacing-medium;

  .c-list__item {
    position: relative;
    z-index: auto;

    .c-list__item-small-editing-area {
      align-self: center;
      min-height: $spacing-medium * 1.25;
      margin-left: auto;
      display: flex;
      align-items: center;

      .c-list__item-secondary-text {
        transition: opacity 0.2s ease-in-out;
      }

      .c-button__group {
        overflow: hidden;
        width: 0;
        height: 0;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }

      .c-tooltip {
        position: absolute;
        right: $spacing-small * 1.5;
        // top: 50%;
        // transform: translateY(-50%);
        z-index: 6;
        height: 100%;
        display: flex;
        top: 0;
        max-height: 56px;

        &:focus-within {
          z-index: 10;
        }
      }
    }

    &:hover {
      z-index: auto;

      .c-list__item-small-editing-area {
        .c-list__item-secondary-text:not(:first-child:last-child) {
          opacity: 0;
          width: 0;
          height: 0;
          overflow: hidden;

          position: absolute;
          left: 0;
          top: 0;
        }
      }

      .c-button__group {
        width: auto;
        height: auto;
        opacity: 1;
        max-height: $spacing-large;
        margin-top: -1px;
      }
    }

    &:last-child {
      margin-bottom: $spacing-small;
    }

    &:focus,
    &:focus-within {
      z-index: 20;
      background-color: $color-primary-10;
    }

    @include mq($from: medium) {
      max-height: none;
    }
  }

  .c-list__item.c-list__item-not-draggable {
    .c-list__item-label {
      position: relative;
    }

    &:hover {
      .c-list__item-text.c-list__item-main-text.c-list__item-main-text--note-preview {
        white-space: normal;
      }
    }
  }

  .c-list__item-editing-area {
    display: flex;
    // align-items: center;
    justify-content: center;
    width: 30%;
    min-width: 208px;

    flex-direction: column;

    .c-input__group-benchmarks {
      margin: 0;

      .c-tooltip {
        margin-bottom: -$spacing-mini;
        margin-right: $spacing-tiny;
      }

      .c-tooltip.c-tooltip--label {
        margin-bottom: $spacing-tiny;
        align-self: flex-end;
      }

      .c-input__group {
        margin-right: 0;
      }

      .c-input__group-label,
      .c-tooltip__trigger span {
        margin-bottom: $spacing-mini * 1.5;
        color: $color-neutral-dark;
        opacity: 1;
        font-family: $font-family-bold;
        align-self: flex-end;
        text-transform: uppercase;

        @include inuit-font-size(10px, 2.75);
      }
    }

    .c-input__group {
      margin-bottom: 0;
    }

    .c-input__group + .c-input__group {
      margin-left: $spacing-medium;
    }

    .c-input:not(.c-input--range) {
      padding: $spacing-mini $spacing-small;
      min-height: 24px;
    }

    .c-input__label {
      margin-bottom: 0;
    }

    .c-input__label + .c-input__wrapper {
      margin-top: $spacing-mini;
    }
  }

  .c-list__item-label {
    padding-top: $spacing-small;
    padding-bottom: $spacing-small;
  }

  .c-list__item-text {
    white-space: normal;
    overflow: visible;
    max-width: 100%;

    margin-top: $spacing-mini;
    margin-bottom: $spacing-mini;

    &:first-child {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      opacity: 1;

      .c-list__item-result-empty {
        align-self: stretch;
      }
    }

    .c-list__item-result-empty .c-progress-bar__label-side {
      display: none;
    }
  }

  .c-list__item-read-only {
    .c-list__item-editing-area {
      .c-input--read-only .c-input {
        padding: 0;
        text-align: center;
      }

      .c-input--read-only .c-input__label {
        text-align: center;
      }

      .c-input__group-benchmarks .c-input__group {
        margin-top: $spacing-mini * 1.5;
      }
    }

    .c-tooltip {
      visibility: hidden;
    }
  }

  @include mq($until: large) {
    min-width: 450px;

    .c-list__item-editing-area {
      width: 140px;
    }

    .c-list__item-text:first-child {
      flex: 1 1 auto;
    }
  }
}

.c-list--dnd-preview.c-list--injuries {
  .c-list__item-editing-area {
    margin-left: auto;

    .c-input {
      height: 100%;
    }

    .c-input__group--checkbox:not(.c-input--checkbox--in-list)
      .c-input--checkbox
      + .c-input__label--checkbox::before {
      flex: 0 0 auto;
      position: relative;
      top: 10px;
    }

    .c-input__group--checkbox:not(.c-input--checkbox--in-list) {
      height: 40px;
      max-width: 60px;
      margin: auto !important;
    }
    .c-input__label--checkbox {
      overflow: hidden;
    }
  }
}

.c-list__item-editing-area {
  .c-input__group-benchmarks {
    align-items: stretch;
  }

  .c-input--checkbox {
    padding: 0;
    min-height: 0;
    min-width: 100%;
  }

  .c-input__label--checkbox {
    flex-direction: column;
    width: 100%;
    align-items: center;

    .c-input__text-group {
      order: -1;
      margin: 2px 0 0 !important;
      justify-content: center;
    }

    .c-input__text {
      padding-right: 0;

      font-family: $font-family-bold;
      color: $color-neutral-dark;
      text-transform: uppercase;

      @include inuit-font-size(10px, 1.2);
    }
  }

  .c-input__group-benchmarks.c-input__group-center-labels {
    .c-input__label--checkbox {
      .c-input__text {
        padding-right: 0;

        font-family: $font-family-bold;
        color: $color-neutral-dark;
        text-transform: uppercase;

        @include inuit-font-size(10px, 1.2);
      }
    }
  }

  .c-input__group--checkbox:not(.c-input--checkbox--in-list) {
    min-width: 40px;

    .c-input--checkbox {
      & + .c-input__label--checkbox:before {
        width: 40px;
        flex: 0 0 calc($huge-icon / 2);
        margin-top: $spacing-mini; // 2px
      }

      &:checked + .c-input__label--checkbox:after {
        width: 40px;
        top: 6px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

// HEADER
.c-list__header {
  height: $spacing-medium + $spacing-small;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: $spacing-small;

  .c-list__body + & {
    margin-top: $spacing-large;
  }
}

.c-list__header-labels {
  flex: 1 0 auto;
  padding: 0 ($spacing-medium + $spacing-small);

  justify-content: space-between;
  display: flex;
  height: 100%;

  .c-list__col-middle {
    flex: 1 0 auto;
  }

  .c-list__col:last-child {
    margin-right: 0;
    min-width: 25%;
    justify-content: flex-end;
  }
}

// prop: oneTitle
.c-list__header-one-title {
  height: auto;
  .c-list__header-labels {
    padding: 0;

    .c-list__col-title {
      width: 100%;
      min-width: auto;
      justify-content: flex-start;
    }
  }
}

// prop: noTitleStatus
.c-list__header-item-status-hidden {
  height: auto;

  .c-list__header-labels {
    padding-left: 72px;
  }
}

// prop: sticky
.c-list__header-sticky {
  position: sticky;
  top: 0;
  z-index: 101;
  background-color: $color-neutral-light;
  margin-left: -$spacing-medium;
  margin-right: -$spacing-medium;
  padding: $spacing-small $spacing-medium;

  .c-list__header-labels {
    padding-left: 0;
    padding-right: 0;
  }
}

// HEADER'S Buttons
.c-list__col-title {
  font-family: $font-family-bold;
  text-transform: uppercase;
  color: $color-neutral-dark;

  display: inline-flex;
  align-items: center;

  padding: 0;
  border: 0;

  @include inuit-font-size(10px, 1);

  .c-icon {
    width: $small-icon;
    height: $small-icon;
    transition: transform 0.3s ease-in-out;
  }

  &.c-button {
    outline-offset: 0;
    border-radius: $spacing-small;
  }
}

.c-list__col-asc {
  .c-icon {
    transform: rotateX(180deg);
  }
}

.c-list__col-by-progress {
  justify-content: center;
  width: ($spacing-medium + $spacing-large);
  height: 100%;
}

.c-list__col--status {
  width: 48px;
  min-width: auto;
  justify-content: center;
}

.c-list__col--main-label {
  min-width: calc(39% - #{$spacing-small * 1.5});
  flex: 1 1;
  padding-left: $spacing-medium * 1.5;
}

.c-list__col--last-label {
  flex: 0 0 80px;
  min-width: 80px;
  padding-right: $spacing-medium * 1.5;
}

// List Item
.c-list__item {
  background: $color-neutral-x-light;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0;
  border: 0;

  width: 100%;
  border-radius: $tiny-radius;
  box-shadow: $list-box-shadow;

  margin-bottom: $spacing-small;
  min-height: 48px;

  cursor: default;
  position: relative;

  transition:
    opacity 0.2s ease-in-out,
    transform 0.2s ease-in-out;

  &:hover,
  &:active,
  &:focus,
  &:focus-within,
  &.c-list__item-active {
    background-color: rgb(225, 233, 247) !important;
    opacity: 1;
  }

  &:not(.c-list__item-feedback):not(.c-list__item-active) {
    opacity: 0.85;
  }

  &:not(.c-list__item-feedback):not(.c-list__item-active):hover {
    opacity: 1;
    transform: scale(1.005);
  }
}

.c-list--sortable-two-columns-with-button {
  .c-list__col-title {
    &:first-child {
      flex: 0 0 48px;
      min-width: 0;
    }

    &:nth-child(2) {
      flex: 1 0 calc(100% - 96px - 48px - 12px);
      padding-left: 12px;
      width: calc(100% - 96px - 48px - 12px);
      margin-right: 12px;
    }

    &:nth-child(3) {
      min-width: 0;
      flex: 1 0 96px;
      margin-left: 0;
    }
  }

  .c-list__item-label {
    padding-left: 12px;
    padding-right: 12px;
  }

  .c-list__item-main-text--note-preview:not(:first-child:last-child),
  &.c-list--virtualized
    .c-list__item-label
    .c-list__item-main-text--note-preview:not(:first-child:last-child),
  &.c-list--virtualized
    .c-list__item:hover
    .c-list__item-main-text--note-preview:not(:first-child:last-child) {
    max-width: calc(100% - 80px);
  }

  .c-list__item-main-text--note-preview {
    .c-list__item-title.c-list__item-title-ellipsed {
      color: inherit;
      text-decoration: none;
    }
  }

  &.c-list--virtualized {
    &:not(.c-list--dnd) {
      .c-list__item:hover,
      .c-list__item:active,
      .c-list__item:focus,
      .c-list__item:focus-within {
        .c-list__item-main-text:not(:first-child:last-child) {
          span {
            white-space: nowrap;
          }
        }
      }
    }
  }

  .c-list__item-wrapping-note-preview {
    width: 96px;
    margin-left: 0;

    p {
      padding-right: 12px;
    }

    .c-button__group {
      right: 0;
    }
  }
}

.c-list__item-clickable {
  cursor: pointer;
  user-select: none;

  .c-list__item-icon:not(.c-list__item-icon-click-propagated) {
    cursor: default;
  }

  &.c-list__item-active {
    .c-list__item-small-editing-area,
    .c-list__item-double-icon-info {
      display: none;
    }
  }
}

.c-list__item-label-results {
  flex-direction: row !important;

  > .c-list__item-small-editing-area {
    min-width: 48px;

    &:not(:first-child) {
      margin-left: $spacing-medium * 1.5 !important;
    }
  }

  &.c-list__item-label-results-variation {
    width: 100%;
    flex-direction: column !important;
    margin-left: auto;
    margin-right: 0;

    .c-list__item-small-edited-area:not(.c-list__item-small-edited-area-select)
      .c-list__item-result {
      min-width: 64px;
      min-height: 26px;
      height: 100%;
      margin-left: 12px;
    }
  }
}

.c-list__item-read-only
  .c-list__item-text.c-list__item-label-results.c-list__item-label-results-variation.c-list__item-main-text {
  width: 100%;
}

// List Item Editable (Program > Exercises)
.c-list__item.c-list__item-editable {
  position: relative;
  box-shadow: none;
  min-height: 48px;

  &,
  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    .c-list__item-label + .c-button--tiny,
    .c-list__item-label + .c-button__group {
      opacity: 1;
      max-width: 100%;
      padding: 0 12px;
      z-index: 2;
    }
  }

  .c-list__item-label {
    border-radius: 4px;
    z-index: 1;
    width: 100%;
    padding: 16px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    & + .c-button--tiny,
    & + .c-button__group {
      position: relative;
      right: 0;
      top: 0;
      bottom: 0;
      padding: 0;
      transition: all 0.2s linear;
      border-radius: 0;
      z-index: 0;
      max-width: 100%;
      opacity: 0;
      margin-left: auto;
    }
  }

  .c-list__item-text:first-child {
    flex: 1 0 40%;
    width: 40%;
    min-width: auto;
    align-self: flex-start;

    @include mq($from: tiny) {
      min-width: 0;
    }

    @include mq($from: medium) {
      min-width: 0;
    }
  }

  .c-list__item-text ~ .c-list__item-text:last-child {
    min-width: 0;
    width: 60%;
    flex: 1 0 60%;
    max-width: none;
    white-space: normal;
  }

  .c-input__group {
    margin-bottom: 0;
  }

  .c-input--read-only .c-input {
    text-align: right;
  }

  @include mq($from: medium) {
    max-height: 100%;
    min-height: 48px;
  }
}

.c-list__item-free-height {
  max-height: none;

  @include mq($from: medium, $until: large) {
    max-height: none;

    .c-list__item-text {
      white-space: normal;
    }

    li:not(.r-list-item--wrapping) {
      .c-list__item-text:first-child {
        flex: 1 0 100%;
      }
    }

    .c-list__item-text.c-list__item-main-text--note-preview {
      flex: 1 0 45%;
    }
  }

  .c-list__item-wrapping-note-preview {
    position: relative;
    width: 50%;
    right: 4px;

    .c-button__group {
      bottom: 0;
    }

    p {
      padding-right: 0;
    }

    > .c-input__group {
      margin-bottom: 24px;
    }

    > .c-button__group {
      visibility: visible;
      opacity: 1;
    }
  }
}

.c-list__item-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  border-top-left-radius: $tiny-radius;
  border-bottom-left-radius: $tiny-radius;

  background-color: $color-primary;

  align-self: stretch;
  width: ($spacing-medium * 3);
  text-transform: uppercase;

  .c-icon {
    width: $huge-icon;
    height: $huge-icon;

    &.c-icon--smalled {
      width: $medium-icon;
      height: $medium-icon;
    }
  }

  &:focus-within {
    outline: 2px solid currentColor;
    outline-offset: -2px;
  }
}

.c-list__item-group {
  .c-list__item-icon {
    background-color: $color-primary-dark;
  }

  &:hover,
  &:active,
  &:focus,
  &.c-list__item-active {
    // background-color: $color-primary-dark-15 !important;
    background-color: rgb(179, 184, 199) !important;
  }
}

.c-list__item-done {
  .c-list__item-icon {
    background-color: $color-success;
  }

  &:hover,
  &:active,
  &:focus,
  &.c-list__item-active {
    background-color: rgb(183, 233, 221) !important;
  }
}

.c-list__item-in-progress {
  .c-list__item-icon {
    background-color: $color-notice;
  }

  &:hover,
  &:active,
  &:focus,
  &.c-list__item-active {
    background-color: rgb(249, 205, 139) !important;
  }
}

.c-list__item-checkbox {
  .c-list__item-icon {
    .c-input--checkbox--in-list {
      .c-input__label--checkbox::before {
        flex: 0 0 48px;
      }
    }

    &:hover {
      .c-icon,
      .c-list__initials {
        display: none;
      }

      .c-input--checkbox--in-list {
        display: block;

        .c-input__label--checkbox::before {
          flex: 0 0 48px;
        }
      }
    }

    .c-input--checkbox--indeterminate
      .c-input--checkbox:not(:checked)
      + .c-input__label:after {
      transform: translate(-50%, -50%);
      left: 50%;
      width: 19px;
      height: 19px;
    }
  }

  &.c-list__item-disabled {
    .c-list__item-icon {
      background-color: $color-primary-25;
    }

    .c-list__item-text {
      color: rgba($color-primary-dark, 0.7);
    }
  }

  &.c-list__item-read-only,
  &.c-list__item-disabled {
    .c-list__item-icon:hover {
      .c-icon,
      .c-list__initials {
        display: block;
      }

      .c-input--checkbox--in-list {
        display: none;
      }
    }
  }
}

.c-list__item-label {
  width: 100%;
  padding: $spacing-small ($spacing-medium + $spacing-small);
  line-height: inherit;

  display: flex;
  flex-wrap: wrap;

  font-family: $font-family-bold;
  text-transform: none;

  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background: inherit;

  @include mq($from: small) {
    justify-content: space-between;
  }

  .c-list__item-icon + & {
    width: calc(100% - 48px);
  }

  .r-list__item > &:first-child,
  .c-list__item > &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

.c-list__item-cta {
  .c-list__item-label {
    align-items: center;
    padding: $spacing-small ($spacing-medium + $spacing-small);
  }
}

.c-list__item-text {
  display: flex;
  align-items: center;

  color: $color-primary-dark;
  text-align: left;

  width: 100%;
  //   flex: 1 1 100%;
  flex: 1;
  max-width: none;

  @include mq($from: tiny) {
    width: auto;
    flex: 0 1 auto;
  }

  .c-badge {
    margin-left: $spacing-small;
  }

  .c-list__item:not(.c-list__item-small) &:first-child {
    min-width: 45%;

    @include mq($from: medium) {
      min-width: 45%;
    }

    @container panel-container (inline-size < 487px) {
      width: 100%;
      flex: 1;
    }

    @container panel-container (inline-size > 486px) {
      flex: 1;
      min-width: 39%;
    }
  }

  & ~ &:last-child {
    margin-right: 0;
    text-align: right;
    justify-content: flex-end;
    display: flex;

    @include mq($from: tiny) {
      min-width: 90px;
      max-width: none;
      margin-top: 4px;
      margin-bottom: 4px;
      white-space: normal;
    }
  }

  & ~ &.c-list__item-progress {
    @include mq($from: tiny) {
      min-width: 0;
      max-width: none;
      width: calc(50% + 25px);
    }
  }
}

.c-list__item-label-with-supertitle {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .c-list__item-supertitle {
    text-transform: uppercase;
    color: $color-neutral-dark;
    font-family: $font-family-regular;

    @include inuit-font-size(10px, 1.2);
  }
}

.c-list__item-label-virtualized {
  .c-list__item:focus &,
  .c-list__item:focus-within &,
  .c-list__item:hover & {
    height: calc(100% - 49px) !important;
  }
}

.c-list__item-main-text {
  font-family: $font-family-bold;
  font-weight: normal;
}

.c-list__item-secondary-text {
  font-family: $font-family-light;
  color: $color-primary-dark;
}

.c-list__item-danger-text {
  font-family: $font-family-light;
  color: $color-error;
}

.c-list__item-middle {
  text-align: left;
  padding-right: $spacing-tiny;

  @include mq($from: tiny) {
    & ~ .c-list__item-text:last-child {
      max-width: none;
      text-align: right;
      justify-content: flex-end;
      display: flex;
    }
  }

  //   @include mq($from: small) {
  @container panel-container (inline-size > 486px) {
    flex: auto;
    padding-right: 0;
    text-align: left;
    justify-content: center;

    & ~ .c-list__item-text:last-child {
      max-width: none;
      flex: 1 0 80px;
      min-width: 80px;
    }
  }
}

.c-list__initials {
  font-family: $font-family-bold;
  display: flex;
  justify-content: center;
  width: 100%;
  color: $color-neutral-x-light;
}

.c-list__item-card-editable {
  min-height: 51px;
  max-height: none;
  position: relative;

  &,
  &:hover,
  &:focus-within,
  &:focus {
    background-color: $color-neutral-light;
  }

  .c-card--secondary {
    margin: 0;
    padding: ($spacing-mini + $spacing-small) ($spacing-huge - $spacing-small)
      ($spacing-mini + $spacing-small) $spacing-medium;
    min-height: 61px;
    display: flex;
    align-items: center;
  }

  .c-card__body--secondary {
    min-height: 31px;
  }

  .c-input__group {
    display: inline-flex;
    margin: $spacing-tiny 0;
  }

  .c-menu {
    left: 0;
    right: auto;
    width: 280px;

    @include mq($until: small) {
      max-width: 100%;
    }
  }

  .c-menu__wrapper {
    display: inline-flex;
    width: auto;

    .c-button--select {
      padding-right: 32px;
      min-width: 120px;
      background: $color-neutral-x-light;
    }
  }

  .c-button--menu {
    span:last-child {
      text-align: right;
    }
  }

  .c-input {
    font-family: $font-family-bold;
  }

  .c-input--read-only {
    border: 1px solid $transparent;
    padding: $spacing-small 0;

    @include mq($until: small) {
      padding: 0;
    }
  }

  .c-button__group {
    transition: all 0.25s ease-in-out;
    opacity: 0;
    position: absolute;
    z-index: -1;
    right: $spacing-medium;
    top: 50%;
    transform: translateY(-50%);

    @include mq($until: small) {
      position: relative;
      bottom: $spacing-small;
      right: 0;
      left: 0;
      top: auto;
      transform: none;
      align-self: flex-start;
      justify-content: flex-end;
      width: 100%;
      opacity: 1;
      z-index: 1;
      margin: $spacing-small $spacing-small 0;
    }
  }

  .c-card-body__item {
    margin: $spacing-tiny $spacing-small $spacing-tiny 0;

    &:not(.c-input__group):not(.c-menu__wrapper) {
      margin-top: $spacing-small + $spacing-tiny;
      margin-bottom: $spacing-small + $spacing-tiny;
    }
  }

  &:hover,
  &:focus-within,
  &:focus,
  &.is-open {
    .c-button__group {
      opacity: 1;
      z-index: 1;
    }
  }

  &.is-open {
    .c-card--secondary {
      padding: ($spacing-mini + $spacing-small) ($spacing-huge * 3)
        ($spacing-mini + $spacing-small) $spacing-medium;

      @include mq($until: small) {
        padding-right: $spacing-medium;
      }
    }
  }

  @include mq($until: small) {
    min-width: 160px;
    flex-wrap: wrap;

    .c-card__body--secondary {
      line-height: 1.8;
    }
  }
}

.c-list__item-small {
  background-color: $color-neutral-x-light;
  padding: $spacing-tiny;
  padding-right: $spacing-small;
  min-height: auto;

  &:hover,
  &:active,
  &:focus {
    background-color: $color-neutral-x-light;
  }

  @include mq($until: small) {
    align-items: center;
  }

  .c-list__item-label {
    padding: 0;
    width: 100%;
    color: $color-primary-dark;

    &:first-child {
      margin-left: $spacing-tiny;
    }

    @include mq($from: small) {
      align-items: center;
    }
  }

  .c-list__item-text {
    font-family: $font-family-regular;
  }

  .c-list__item-text {
    &:first-child {
      //   min-width: 25%;
      min-width: calc(100% - 94px);
      white-space: normal;
      padding-top: $spacing-tiny;
      padding-bottom: $spacing-tiny;
    }

    &:last-child {
      align-self: flex-start;
      padding-top: $spacing-tiny;
    }
  }
}

.c-list__item-trial {
  align-self: flex-end;
  margin-bottom: 3px;

  margin-right: $spacing-tiny;
  font-family: $font-family-regular !important;
  text-transform: capitalize !important;

  color: $color-neutral-dark;

  @include inuit-font-size(10px, 1.2);

  ~ .c-list__item-trial {
    display: none;
  }
}

.c-list__item-small-edited-area {
  flex-direction: row !important;
  justify-content: flex-end;
  max-width: none !important;

  > .c-list__item-result {
    display: flex;
    flex-direction: column;

    box-shadow: 0 0 10px -7px $color-neutral-dark;
    padding: 4px 8px;
    align-items: center;
    border-radius: 3px;
    background: rgba($color-neutral-x-light, 0.3);
    height: auto;

    i {
      text-align: center;
    }
  }

  > .c-list__item-trial {
    &:first-child {
      align-self: flex-end;
      margin-bottom: 3px;

      margin-right: $spacing-small;
      font-family: $font-family-regular !important;
      text-transform: capitalize !important;
    }
  }

  & + & {
    margin-top: $spacing-small;
  }

  .c-list__item-label-results > &:not(:first-child) {
    margin-left: auto !important;
  }

  &.c-list__item-small-edited-area-select {
    flex-wrap: nowrap;
    align-items: flex-start;
    width: 100%;
    padding-left: 0;
  }
}

.c-list__item-small-editing-area.c-list__item-small-edited-area.o-flex--column.c-list__item-small-edited-area-select {
  width: 100%;
}

.c-list__item-small-editable {
  transition: all 0.15s ease-in-out;
  max-width: 100%;
  max-height: 100%;
  position: relative;
  align-items: flex-start;

  .c-list__item-label {
    transition: margin-right 0.15s ease-in-out 0.15s;
    padding-right: 0;

    .c-list__item-text {
      width: auto;
      flex: 1 1 auto;
    }
  }

  .c-button--tiny {
    position: absolute;
    transform-origin: 0 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding-left: $spacing-small;

    background-color: $color-neutral-light;
    height: 34px;
    width: 32px;

    opacity: 0;
    z-index: -1;
    transition: all 0.15s ease-in-out 0.15s;
  }

  .c-list__item-small-editing-area,
  .c-list__item-small-editing-buttons {
    display: none;
  }

  .c-list__item-small-editing-area {
    width: auto;
    justify-content: flex-end;
    flex-wrap: wrap;

    @include mq($until: tiny) {
      width: 100%;
    }
  }

  .c-list__item-small-editing-buttons {
    flex: 1 0 100%;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: $spacing-small;
  }

  @include mq($until: small) {
    max-height: 100%;
    align-items: flex-start;

    .c-list__item-text {
      width: auto;
      flex: 1 1 auto;
      line-height: inherit;
    }

    .c-button--tiny {
      min-height: 100%;
    }
  }

  &:hover,
  &:active,
  &:focus,
  &.is-open {
    .c-list__item-label {
      margin-right: $spacing-large;
    }

    .c-button--tiny {
      margin-left: $spacing-small;
      opacity: 1;
      z-index: 5;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .c-tooltip__trigger {
      margin: 0;
    }
  }

  &:focus-within {
    .c-list__item-label {
      margin-right: $spacing-large;
    }

    .c-button--tiny:not(.c-tooltip__trigger) {
      margin-left: $spacing-small;
      opacity: 1;
      z-index: 5;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &.is-open {
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: 100%;
    max-height: none;

    form {
      flex: 1 1 auto;
    }

    .c-list__item-label {
      display: inline-flex;
      width: auto;
      line-height: 2;
      margin-right: 0;
    }

    // Refactored: .r-list__item-order
    // .c-badge--rank {
    //   line-height: 2.3;
    //   margin-top: $spacing-tiny * 1.5;
    // }

    .c-list__item-main-text {
      margin-bottom: 0;
    }

    .c-input__group:not(:first-child),
    .c-button:not(:first-child) {
      margin-left: $spacing-small;
    }

    .c-input__group {
      max-width: 60px;
      margin-bottom: 0;
      margin-top: $spacing-small;
    }

    .c-input {
      padding: $spacing-small;
      text-align: center;
    }

    .c-input--editable-error {
      padding: $spacing-small ($spacing-small + $spacing-small + $spacing-tiny)
        $spacing-small $spacing-small;

      & + .c-icon {
        width: $spacing-small + $spacing-tiny;
        height: $spacing-small + $spacing-tiny;
        right: $spacing-small;
      }
    }

    .c-button--tiny {
      position: relative;
      right: auto;
      top: auto;
      transform: none;
      background-color: $transparent;
      height: auto;
      width: auto;
    }

    .c-button--tiny-cancel {
      color: $color-neutral-dark;
    }

    .c-list__item-small-editing-area,
    .c-list__item-small-editing-buttons {
      display: flex;
    }

    // Labels for units.
    .c-input__group-benchmarks {
      margin: 0;

      .c-input__group-label {
        margin-right: 0;
        text-transform: uppercase;

        margin-left: $spacing-tiny;
        font-family: $font-family-bold;
        @include inuit-font-size(10px);
      }

      .c-input__group {
        margin-right: 0;
      }
    }
  }
}

.c-list--cue {
  margin-bottom: $spacing-medium;

  .c-input__label + & {
    padding-top: 0;
  }

  .c-list__item {
    min-height: $spacing-large;
    max-height: none;
  }

  .c-list__item-small-editable {
    overflow: hidden;

    .c-input__group {
      margin: 0;
    }

    .c-input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      padding: 0 $spacing-small 0 $spacing-large;
      min-height: 100%;
    }

    .c-input:focus {
      padding-right: 36px;
    }

    .c-input__wrapper {
      position: static;
    }

    .c-list__item-label + .c-button--tiny {
      top: 1px;
      bottom: 1px;
      right: 1px;
      transform: none;
      height: calc(100% - 2px);
      justify-content: flex-start;
    }

    &.c-list__item-feedback .c-input {
      padding: 0 $spacing-small;
    }
  }

  .c-button--ghost {
    background-color: $color-neutral-x-light;
    outline-offset: -2px;
  }
}

.c-list__item-feedback {
  &.c-list__item-small {
    align-items: center;
    margin-left: 0;
    margin-right: 0;
    position: relative;

    .c-list__item-label:first-child {
      position: relative;
      display: flex;
      min-height: 24px;
    }

    .c-input {
      transition: padding-right 0.2s linear !important;
      background-color: transparent;
      border: 0;
    }

    .c-list--cue & .c-input {
      padding-right: 0;
      padding-left: 0;
    }

    .c-button--tiny:first-of-type {
      position: relative;
      margin-right: auto;
      z-index: 5;
      opacity: 1;
      background: transparent;
      top: auto;
      bottom: auto;
      right: auto;
      height: auto;
      width: auto;
      align-self: center;
      transition: margin-right 0.2s linear;
      margin-right: 0;
    }

    .c-button--tiny:first-of-type:focus {
      outline-offset: 2px !important;
      border-radius: 8px;
      padding-left: 2px !important;
      padding-right: 2px !important;
    }

    .c-button--tiny:last-of-type {
      justify-content: flex-start;
      right: 1px;
    }

    &:focus-within,
    &:focus,
    &:hover {
      .c-input {
        padding-right: 0;
        padding-left: 0;
      }

      .c-button--tiny:first-of-type {
        margin-right: 32px;
      }
    }
  }

  &.c-list__item-small:not(.c-list__item-small-editable) {
    .c-button--tiny:first-of-type:last-of-type {
      position: absolute;
      right: 0;
      margin-left: 0;
      margin-right: 8px;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      border-radius: 2px;
    }

    .c-list__item-label {
      transition: padding 0.1s ease-in-out;
    }

    &:hover,
    &:focus-within {
      .c-list__item-label {
        padding-right: 36px;
      }

      .c-button--tiny:first-of-type {
        margin-right: $spacing-small * 1.5;
        opacity: 1;
      }
    }
  }

  &.c-list__item-small-editable {
    .c-button--tiny {
      height: 30px;
    }
  }

  .c-list__item-label {
    margin-right: 0 !important;
  }

  .c-button--tiny {
    margin-left: $spacing-small !important;
  }

  &.c-list__item-small-editable:hover,
  &.c-list__item-small-editable:active,
  &.c-list__item-small-editable:focus,
  &.c-list__item-small-editable.is-open {
    .c-list__item-label {
      margin-right: 0 !important;
    }

    .c-button--tiny {
      margin-left: $spacing-small !important;
    }
  }

  &:hover,
  &:focus-within {
    background-color: $color-neutral-x-light !important;
    outline: 1px solid $color-neutral;
    // outline-offset: -1px;
  }

  &.c-list__item-small-editable:focus-within {
    // outline: 2px solid $color-primary;

    .c-input {
      outline: 0 !important;
    }
  }

  .c-list__item-text.c-list__item-main-text {
    align-self: center;
    padding: 0;
    flex: 1;
  }
}

.c-list--progress-bars {
  .c-list__item {
    padding: $spacing-tiny $spacing-large $spacing-tiny $spacing-medium;
    min-height: $spacing-large;
  }

  .c-list__item-main-text {
    flex: 0 0 25%;
    font-family: $font-family-bold;
    text-transform: uppercase;
    color: $color-neutral-dark;

    padding-right: $spacing-small;
    white-space: normal;
    overflow: auto;

    @include inuit-font-size(10px, 1.2);
  }

  .c-list__item-progress {
    flex: 0 0 75%;
  }
}

.c-list__item-program {
  .c-list__item-label {
    width: 100%;
    padding: 0 $spacing-medium;
  }

  .c-list__item-text {
    white-space: normal;
    overflow: visible;
    padding: 8px 0;
  }

  &.c-list__item-done {
    background-color: $color-neutral-x-light;
    border: 1px solid $color-neutral-light;

    .c-list__item-label {
      opacity: 0.5;
    }
  }

  &.c-list__item-in-progress {
    border: 1px solid $color-error-25;

    .c-list__item-text:last-child {
      color: $color-error;
    }
  }
}

// <List threeColsAndDropdown>
.c-list--with-dropdown {
  .c-list__item-checkbox .c-input--checkbox--in-list .c-input__label {
    height: 100%;

    &::after,
    &::before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .c-list__item-text {
    white-space: normal;

    &:first-child {
      min-width: 40%;
      margin-right: 24px;
    }

    &:first-child:last-child {
      margin-right: 0;
    }
  }

  .c-list__item {
    max-height: none !important;
  }

  .c-list__item-middle {
    display: flex;
    justify-content: flex-end;

    + * {
      margin-left: $spacing-medium;
    }
  }

  .c-list__item-label {
    // padding-top: $spacing-small;
    padding: $spacing-small $spacing-medium;
  }

  .c-input__group.c-input__group-select {
    min-width: auto !important;

    &:last-child {
      margin-right: $spacing-medium * 1.5;
    }
  }

  &.c-list--dnd-preview .c-list__item-editing-area .c-input {
    padding: $spacing-small $spacing-large $spacing-small $spacing-small;
  }

  .c-tooltip.c-tooltip--left.c-tooltip--label {
    align-self: center !important;
    margin-bottom: -$spacing-mini !important;
  }

  .c-list__item-editing-area {
    flex: 1 0 50%;
  }

  .c-list__item-editing-area.c-list__item-with-slider {
    flex: 1 0 100%;
    margin: 8px 0;
  }

  .c-list__item-with-slider .c-list__item-slider-wrapper {
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;

    .c-list__item-small-editing-area {
      flex-wrap: nowrap;

      position: relative;
      width: 100%;
      display: flex;

      margin: auto;
      max-width: 460px;
      min-width: 0;

      @container tabs-container (inline-size < 518px) {
        width: 100%;
        max-width: none;
      }
    }

    .c-input__wrapper {
      display: flex;
    }

    .c-input__group.c-input--disabled {
      margin: 0;
      max-width: none;
      flex: 1 1 auto;
    }

    .c-input {
      border: 0;
      background: transparent;
      padding: 0;
      min-width: 100px;
      flex: 1 1 100px;
    }
  }

  .c-input__group-benchmarks {
    margin-left: auto !important;
  }

  .c-list__item-one-line .c-list__item-editing-area {
    width: auto;
    flex-direction: column;
    max-width: 100%;
    flex-wrap: wrap;
    margin-left: auto;
    margin-top: 4px;
    flex: auto;
    min-width: auto;

    .c-list__item-text.c-list__item-main-text {
      font-family: $font-family-regular;
      align-items: flex-end;
    }

    .c-input__group-benchmarks {
      width: auto;
      position: relative;
      margin-right: 24px;

      .c-input__group {
        max-width: 60px;
      }

      .c-input__group + .c-input__group {
        margin-left: 8px;
      }
    }

    .c-input {
      padding: $spacing-small;
    }

    .c-input--select {
      padding-right: $spacing-large;
    }
  }

  .c-input__group-benchmarks + .c-input__group-benchmarks {
    margin-top: $spacing-small;
  }

  .c-list__item-editing-area,
  .c-list__item-small-editing-area {
    &.c-list__form-with-select {
      .c-input__group-benchmarks {
        flex-wrap: wrap;
        justify-content: flex-end;
        z-index: 6;
        align-items: stretch;

        .c-input__group.c-input__group-select {
          flex: 0 1 auto;
          margin: 8px 0 0 4px;
          width: 170px !important;
          max-width: 100%;

          display: flex;
          flex-direction: column;
        }

        .c-input.c-input--select {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 190px;
          min-height: 32px;
          line-height: 1;
        }

        @container tabs-container (inline-size < 518px) {
          width: auto;
        }
      }

      @include mq($until: small) {
        margin: 0 !important;
        align-items: stretch;
        flex-direction: column;
      }
    }
  }

  @container tabs-container (inline-size > 628px) {
    .c-list__item-read-only.c-list__item-one-line {
      .c-list__item-editing-area {
        width: 50% !important;
        flex: 0 0 calc(50% + 24px);
      }
      .c-list__item-text:first-child {
        margin-right: 0;
        margin-left: 0;
        min-width: 0;
      }
    }
  }
}

.c-list__item-label > .c-list__item-editing-area.c-list__form-with-select {
  margin-right: 0;
  width: 50%;
}

// <ListItem withSubList>
.c-list__item-with-sub-list {
  flex-wrap: wrap;
  background-color: $color-neutral-x-light;
  box-shadow: none;

  z-index: 1;
  max-height: none;

  .c-list__item-icon {
    box-shadow: $list-box-shadow;
    border-bottom-left-radius: 0;
    z-index: 1;
  }

  > .c-list__item-label {
    box-shadow: $list-box-shadow;
    border-top-right-radius: $tiny-radius;
    border-bottom-right-radius: 0;
    z-index: 1;
    width: calc(100% - 48px);
    min-height: 48px;

    border-bottom: 1px solid $color-neutral-light;
  }

  &:hover,
  &:focus-within {
    > .c-list__item-label {
      border-bottom-color: $transparent;
    }
  }

  .c-list--sublist .c-list__item-small-editable {
    padding-right: $spacing-medium;
    padding-left: $spacing-medium;

    .c-list__item-label {
      margin-left: 0;
      padding-left: 0;
      margin-top: 0;
      min-width: 50%;
      width: 50%;
    }
  }

  @container tabs-container (inline-size < 486px) {
    .c-list--sublist .c-list__item-small-editable {
      .c-list__item-label {
        width: 100%;
      }
    }
  }

  .c-accordion__button:not(.c-accordion__button-custom):checked
    ~ .c-accordion__label-selfclosing {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    min-height: 48px;
  }

  .c-accordion__button:not(.c-accordion__button-custom)
    ~ .c-accordion__label-selfclosing {
    min-height: 48px;
  }

  .c-accordion__label.c-accordion__label-selfclosing {
    display: flex;
    border-radius: $tiny-radius;
    min-height: 48px;

    > span {
      display: flex;
      width: 100%;
      background: $color-neutral-x-light;
    }

    &::after {
      right: $spacing-small;
      z-index: 0;
    }
  }

  .c-accordion__label-selfclosing ~ .c-accordion__content > .c-list--sublist {
    box-shadow: none !important;

    &:first-child {
      margin-top: 0;
    }
  }

  .c-accordion__content .c-list__item-small-editable {
    .c-list__item-label {
      display: inline-flex !important;
      flex: 1 1 auto !important;
      width: auto !important;
    }
  }

  &.c-list__item-read-only {
    .c-list__item-small-editable {
      .c-list__item-label {
        margin-top: 0;
        padding-left: 0;
      }
    }
  }

  .c-list__item-small-editable
    .c-list__form-with-slider
    .c-input__group-benchmarks
    .c-input__group
    .c-input__wrapper.o-flex {
    max-width: 100%;
  }
}

.c-list--sublist {
  flex-wrap: wrap;
  padding: 0;
  width: 100%;
  background-color: rgba($color-neutral-x-light, 0.6) !important;
  overflow: visible;
  z-index: 1;

  .c-list__body {
    margin-bottom: 0;
  }

  .c-list__item {
    border-radius: 0;
    box-shadow: $list-box-shadow;
    background: none;
    margin-bottom: 0;
    padding-top: $spacing-small;
    padding-bottom: $spacing-small;

    &:last-child {
      border-bottom-left-radius: $tiny-radius;
      border-bottom-right-radius: $tiny-radius;
      min-height: 48px;
      margin-bottom: 0;
    }
  }

  .c-list__item-label {
    background: none;
    box-shadow: none;
    padding: 0;
  }

  .c-list__item-small-editable {
    form {
      flex: 0 1 auto;
    }

    .c-list__item-label {
      padding-left: $spacing-small;
    }

    .c-input__group {
      margin-top: 0;
    }

    .c-input__label {
      margin-bottom: 2px;
    }

    .c-list__item-small-editing-buttons,
    .c-list__item-small-editing-area {
      margin-left: auto;
      max-width: calc(100% - 44px);
    }

    .c-input__group-select {
      max-width: none;
    }

    .c-input--select {
      padding-right: 32px;
    }

    .c-input__group {
      margin: 0 0 0 $spacing-small;
    }

    .c-input__group-benchmarks {
      position: relative;
      width: 100%;
      display: flex;

      max-width: 460px;

      justify-content: flex-end;
      margin-right: 24px;

      > :not(:first-child) {
        flex: 0 0 auto;
      }

      .c-input__group {
        max-width: none;
        position: relative;

        .c-input__wrapper {
          max-width: 60px;
        }

        .c-input {
          text-align: left;
          width: auto;
          max-width: 100%;
        }
      }

      .c-input__group-label,
      .c-tooltip__trigger span {
        margin-bottom: $spacing-mini * 1.5;
        color: $color-neutral-dark;
        opacity: 1;
        font-family: $font-family-bold;
        align-self: flex-end;

        @include inuit-font-size(10px, 2.75);
      }

      @container tabs-container (inline-size < 518px) {
        width: 100%;
      }

      @container tabs-container (inline-size > 628px) {
        max-width: none;
        margin-right: 0;
      }
    }

    .c-list__item-small-editing-area.c-list__form-with-slider
      .c-input__group-benchmarks {
      @container tabs-container (inline-size > 628px) {
        width: 100%;
      }
    }

    .c-input__group-benchmarks {
      .c-input__group.c-input__group-select .c-input__wrapper {
        max-width: none;
        flex: 1;
      }
    }

    .c-input__group-benchmarks + .c-input__group-benchmarks {
      margin-top: $spacing-small;
    }

    .c-list__item-small-editing-area-two-dropdowns
      .c-input__group-benchmarks
      .c-input__group
      .c-input__wrapper {
      max-width: none;
      flex: 1;
    }

    .c-tooltip.c-tooltip--left.c-tooltip--label {
      align-self: flex-end !important;
      margin-bottom: $spacing-tiny !important;
    }

    .c-tooltip__message {
      z-index: 100;
    }
  }

  .c-list__item-text:first-child {
    line-height: 1.4;
    margin-right: 16px;
  }

  .c-list__form-with-slider {
    &.c-list__item-small-editing-buttons,
    &.c-list__item-small-editing-area {
      max-width: 100%;
      min-width: 100%;

      .c-input__group-benchmarks {
        .c-input__group {
          margin-left: 0;
          margin-right: 0;
          max-width: 100%;

          .c-input__wrapper {
            min-width: 100%;
            max-width: 100%;
            display: flex;
          }
        }

        .c-input {
          padding: 0;
        }
      }
    }
  }

  .c-tooltip__trigger {
    padding-left: 0;
    margin-left: 0;
    margin-bottom: $spacing-tiny !important;
  }
}

.c-list__item-with-sub-list .c-list--sublist.c-list--sublist-with-carousel {
  .c-list__item {
    padding: $spacing-small ($spacing-large * 1.5);
  }

  .content {
    max-width: 760px;
    margin: auto;
  }
}

.c-list__item-checkbox-with-inner-checkboxes {
  .c-list__item-label {
    max-height: none;
  }

  .c-list__item-text:first-child {
    min-width: auto;
  }

  .c-list__item-editing-area {
    height: 32px;
    margin-left: auto;
    margin-top: 2px;
    margin-bottom: 2px;

    .c-input__group--checkbox:not(.c-input--checkbox--in-list) {
      min-width: 48px;
    }

    .c-input__group-benchmarks {
      height: 100%;
    }

    .c-input__group--checkbox:not(.c-input--checkbox--in-list)
      .c-input--checkbox
      + .c-input__label--checkbox::before {
      margin-top: 9px;
      top: 50%;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .c-input__label {
      margin-top: -2px;
    }

    .c-input {
      padding: 0px 8px;
      height: 100%;
    }
  }
}

.c-button--as-list-item {
  font-family: inherit !important;
  color: inherit !important;
  text-transform: none !important;
  padding: 0 !important;

  .c-icon {
    transition: opacity 0.2s ease-in-out;
    opacity: 0;

    use {
      fill: currentColor;
    }
  }
}

.c-list__item-label {
  &:hover,
  &:focus-within {
    .c-button--as-list-item .c-icon {
      opacity: 0.7;
    }
  }
}

.c-list--virtualized {
  overscroll-behavior: none;
  max-height: 100%;

  .c-panel > & {
    margin: 0 -#{$spacing-medium};
    padding: 0 $spacing-medium;
  }

  .c-panel .p-tabs--full-width &.c-list--medium-negative-margin {
    margin-left: -$spacing-medium;
    margin-right: -$spacing-medium;
    padding: 0 $spacing-medium;
  }

  .p-tabs .react-tabs__tab-panel > & {
    padding: 0 $spacing-small 0 $spacing-medium;

    .c-list__body {
      margin-top: $spacing-small;
      margin-bottom: $spacing-small;
    }
  }

  .c-list__item-label .c-list__item-main-text {
    max-width: 50%;
    min-width: 0;
    width: 85%;

    span {
      white-space: normal;
      display: inline-block;
      width: 100%;
      //   text-overflow: ellipsis;
      //   overflow: hidden;
    }
  }

  .c-list__item-label-with-supertitle {
    &.c-list__item-label .c-list__item-main-text {
      flex-direction: column;
      justify-content: center;
    }
  }

  .c-list__item-label .c-list__item-secondary-text {
    max-width: 100%;
    min-width: 10%;
  }

  .c-list__item-middle {
    white-space: normal;
    max-width: 50%;

    text-align: right;
    padding-right: $spacing-medium;
    display: inline;
  }

  &:not(.c-list--dnd) {
    .c-list__item:hover,
    .c-list__item:active,
    .c-list__item:focus,
    .c-list__item:focus-within {
      .c-list__item-main-text:not(:first-child:last-child) {
        // position: absolute;
        // top: 0;
        // left: 48px;
        // right: auto;
        // max-width: calc(75% - #{$spacing-huge * 1.5});
        // padding-left: 24px;
        // height: 100%;

        span {
          white-space: normal;
        }
      }
    }
  }
  .c-list__item-secondary-text {
    margin-left: auto;
  }
}

.c-modal__content .c-list--on-modal {
  padding-left: 24px;
  padding-right: 12px;
  max-width: calc(100% + 16px);
  margin: 0 -8px !important;
  padding-top: 8px;
  padding-bottom: 32px;
  overflow-y: scroll;
}

// TODO: Refactor ... voorlopig nieuwe klass voor ExercisesListItem.js
.c-list__item.c-list__item-active {
  z-index: 102 !important;
  position: sticky;
  top: 0;
  bottom: 6px;

  //   transform-origin: right center;
  //   transform: scale(1.005);
  //   box-shadow: 0 0 2px 0 rgba(7, 22, 73, 0.6);
  //   transform: scale(1.005);
  box-shadow: 0 0px 7px -2px rgba(7, 22, 73, 0.6);
  opacity: 1;
  transform: scale(1.007);

  &::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    top: 50%;
    right: -16px;

    z-index: 10;
    transform: translateY(-50%);
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid $color-neutral-x-light;
    filter: drop-shadow(2px 0px 2px gray);
  }

  &.c-list__item-small {
    top: 8px;
  }

  .c-card--secondary &::after {
    right: -32px;
  }

  .c-list--on-modal & {
    bottom: 0 !important;
  }

  .c-list--on-modal &::after {
    background: $color-neutral-x-light;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: none;
    transform: none;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    z-index: -1;
    filter: none;
  }
}

.c-list__item-main-text--note-preview {
  padding-left: 0;

  .c-list__item-title.c-list__item-title-ellipsed {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    align-self: center;
  }

  &:not(:first-child:last-child),
  .c-list--virtualized .c-list__item-label &:not(:first-child:last-child),
  .c-list--virtualized .c-list__item:hover &:not(:first-child:last-child) {
    // max-width: calc(60% - 16px);
    max-width: calc(100% - 48px);
    padding-right: 8px;
    flex: 1;
  }

  .c-list__item-active .c-list__item-icon + .c-list__item-label & {
    max-width: 100%;
    width: auto;
  }

  &:first-child:last-child {
    max-width: 100%;
  }
}

.c-list__item-wrapping-note-preview {
  display: flex;
  align-items: center;
  margin-left: $spacing-small;

  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: auto;
  width: 40%;
  justify-content: flex-end;

  p {
    font-family: 'lato';
    color: $color-primary-dark;
    text-align: right;
    margin: 0;
    width: auto;
    // transition: all 0.2s ease-in-out;
    // transition: none;
    padding-right: $spacing-medium;
    opacity: 1;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .c-button__group {
    max-height: none;
    position: absolute;
    right: 0;
    top: auto;
    z-index: 1;
    // right: 14px;
    visibility: hidden;
    opacity: 0;
    padding-left: 16px;

    display: flex;
    justify-content: flex-end;
    right: 0;

    .c-list__item-label-with-supertitle & > *:first-child:last-child {
      margin-right: 14px;
    }
  }

  .c-button--tiny.c-tooltip__trigger {
    margin-top: 1px;
  }
}

.c-list__item:not(.c-list__item-active):hover {
  z-index: 100;

  .c-list--permissions & {
    z-index: 9;
  }
}

.c-list__item:active,
.c-list__item:focus,
.c-list__item:focus-within {
  z-index: 99;
}

.c-list__item-double-icon-info--with-preview {
  > .c-list__item-text {
    opacity: 1;
  }
  .c-button__group {
    max-height: none;
    position: absolute;
    right: 0;
    top: auto;
    z-index: 1;
    right: 14px;
    visibility: hidden;
    opacity: 0;
  }
}

.c-list__item:hover,
.c-list__item:active,
.c-list__item:focus,
.c-list__item:focus-within {
  @media (hover: hover) {
    .c-list__item-wrapping-note-preview {
      p:not([class]) {
        opacity: 0;
      }

      .c-button__group {
        opacity: 1;
        visibility: visible;
      }
    }

    .c-list__item-double-icon-info--with-preview {
      > .c-list__item-text {
        opacity: 0;
      }

      .c-button__group {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.c-list__item-with-input {
  &.c-list__item-active {
    box-shadow: 0 0 2px 0px rgba(7, 22, 73, 0.3);
  }

  .c-list__item-label {
    padding: 8px 24px;
    position: relative;
  }

  .c-list__item-text-switchable {
    padding: 0;
  }

  form {
    position: absolute;
    right: 12px;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
  .c-input__group.c-input--inline {
    margin-bottom: 0;
  }

  .c-input {
    padding: 8px 12px;
  }

  .c-input--inline .c-input__wrapper {
    margin-left: 0;
    position: static;
  }

  //   .c-input__wrapper {
  //   }

  .c-input__error,
  .c-input.has-error + .c-icon {
    display: none;
  }

  .c-button__group {
    position: absolute;
    z-index: 1;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }

  .c-button.c-button--tiny {
    margin-bottom: 0;
  }
}

// Two icons on listitems:

.c-list__item-double-icon-info {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;

  .c-list__item-text.c-list__item-secondary-text {
    width: auto;
    min-width: 40px;
    max-width: none;
    flex: 0 0 auto;
    width: auto;
    display: flex;
    justify-content: flex-end;
  }

  .c-list__item-text.c-list__item-secondary-text
    + .c-list__item-text.c-list__item-secondary-text {
    padding-right: 0;
    border-left: 1px solid $color-neutral-dark;
  }

  .c-list__item-text.c-list__item-secondary-text {
    padding-left: 8px;
    padding-right: 12px;
    margin-left: 0 !important; // Refactor: is now because hover
  }

  .c-list__item-secondary-text-value-unit {
    max-width: 0;
    transition: all 0.25s ease-in-out;
    overflow: hidden;
  }
}

.c-list__item {
  &:hover,
  &:focus-within,
  &:focus {
    @include mq($from: large) {
      .c-list__item-double-icon-info {
        .c-list__item-secondary-text-value-unit {
          width: auto;
          margin-right: 4px;
          max-width: 100%;
        }
      }
    }
  }
}

.c-list__item-outfaded {
  outline: 1px dashed $color-neutral;

  .c-list__item-icon {
    background-color: $color-neutral;
  }

  .c-list__item-label {
    background: rgba($color-neutral-light, 0.8);
  }

  .c-list__item-title.c-list__item-title-ellipsed {
    color: $color-neutral-dark;
  }

  .c-list__item-icon + .c-list__item-label {
    width: calc(100% - 48px);
  }

  &.c-list__item-clickable:hover,
  &.c-list__item-clickable:focus,
  &.c-list__item-clickable:focus-visible,
  &.c-list__item-clickable.c-list__item-active {
    background: $color-neutral-x-light !important;

    .c-list__item-icon {
      background-color: rgb(188, 192, 203);
    }

    .c-list__item-label {
      background: rgb(234, 236, 237);
    }
  }
}

.c-list__item-disabled.c-list__item-outfaded:not(
    .c-list__item-clickable
  ):hover {
  z-index: 0;
  opacity: 0.7;
}

.c-list__item-with-supertitle {
  .c-list__item-main-text {
    flex-wrap: wrap;
    flex: 1 1 auto !important;
  }

  .c-list__item-secondary-text {
    margin-left: auto;
  }
}

.c-list__item-text--higlight {
  background-color: rgba($color-primary, 0.35);
  border-radius: 2px;
  //   color: white;
}

.c-list__filtering-message {
  padding: 0 16px 8px;
  display: inline-block;
  text-align: right;
  width: calc(100% + 32px);
  margin-left: -16px;
  margin-right: -16px;
  margin-top: 4px;
  margin-bottom: 0;
  font-style: italic;
  color: $color-primary-dark-50;

  &:has(+ .c-list) {
    box-shadow: $shadow-bottom;
    z-index: 1;
  }

  &:has(+ .r-list__header) {
    padding-bottom: 0;
  }

  .c-modal__content &.c-message__text {
    margin-left: 0;
    width: 100%;
    padding: 0 24px 8px;
  }
}

// REFACTOR
.r-list {
  position: relative;
}

form:has(> .c-list),
form:has(> .r-list) {
  height: 100%;
}

.r-list--suplementary-with-range-and-select {
  padding: 0;
  margin-bottom: 16px;

  background-color: inherit;
  display: flex;
  flex-direction: column;

  overflow: visible;

  &:has(input[aria-expanded='true']) {
    z-index: 10 !important;
  }
}

.r-list__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 8px;
  height: auto;

  font-family: $font-family-bold;
  text-transform: uppercase;
  color: $color-neutral-dark;

  @include inuit-font-size(10px, 1);
}

.r-list__header--sticky {
  align-items: stretch;
  padding: 8px 16px 4px;
  height: 32px;
  margin-right: -16px;
  margin-bottom: 0;
  margin-left: -16px;
  box-shadow: 0 6px 6px -10px $color-neutral-x-dark;
  z-index: 1;

  .c-list__col--last-label:last-child {
    min-width: 25%;
    justify-content: flex-end;
  }
}

.r-list__header-button {
  padding: 0;
  border-radius: 8px;

  font-family: inherit;
  color: inherit;
  text-transform: inherit;
  font-size: inherit;
  line-height: inherit;
  background: none;
  border: 0;

  .c-icon {
    width: $small-icon;
    height: $small-icon;
    transition: transform 0.3s ease-in-out;
  }

  &:focus {
    outline-offset: 2px;
    outline: 2px solid $color-primary;
  }
}

.r-list__header-button--asc {
  .c-icon {
    transform: rotateX(180deg);
  }
}

.r-list__body {
  list-style: none;
  margin-left: 0;

  .r-list--sublist > & {
    margin-bottom: 0;
  }
}

.r-list__item {
  background: $color-neutral-x-light;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0;
  border: 0;

  width: 100%;
  border-radius: $tiny-radius;
  box-shadow: $list-box-shadow;

  margin-bottom: $spacing-small;
  min-height: 48px;

  position: relative;
  cursor: default;

  &:hover,
  &:active,
  &:focus,
  &:focus-within,
  &.c-list__item-active {
    background-color: $color-primary-10 !important; //rgb(225, 233, 247)
  }

  &:focus,
  &:focus-within {
    z-index: 20 !important;
  }

  &[role='button'] {
    cursor: pointer;
    user-select: none;

    .r-list__item-icon {
      cursor: default;
    }

    &.c-list__item-active {
      .c-list__item-small-editing-area,
      .c-list__item-double-icon-info {
        display: none;
      }
    }
  }

  .c-list__item-trial {
    margin-bottom: 10px;
    text-transform: capitalize;

    .c-input__group-label {
      font-family: $font-family-bold;

      text-transform: uppercase;
    }
  }

  /** Child of Sublist **/
  // Only direct descent of ListBody, under SubList
  .r-list--sublist > .r-list__body > & {
    border-radius: 0;
    background: inherit;

    margin-bottom: 0;

    flex-wrap: wrap;
  }

  .r-list--sublist > .r-list__body > &:last-child {
    border-bottom-left-radius: $tiny-radius;
    border-bottom-right-radius: $tiny-radius;
    margin-bottom: 0;
  }
}

.r-list__item-order {
  color: $color-neutral-dark;
  background-color: $color-neutral-light;
  border-radius: $small-medium-radius;

  flex: 0 0 auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  height: 24px;
  min-width: 24px;
  margin-right: $spacing-tiny;

  position: relative;
  z-index: 1;

  font-family: $font-family-bold;
  @include inuit-font-size(10px);
}

.r-list__item-read-only {
}

.r-list__item-with-sub-list {
  flex-wrap: wrap;
  max-height: none;
}

.r-list__item-done {
  .r-list__item-icon {
    background-color: $color-success;
  }

  &:hover,
  &:active,
  &:focus,
  &.c-list__item-active {
    background-color: $color-success-25 !important;
  }
}

.r-list__item-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  border-top-left-radius: $tiny-radius;
  border-bottom-left-radius: $tiny-radius;

  background-color: $color-primary;

  width: ($spacing-medium * 3);
  min-height: ($spacing-medium * 3);
  text-transform: uppercase;

  .c-icon:not(.c-icon--medium) {
    width: $huge-icon;
    height: $huge-icon;
  }

  .r-list__item-with-sub-list & {
    box-shadow: $list-box-shadow;
    border-bottom-left-radius: 0;
    z-index: 1;
  }
}

.r-list__item-label {
  width: 100%;
  padding: $spacing-small $spacing-medium;

  display: flex;
  flex-wrap: wrap;

  font-family: $font-family-bold;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;

  // When the label has a sub-list, will get
  // a slightly visible extra shadow around
  .r-list__item-with-sub-list > & {
    box-shadow: $list-box-shadow;
    border-bottom: 1px solid $color-neutral-light;
  }

  .r-list__item-with-sub-list:hover > &,
  .r-list__item-with-sub-list:focus-within > & {
    border-bottom-color: $transparent;
  }

  // When the label belongs to a SubList-Item
  // it will get a thiner font
  .r-list--sublist & {
    font-family: $font-family-regular;
    padding: 8px 16px;
  }

  // Most of the cases, there is an icon
  // preceding the label.
  .r-list__item-icon + & {
    width: calc(100% - 48px);
  }
}

.r-list__item-text {
  display: flex;
  align-items: center;

  min-width: 48%;
  margin: 2px 4px 2px 0;

  .r-list--sublist &:first-child {
    padding-right: 8px;
  }

  &:first-child:last-child {
    margin-right: 0;
  }

  @container tabs-container (inline-size > 628px) {
    width: 50%;
    margin: 0;
  }
}

.r-list__form {
  flex: auto;
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-top: 6px;
  margin-bottom: 2px;
  max-width: 100%;
  position: relative;

  .c-input__label {
    margin-bottom: 0;
  }
}

.r-list__form-long {
  width: 100%;

  .r-input-test-item-wrapper {
    flex-wrap: nowrap;
    max-width: none;
  }

  .r-list-item-data-value {
    width: 100%;
    flex: auto;
  }
}

.r-list__form-select {
  .c-input__group-benchmarks {
    margin: 0 24px 0 0;
    width: auto;
    justify-content: flex-end;
  }

  .c-input__wrapper--select {
    margin-top: 2px;
  }

  .c-input__group-benchmarks .c-input__group-select {
    margin: 8px 0 0 4px;
    width: 170px;
  }

  .r-list--sublist & > .c-input__group-benchmarks {
    .c-input__label {
      margin-bottom: 10px;
      margin-right: 8px;
    }
  }
}

.r-list__form-with-2-elements {
  .c-input__group-select {
    flex: 0 1 auto;
  }
}

.r-list__form-with-slider {
  flex: 1 0 100%;
}

.r-list-item-data-value {
  display: flex;

  box-shadow: 0 0 10px -7px $color-neutral-dark;
  padding: 4px 8px;
  align-items: center;
  border-radius: 4px;
  background: rgba($color-neutral-x-light, 0.3);

  min-width: 64px;
  min-height: 26px;
  height: 100%;
  margin-left: 12px;

  justify-content: center;
  flex-wrap: wrap;
  flex: 1 0 64px;

  &:first-child {
    margin-left: 0;
  }

  .c-progress-bar__label-side {
    width: 100%;
    text-align: center;
  }

  i {
    text-align: center;
    margin-right: 4px;
  }
}

.r-list-item--wrapping > .c-list__item.c-list__item-active {
  margin-bottom: 0;
}

.r-list--sublist {
  flex-wrap: wrap;
  padding: 0;
  width: 100%;
  background-color: rgba($color-neutral-x-light, 0.6) !important;
  overflow: visible;
  z-index: 1;
  border-radius: 0;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.r-list--refactoring-wrapping-notes {
  padding: 16px;
  overflow-x: hidden;
  height: 100%;

  .r-list__body {
    margin-bottom: 0;
  }

  .c-list__item-wrapping-note-preview {
    position: relative;

    p {
      padding-right: 0;
    }

    .c-button__group {
      max-width: 100%;
    }
    @container panel-container (inline-size < 487px) {
      width: 100%;
      margin: 0;
    }
  }

  .c-list__item-main-text--note-preview {
    &:not(:first-child:last-child),
    .c-list--virtualized .c-list__item-label &:not(:first-child:last-child),
    .c-list--virtualized .c-list__item:hover {
      @container panel-container (inline-size < 487px) {
        max-width: none;
        padding-right: 0;
        margin-bottom: 4px;
      }
    }
  }
}
