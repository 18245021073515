@import '../../_scss/settings';

.c-table,
.r-table {
  //   --light-hover-color: rgb(249, 251, 255);
  //   --light-hover-color: #3c84f006;
  --light-hover-color: #eff5fe;

  --light-hover-fading-color: rgba(249, 251, 255, 0.7);
}

.c-table {
  // TODO check: new code for scrollable tables
  .c-menu__wrapper .c-menu--open {
    z-index: 11;
  }
}

.c-table--import {
  min-width: 800px;

  @include mq($until: medium) {
    .c-table-cell {
      .c-table-cell {
        display: table-cell !important;
      }
    }
  }
}

.c-table-cell {
  padding: $spacing-small;
  position: relative;
  min-height: 50px;
  border-bottom: 2px solid $color-neutral-light;

  > .c-table__content span {
    transform-origin: 0;
    transition:
      color 0.2s ease-in-out,
      transform 0.2s ease-in-out;
  }

  * {
    margin-bottom: 0;
  }

  &:not(.c-table--editable) {
    .c-table-cell {
      @include mq($until: medium) {
        &:nth-child(n + 4) {
          display: none;
        }
      }

      @include mq($until: small) {
        &:nth-child(n + 3) {
          display: none;
        }
      }
    }
  }

  //   On focus, table cell has higher zIndex,
  // otherwise its tooltip got behind other elements
  &:focus-within {
    z-index: 2;
  }
}

.c-table-cell.c-table-cell--graphic {
  max-width: none;
}

.c-table-cell.c-table-cell--empty {
  opacity: 0.35;
  min-height: 0;
}

.c-table-cell--wide {
  width: 50%;

  .c-progress-bar--benchmark .c-progress-bar__label {
    right: $spacing-small * 1.5;
  }
  .c-progress-bar--framed.c-progress-bar--value-fixed {
    width: calc(100% - 40px);
  }
}

.c-table-cell--even-half {
  width: 50%;
  min-width: 149px;
  max-width: 160px;
}

.c-table--benchmark {
  text-align: left;
  margin-bottom: $spacing-large * 2.5;

  .c-table__header {
    position: sticky;
    top: 0;
    background-color: $color-neutral-x-light;
    z-index: 13;
    box-shadow: 0 7px 9px -9px $color-primary-dark-10;

    .c-table-cell--header {
      padding-bottom: 12px;
      vertical-align: middle;
    }

    .c-table__row:hover {
      background: $color-neutral-x-light;
    }
  }

  .c-table-cell--header {
    .o-flex .c-button--tiny {
      margin-left: auto;
    }

    .c-table__content {
      height: auto;
      min-height: 0;
    }
  }

  .c-table-cell--header.c-table-cell {
    padding: 0 $spacing-medium $spacing-medium;
  }

  .c-table-cell--header.c-table-cell:first-child {
    padding-left: 0;
    box-shadow: 3px 0 7px -6px #0002;

    &::before {
      content: '';
      position: absolute;
      background: white;
      top: 0;
      left: -24px;
      bottom: 0;
      right: 0;
      box-shadow: 0 5px 15px -10px #f4f5f7;
      border-right: 1px solid #0001;
    }
  }

  &:not(.c-table--import) .c-table-cell--header.c-table-cell > .o-flex {
    margin-top: $spacing-small * 1.5;
  }

  .c-table__row {
    box-shadow: 0 5px 15px -10px $color-neutral-light;

    &:hover {
      background: var(--light-hover-color);
      .c-table-cell.c-table-cell--main > .c-table__content span {
        color: $color-primary-dark;
        transform: scale(1.035);
      }
    }
  }

  .c-table__content {
    min-height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    background: inherit;
    padding: 0;

    &
      > *:first-child:not(button):not(.c-tooltip):not(
        .c-progress-bar--block-value
      ):not(.c-input__group-select) {
      flex: 1;
      padding: $spacing-mini 0 $spacing-tiny;
      position: relative;
    }

    .c-progress-bar--framed {
      width: calc(100% - #{$spacing-medium * 2.5});
    }
  }

  .c-table__content--avg {
    padding: $spacing-mini 0 $spacing-tiny;

    .c-progress-bar__label-side {
      display: inline-block;
      margin-right: $spacing-tiny * 1.5;
    }

    .c-progress-bar--framed {
      width: 100%;
    }

    .c-progress-bar__label-block span[class='']:first-child:last-child {
      font-family: $font-family-regular;
    }
  }

  .c-table-cell:not(th) {
    padding: 0 $spacing-medium;
    min-height: 0;
    vertical-align: center;
    height: auto;
    border-bottom: 2px solid $color-neutral-light;
  }

  .c-table-cell.c-table-cell--main {
    padding: 0 0 0 $spacing-small;

    .c-table__content {
      padding: 0 $spacing-small;
    }

    &::before {
      content: '';
      background: white;
      height: 100%;
      width: 100%;
      z-index: -1;
      position: absolute;
      top: 0;
      left: -24px;
      bottom: 0;
    }
  }

  .c-table-cell.c-table-cell--main:first-child {
    padding-left: 0;
    background: white;
    box-shadow: 3px 0 7px -6px #0002;

    .c-table__content {
      border-right: 1px solid #0001;
    }
  }

  .c-table__row:hover .c-table-cell.c-table-cell--main:first-child {
    background: var(--light-hover-color);
  }

  .c-progress-bar--benchmark .c-progress-bar__label {
    right: 0;
  }

  .c-progress-bar__label {
    margin-right: 0;
    margin-bottom: $spacing-mini;
    color: $color-neutral-dark;
  }

  .c-progress-bar__label-block {
    text-transform: none;

    @include inuit-font-size(10px, 1.2);

    &:first-child:last-child,
    &:nth-child(2) {
      @include inuit-font-size(11px, 1.2);
    }
  }

  .c-progress-bar__label-block-light {
    text-transform: none;
  }
}

.c-table--error-list {
  tr {
    &:not(:last-child) {
      border-bottom: 1px dotted $color-neutral;
    }
  }

  .c-table-cell {
    padding: $spacing-small $spacing-tiny;
    vertical-align: top;
    max-width: ($spacing-huge) * 2;

    &:first-child {
      max-width: $spacing-huge;
      text-align: right;
    }
  }
}

.c-table-cell--header {
  &:first-child {
    padding-left: 0;

    // TODO check: new code for scrollable tables
    position: sticky;
    left: 0;
    z-index: 11;
    display: table-cell;

    .c-input__group {
      margin-right: $spacing-medium;
    }
  }

  > * {
    min-width: 100%;

    .c-input__group {
      max-width: calc(100% - 27px);
      margin-bottom: 0;
    }

    input {
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }

  // TODO check: new code for scrollable tables
  @include mq($from: large) {
    &:nth-child(n + 2) {
      min-width: calc((100vw - 48px - 175px - 72px - 17px) / 4);
      max-width: calc((100vw - 48px - 175px - 72px - 17px) / 4);
    }
  }
  @include mq($until: large) {
    &:nth-child(n + 2) {
      min-width: calc((100vw - 48px - 175px - 72px - 17px) / 3);
      max-width: calc((100vw - 48px - 175px - 72px - 17px) / 3);
    }
  }
  @include mq($until: medium) {
    &:nth-child(n + 2) {
      min-width: calc((100vw - 48px - 175px - 72px - 17px) / 2);
      max-width: calc((100vw - 48px - 175px - 72px - 17px) / 2);
    }
  }
  @include mq($until: small) {
    &:nth-child(n + 2) {
      min-width: calc((100vw - 48px - 175px - 17px) / 1);
      max-width: calc((100vw - 48px - 175px - 17px) / 1);
    }
  }
}

.c-table--reports-rehab {
  .c-table-cell--header {
    @include mq($from: large) {
      &:nth-child(n + 2) {
        min-width: calc((100vw - 48px - 175px - 72px - 48px) / 4);
        max-width: calc((100vw - 48px - 175px - 72px - 48px) / 4);
      }
    }
    @include mq($until: large) {
      &:nth-child(n + 2) {
        min-width: calc((100vw - 48px - 175px - 72px - 48px) / 3);
        max-width: calc((100vw - 48px - 175px - 72px - 48px) / 3);
      }
    }
    @include mq($until: medium) {
      &:nth-child(n + 2) {
        min-width: calc((100vw - 48px - 175px - 72px - 48px) / 2);
        max-width: calc((100vw - 48px - 175px - 72px - 48px) / 2);
      }
    }
    @include mq($until: small) {
      &:nth-child(n + 2) {
        min-width: calc((100vw - 48px - 175px - #{$spacing-small}) / 1);
        max-width: calc((100vw - 48px - 175px - #{$spacing-small}) / 1);
      }
    }
  }
}

.c-table-cell--main {
  color: $color-neutral-dark;
  text-transform: uppercase;
  font-family: $font-family-bold;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding-left: 0;
  @include inuit-font-size(10px, 2);

  // TODO check: new code for scrollable tables
  position: sticky;
  left: 0;
  z-index: 10;
  display: table-cell;

  min-width: 175px;
  height: 50px;
  font-weight: normal;

  .c-button--tiny:not(.c-tooltip__trigger) {
    color: inherit;
    font-family: inherit;
    text-transform: inherit;
    text-align: left;
    margin-left: $spacing-small;

    @include inuit-font-size(10px, 2);
  }
}

.c-table--editable {
  min-width: 340px;
  border-collapse: separate;
  border-spacing: 1px;
  text-align: center;
  margin-bottom: 0;

  background: $color-neutral;

  .c-table__header {
    .c-table-cell--header {
      text-transform: uppercase;
      color: $color-primary-dark;
      border-color: $color-neutral;
      background: #e1e9f7;

      font-family: $font-family-bold;
      font-weight: normal;

      @include inuit-font-size(10px);

      position: sticky;
      left: auto;
      top: 1px;
      z-index: 9;

      outline: 1px solid $color-neutral;

      &:first-child {
        padding-left: $spacing-small;
      }

      > * {
        justify-content: center;
      }
    }

    tr:nth-child(2) {
      .c-table-cell--header {
        font-family: $font-family-light;
        position: sticky;
        top: 28px;
        z-index: 9;
      }
    }
  }

  .c-table-cell {
    padding: $spacing-tiny $spacing-small;
    border: 0;
    min-height: 0;
    background: $color-neutral-light;

    &::after {
      content: none;
    }

    .c-input__group {
      margin-bottom: 0;
    }

    &.is-edit {
      outline: 1px solid $color-primary;
      padding: 1px;
      z-index: 10;

      .c-input {
        padding: $spacing-tiny $spacing-medium;
      }

      &::after {
        content: '';
        width: 7px;
        position: absolute;
        height: 7px;
        background: #3c84f0;
        border-radius: 50%;
        top: -$spacing-tiny;
        left: -$spacing-tiny;
        border: 1px solid $color-neutral-light;
        z-index: 10;
      }

      &::before {
        content: '';
        width: 7px;
        position: absolute;
        height: 7px;
        background: #3c84f0;
        border-radius: 50%;
        bottom: -$spacing-tiny;
        right: -$spacing-tiny;
        border: 1px solid $color-neutral-light;
        z-index: 10;
      }
    }
  }

  .c-table__row {
    height: 26px;
  }

  .c-table__body {
    .c-table__row--edit {
      .c-table-cell {
        padding: 0;
      }

      .c-input {
        padding: ($spacing-tiny * 1.5) $spacing-medium;
        color: $color-primary-dark-25;

        &:focus,
        &.is-edited {
          color: $color-primary-dark;
        }
      }
    }
  }
}

.c-table--is-editing {
  .c-table-cell:last-child {
    padding: 0;
  }
}

.c-table--vertical-top {
  .c-table-cell {
    vertical-align: top;
  }
}

.c-table--report-tab {
  &.u-margin-bottom-none {
    margin-bottom: $spacing-tiny !important;
  }

  .c-table-cell {
    background: $color-neutral-x-light;
    position: static;
    border: 1px solid $color-neutral-light;
    border-right: 0;
  }

  .c-table-cell:first-child:not(.c-table-cell--even-half) {
    padding-left: $spacing-small;
    position: static;
    width: 30%;
    display: table-cell;
    color: $color-primary-dark;
  }

  .c-table-cell:nth-last-child(2):first-child:not(.c-table-cell--even-half) {
    width: 50%;
  }

  .c-table__row:hover:not(.c-table__row--unavailable),
  .c-table__row:focus-within:not(.c-table__row--unavailable) {
    .c-table-cell.c-table-cell--even-half {
      background: linear-gradient(
          rgba($color-primary, 0.1),
          rgba($color-primary, 0.1)
        ),
        linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
    }
  }

  .c-table-cell--value {
    font-family: $font-family-bold;
    color: $color-primary-dark;
    @include inuit-font-size(11px);
  }
}

.c-table--results-left-right {
  .c-table__body {
    background-color: $color-neutral-x-light;

    &:hover,
    &:focus-within {
      background: linear-gradient(
          rgba($color-primary, 0.1),
          rgba($color-primary, 0.1)
        ),
        linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
    }
  }

  .c-accordion__label & .c-table__body {
    font-family: $font-family-regular;
    color: $color-primary-dark;
  }

  &.c-table--report-tab {
    .c-table__body:hover,
    .c-table__body:focus-within {
      .c-table-cell.c-table-cell--even-half {
        background: linear-gradient(
            rgba($color-primary, 0.1),
            rgba($color-primary, 0.1)
          ),
          linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
      }
    }
  }

  .c-progress-bar.c-progress-bar--benchmark.c-progress-bar--framed {
    padding-right: 0;
  }

  .c-progress-bar__label-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: none;
    color: $color-primary-dark;
  }

  .c-progress-bar__group-full-width {
    position: relative;
    padding: $spacing-tiny 0;
  }

  .c-progress-bar--value-fixed .c-progress-bar__label {
    right: 0;
    margin: 0;
    color: $color-neutral-dark;
  }

  .c-progress-bar--framed {
    max-width: calc(100% - 36px);
  }
  .c-progress-bar--framed.c-progress-bar--without-percent {
    max-width: 100%;
  }

  @include mq($from: small) {
    .c-table__body {
      display: flex;
      flex-wrap: wrap;
    }
    // De container Card will be min 700px
    .c-table__row:nth-child(n + 3) {
      margin-left: 0;
    }

    .c-table__row {
      width: 60%;
      min-width: 200px;
      flex: 1 1 60%;

      &:first-child {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 40%;
        min-width: 40%;
        flex: 1 0 40%;
      }

      &.u-1-of-1 {
        min-width: 100%;
      }

      &:nth-child(n + 3) {
        margin-left: 50%;
      }
    }

    .c-table-cell.c-table-cell--even-half {
      min-width: 140px;
      min-width: 0;

      display: inline-flex;
      align-items: center;

      &[colspan='2'] {
        min-width: 100%;
        display: flex;

        .c-progress-bar__group-value-at-end {
          width: 100%;
          flex: 1;
        }
      }
    }

    .c-table__row:first-child > .c-table-cell.c-table-cell--even-half {
      max-width: none;
      min-width: 100%;
      align-items: center;
      display: flex;
      padding-right: $spacing-medium * 1.5;
    }

    .c-table__row:nth-child(2) {
      display: flex;

      > .c-table-cell.c-table-cell--even-half {
        max-width: 100%;
      }

      > .c-table-cell.c-table-cell--even-half[colspan='2'] {
        > .c-progress-bar__group.c-progress-bar__group-full-width {
          margin: auto;
        }
      }
    }

    table.u-margin-bottom-small:nth-child(1)
      > tbody:nth-child(1)
      > tr:nth-child(4) {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }

    table.u-margin-bottom-small:nth-child(1)
      > tbody:nth-child(1)
      > tr:nth-child(3) {
      display: flex;
      width: 100%;

      justify-content: flex-end;
    }
  }
}

.c-table--growth {
  .c-table__header {
    .c-table-cell--header {
      font-family: $font-family-light;
      z-index: 9;

      min-width: 35px;
      padding: $spacing-tiny;
      max-width: none;
    }
  }
}

.c-table-cell--nowrap {
  white-space: nowrap;
}

.c-table__row--unavailable {
  .c-table-cell {
    background: rgba(255, 255, 255, 0);
    opacity: 0.35;
  }
}

// Tables for Values per Session
.c-table--benchmark.c-table--values {
  .c-table-cell.c-table-cell--header {
    padding: $spacing-small;

    .o-flex {
      justify-content: center;
    }

    .c-table__content {
      height: 20px;
    }
  }

  .c-table__content {
    height: $spacing-medium * 1.5;
    background: none;
  }

  .c-table-cell {
    padding: 0 $spacing-small;

    &.c-table-cell--main {
      .c-table__content {
        margin-right: -$spacing-small;
        margin-left: -$spacing-small;
        padding-left: $spacing-small;
        padding-right: $spacing-small;
      }
    }

    &:first-child {
      width: 250px;
      color: #7a8198;
    }

    &:not(:first-child) .c-table__content {
      justify-content: center;
    }
  }

  .c-table__body + .c-table__body > tr > th {
    margin-top: $spacing-medium;
  }

  .c-table__body + .c-table__body .c-table__row:first-child .c-table-cell {
    padding-top: $spacing-medium;
  }
}

.c-table--compressed {
  .c-table__row:not(:nth-child(2n)) .c-table-cell {
    min-height: 100%;
    padding: $spacing-tiny $spacing-small;
  }

  .c-table__row:nth-child(2) .c-table-cell {
    padding-top: 0;
  }

  .c-table-cell {
    border-top: 0;
  }

  @include mq($from: small) {
    .c-table__row:nth-child(2) .c-table-cell {
      padding-bottom: 0;
    }
  }
}

.c-table--custom {
  .c-table__body .c-table-cell {
    padding: 0;
  }

  .c-table-cell.c-table-cell--header:first-child {
    width: 80px;
  }

  .c-table-cell.is-edit .c-input.c-input--in-table {
    padding: $spacing-tiny 0;
  }

  .o-flex.c-table-celll--color {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .c-table-celll--color.c-table-cell--color-red {
    background-color: $color-progress-red;
  }

  .c-table-celll--color.c-table-cell--color-orange {
    background-color: $color-progress-orange;
  }
  .c-table-celll--color.c-table-cell--color-yellow {
    background-color: $color-progress-yellow;
  }
  .c-table-celll--color.c-table-cell--color-lime {
    background-color: $color-progress-lime;
  }
  .c-table-celll--color.c-table-cell--color-green {
    background-color: $color-progress-green;
  }

  .c-table-cell--first,
  .c-table-cell--last {
    padding: 0 $spacing-small;

    div {
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &::before {
        position: absolute;
        background: $color-neutral-light;
        width: auto;
        top: 0;
        left: -50%;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        content: '';
        z-index: -1;
      }
    }
  }

  .c-table-cell--last {
    div::before {
      left: 0;
      right: -50%;
    }
  }

  .c-table-cell.c-table-cell--empty {
    padding: 0;
  }

  &.c-table--editable {
    .c-table-cell.c-table-cell--first.is-edit {
      border: 0;
      padding: 1px;

      div::before {
        left: -50%;
        right: 0;
        outline: 1px solid $color-primary;
      }
    }

    // Ball Top Left
    .c-table-cell.c-table-cell--first.is-edit::after {
      top: -3px;
      left: calc(-50% - #{$spacing-tiny});
    }

    .c-table-cell.c-table-cell--last.is-edit {
      div::before {
        left: 0;
        right: -50%;
        outline: 1px solid $color-primary;
      }
    }

    // Ball Bottom Right
    .c-table-cell.c-table-cell--last.is-edit::before {
      right: calc(-50% - #{$spacing-tiny});
    }

    .c-table__row--edit .c-input {
      padding: 6px 0;
    }

    .c-table__row--edit {
      .c-table-cell--first,
      .c-table-cell--last {
        div {
          &::before {
            left: -50%;
            right: 0;
            top: 0;
            bottom: 0;
          }
        }
      }
      .c-table-cell--last {
        div::before {
          left: 0;
          right: -50%;
        }
      }
    }
  }
}

// REFACTORING
.r-table {
  table-layout: fixed;

  .c-table-cell,
  .r-table-cell {
    padding: 0px 8px;

    > .o-flex > .c-button {
      margin-left: auto;
    }
  }

  .c-table-cell--main {
    padding-left: 0;
    background: white;
  }

  .c-table-cell > .o-flex,
  .c-input-select .c-input-select__value-container {
    text-align: left;
    align-items: center;
  }

  .r-table-cell:nth-child(2n),
  .c-table-cell:nth-child(2n) {
    background-color: $color-neutral-lighter;
  }
}

.r-table--fixed-width {
  width: 1px;

  //   @include mq($from: large) {
  //     width: auto;
  //   }
}

.r-table--full-width {
  width: 100%;
  height: auto;
  margin: 0;

  .c-table-cell {
    position: sticky;
    left: 0;
    box-shadow: inset 6px 0px 5px -10px black;
  }
}

.r-table__header {
  .c-table-cell--header {
    background-color: $color-neutral-x-light;

    &:nth-child(2n) {
      background-color: $color-neutral-lighter;
    }
  }

  .c-table-cell--header:nth-child(n + 2) {
    min-width: auto;
    max-width: none;
    width: 150px; // How much?
  }

  .c-table-cell--main {
    line-height: 1.2;
  }
}

.c-table__header,
.r-table__header {
  .c-input__group-select {
    flex: 0 0 calc(100% - 32px);
  }
}

.r-table__header--sticky {
  position: sticky;
  top: 0;
  background-color: $color-neutral-x-light;
  z-index: 13;
  box-shadow: 0 7px 9px -9px $color-primary-dark-10;
}

.c-table__body {
  position: relative;

  &:focus-within {
    z-index: 12;
  }
}

.r-table-cell {
  padding: 8px;
  position: relative;
  min-height: 50px;
  border-bottom: 2px solid $color-neutral-light;
  position: sticky;
  left: 0;

  &:first-child {
    padding-left: 0;
    padding-right: 16px;
    width: 250px;
  }

  .r-table__header &:first-child {
    z-index: 12;
  }

  .r-table__header &:first-child::before {
    content: '';
    position: absolute;
    left: -16px;
    z-index: -1;

    background: $color-neutral-x-light;
    top: 0;
    bottom: 0;
    right: -1px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.r-table__header .c-table-cell:nth-child(2n)::after {
  background: $color-gradient-lighter-to-transparent;
}

.c-table__body .c-table-cell--main {
  z-index: 12;
  padding-right: 16px;

  &:focus-within {
    z-index: 13;
  }
}

.c-table__body .c-table-cell--main:first-child::before {
  content: '';
  position: absolute;
  left: -24px;
  z-index: -1;

  background: inherit;
  top: 0;
  bottom: 0;
  right: -1px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.c-table__body .c-table-cell--main:first-child::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: -2px;
  left: 0;
  right: 0;
}

.c-table__body .c-table__row:last-child .c-table-cell--main::before {
  bottom: -2px;
}

.r-table-cell--complex-label {
  max-width: calc(100% - 24px);

  > span {
    font-family: $font-family-regular;
    text-transform: none;

    @include inuit-font-size(12px, 1);
  }
}
.r-table-cell__content {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-family-bold;
  color: $color-primary-dark;
  position: relative;
  z-index: inherit;

  @include inuit-font-size(11px, 1.2);
}

.c-table-cell--outfaded .r-table-cell__content {
  opacity: 0.5;
}

.u-text-uppercase {
  text-transform: uppercase;
}

.r-table-cell__content-outdated {
  display: inline-flex;
  position: relative;
  margin-left: 8px;

  &::after {
    content: '';
    border: 1px solid $color-growth-orange;
    top: -6px;
    position: absolute;
    bottom: -6px;
    left: -8px;
    right: -8px;
    border-radius: 6px;
  }

  .c-tooltip {
    position: absolute;
    right: -16px;
    top: -12px;
    background: $color-neutral-x-light;
    z-index: 1;
    border-radius: 50%;
    display: inline-flex;
  }
}

.r-table-cell--alert {
  display: inherit;
  opacity: 0.3;
  align-items: center;
}

.r-table-cell__unit {
  font-family: $font-family-regular;
  margin-left: 4px;
}

.r-table__header .c-table-cell--sorted::after {
  left: -2px;
  right: -2px;
}

.r-table {
  .c-icon--on-table-cell {
    margin-left: 6px;
  }

  .c-button--mini-text-default {
    border: 1px dashed $color-primary-dark-25;
    border-radius: 12px;
    padding: 6px 8px;
    background-color: white;
    font-family: inherit;
  }

  .c-tooltip--inherited-styles {
    color: inherit;
  }

  .c-tooltip--inherited-styles > .c-button {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    transition: all 0.2s ease-in-out;
    // padding: 4px;

    border: 1px dashed $color-primary-dark-25;
    border-radius: 24px;
    padding: 4px 4px 4px 8px;
  }

  .c-tooltip--inherited-styles-active > .c-button {
    padding: 4px;
  }

  .c-table-cell--sorted {
    z-index: 11;

    .o-flex {
      position: relative;
      z-index: 1;
    }

    &.c-table-cell--header {
      color: $color-primary;
    }

    &::before {
      content: '';
      border-right: 1px dashed $color-primary;
      border-left: 1px dashed $color-primary;
      position: absolute;
      top: 0;
      left: 1px;
      right: 1px;
      bottom: 0;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
    }
  }

  .r-table__header .c-table__row .c-table-cell--sorted::before {
    border-top: 1px dashed $color-primary;
  }

  .c-table__body .c-table__row:last-child .c-table-cell--sorted::before {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;

    border-bottom: 1px dashed $color-primary;
    bottom: -2px;
  }

  .c-table__body .c-table__row:hover {
    background-color: var(--light-hover-color);

    .c-table-cell:nth-child(2n) {
      background-color: var(--light-hover-color);
    }
  }
}

.r-table .c-table__body .c-table__row:hover {
  .o-flex {
    color: $color-primary-dark;
  }

  .c-table-cell--main:first-child::before {
    background: rgb(245, 249, 255);
  }
}
