@import '../../_scss/settings';

.c-message {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: auto;

  .c-button {
    margin-top: $spacing-medium;
  }
}

.c-message--empty-state {
  height: 100%;

  > * {
    max-width: 18rem;
    text-align: center;
  }
}

.c-message__icon {
  width: 100%;

  + .c-message__text-wrapper {
    margin-top: $spacing-small + $spacing-tiny;
  }
}

.c-message__text-wrapper {
  color: $color-neutral-dark;
  font-family: $font-family-regular;
  text-align: center;
  margin: 0;

  max-width: 18rem;
  display: block;

  @include inuit-font-size(13px, 1.2);

  a {
    text-decoration: underline;
    color: $color-primary-dark;
  }
}

.c-message__text {
  margin: 0;
}

.c-message__text-warning {
  color: $color-error;
}

.c-message__text-success {
  margin-bottom: 0;

  color: $color-success;
  font-family: $font-family-bold;
  text-align: center;

  > b {
    color: $color-primary-dark;
  }
}

.c-message-highlight {
  margin: 0;
  align-self: center;
  align-items: center;
  display: flex;

  b {
    margin-right: $spacing-small;
    @include inuit-font-size(24px);
  }
}
